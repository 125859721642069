import { Icon, IconDisplay, IconSize } from "@components/Icon";
import Popover from "@components/Popover/component";
import Thermometer, { ThermometerProps } from "@components/Thermometer";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Big } from "big.js";
import React, { forwardRef } from "react";

import { colorCssVars } from "src/theme/color";
import { verticalStackCss, horizontalStackCss } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

// TODO #8478: parametrize currency
export const LabeledThermometer = forwardRef<HTMLDivElement, ThermometerProps>(
  function LabeledTermometerImpl({ labelPosition = "bottom", ...props }, ref) {
    const {
      className,
      currency,
      goalLabel = "Goal",
      progressLabel = "Amount raised",
    } = props;

    const monthlyAnnualTotal =
      !props.dataMissing && typeof props.monthly?.times === "function"
        ? { currency, amount: new Big(props.monthly.times(12).toFixed(0)) }
        : undefined;

    const totalAmount = props.dataMissing
      ? new Big(0)
      : new Big(props.currentAmount).add(props.raisedOffline ?? 0);

    const raisedOnline =
      !props.dataMissing && props.currentAmount
        ? { currency, amount: new Big(props.currentAmount.toFixed(0)) }
        : undefined;

    const raisedOffline =
      !props.dataMissing && props.raisedOffline
        ? { currency, amount: new Big(props.raisedOffline.toFixed(0)) }
        : undefined;

    // If we aren't showing the recurring amount, then we split the thermometer
    // totals and labels around the bar itself.
    const showRecurringAmount = !!(
      monthlyAnnualTotal && monthlyAnnualTotal.amount.gt(0)
    );

    const RecurringLabel = () => {
      if (showRecurringAmount && monthlyAnnualTotal) {
        return (
          <section>
            <div
              css={[
                textSizeCss.xs,
                horizontalStackCss.xxs,
                css`
                  color: var(${colorCssVars.text.secondary});
                  align-items: center;
                `,
              ]}
            >
              <p>
                <span
                  css={css`
                    ${textSizeCss.s}
                    font-weight: ${FontWeight.BOLD};
                    color: var(${colorCssVars.text.body});
                  `}
                >
                  {displayCurrencyValueInUserLocale({
                    currencyValue: monthlyAnnualTotal,
                  })}
                </span>{" "}
                Annual recurring total
              </p>
              <Popover
                placement={"bottom"}
                css={css`
                  cursor: pointer;
                `}
                contentCss={css`
                  max-width: 300px;
                `}
                content={
                  "Total funds that current monthly donations would raise in the next 12 months, helping nonprofits operate sustainably and plan ahead."
                }
              >
                <Icon
                  iconImport={() => import("@components/Icon/icons/InfoIcon")}
                  display={IconDisplay.SECONDARY}
                  size={IconSize.X_SMALL}
                />
              </Popover>
            </div>
          </section>
        );
      }
      return null;
    };

    return (
      <div ref={ref} className={className} css={verticalStackCss.xs}>
        {labelPosition === "top" && <Thermometer {...props} />}
        <DefinitionList>
          <div>
            <dt>{progressLabel}</dt>
            <dd css={[horizontalStackCss.xxs, { alignItems: "center" }]}>
              <div>
                {props.dataMissing
                  ? "-"
                  : displayCurrencyValueInUserLocale({
                      currencyValue: {
                        currency,
                        amount: new Big(totalAmount.toFixed(0)),
                      },
                    })}
              </div>
              {raisedOffline && raisedOnline && raisedOffline.amount.gt(0) && (
                <Popover
                  placement={"bottom"}
                  css={css`
                    cursor: pointer;
                    z-index: 3;
                    padding-bottom: 2px;
                  `}
                  contentCss={css`
                    max-width: 300px;
                  `}
                  content={
                    <React.Fragment>
                      <div
                        css={[
                          textSizeCss.xs,
                          horizontalStackCss.xxs,
                          css`
                            color: var(${colorCssVars.text.secondary});
                            align-items: center;
                          `,
                        ]}
                      >
                        <p>
                          <span
                            css={css`
                              ${textSizeCss.s}
                              font-weight: ${FontWeight.BOLD};
                              color: var(${colorCssVars.text.body});
                            `}
                          >
                            {displayCurrencyValueInUserLocale({
                              currencyValue: raisedOnline,
                            })}
                          </span>{" "}
                          was raised on Every.org
                        </p>
                      </div>
                      <div
                        css={[
                          textSizeCss.xs,
                          horizontalStackCss.xxs,
                          css`
                            color: var(${colorCssVars.text.secondary});
                            align-items: center;
                          `,
                        ]}
                      >
                        <p>
                          <span
                            css={css`
                              ${textSizeCss.s}
                              font-weight: ${FontWeight.BOLD};
                              color: var(${colorCssVars.text.body});
                            `}
                          >
                            {displayCurrencyValueInUserLocale({
                              currencyValue: raisedOffline,
                            })}
                          </span>{" "}
                          was raised outside of Every.org
                        </p>
                      </div>
                    </React.Fragment>
                  }
                >
                  <Icon
                    iconImport={() => import("@components/Icon/icons/InfoIcon")}
                    display={IconDisplay.SECONDARY}
                    size={IconSize.SMALL}
                  />
                </Popover>
              )}
            </dd>
          </div>
          <div css={{ textAlign: "right" }}>
            <dt>{goalLabel}</dt>
            <dd>
              {props.dataMissing
                ? "-"
                : displayCurrencyValueInUserLocale({
                    currencyValue: {
                      currency,
                      amount: new Big(props.goalAmount.toFixed(0)),
                    },
                  })}
            </dd>
          </div>
        </DefinitionList>
        {labelPosition === "bottom" && <Thermometer {...props} />}
        <RecurringLabel />
      </div>
    );
  }
);

const DefinitionList = styled.dl`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    display: flex;
    flex-direction: column-reverse;
  }
  dt {
    color: var(${colorCssVars.text.secondary});
    ${textSizeCss.s};
  }
  dd {
    color: var(${colorCssVars.text.body});
    font-weight: ${FontWeight.BOLD};
    ${textSizeCss.s};
  }
`;
