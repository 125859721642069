import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { SelectDonationFlowPaymentOption } from "@components/donate/DonateV3/PaymentProcess/components/SelectFlowPaymentOption";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { useSyncPaymentMethod } from "@components/donate/DonateV3/PaymentProcess/useSyncPaymentMethod";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { getWindow } from "src/utility/window";

export const StockPage = ({
  form,
  formContext,
  nonprofit,
}: {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
}) => {
  const navigate = useNavigate();

  useSyncPaymentMethod({
    paymentOption: DonationFlowPaymentOption.STOCKS,
    form,
    formContext,
  });

  useEffect(() => {
    const window = getWindow();
    if (window) {
      if (!nonprofit?.metadata?.showStockPull) {
        // TODO #15621: enable pull stock flow for everybody (remove this navigate)
        navigate(
          paymentProcessRouteNameToPathMap[
            PaymentProcessRouteName.STOCKS_MANUAL
          ],
          { replace: true }
        );
      } else if (formContext.skipAmountAndFrequency) {
        navigate(
          paymentProcessRouteNameToPathMap[
            PaymentProcessRouteName.STOCKS_SIGNATURE_SIGN
          ],
          { replace: true }
        );
      }
    }
  }, [formContext.skipAmountAndFrequency, nonprofit, navigate]);

  return (
    <PageContainer>
      <SelectDonationFlowPaymentOption
        selectedPaymentOption={DonationFlowPaymentOption.STOCKS}
        paymentRequestReadyStatus={
          formContext.paymentRequestInitializer.readyStatus
        }
        paymentRequestIsApplePay={
          formContext.paymentRequestInitializer.isApplePay
        }
        paymentFlowOptions={formContext.paymentFlowOptions}
        showMorePaymentOptions={formContext.showMorePaymentOptions}
        setShowMorePaymentOptions={formContext.setShowMorePaymentOptions}
      />
      <ProcessContainer css={{ display: "flex", flexDirection: "column" }}>
        <h4 css={{ marginBottom: spacing.l }}>Stock shares donation</h4>
        <p css={{ marginBottom: spacing.l }}>
          We can submit forms to brokers to process your donation automatically,
          or you can get instructions for manual stock donation.
        </p>
        <div css={verticalStackCss.xs}>
          <Button
            css={{ marginBottom: spacing.l }}
            role={ButtonRole.PRIMARY}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: () => {
                navigate(
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.STOCKS_SIGNATURE
                  ]
                );
              },
            }}
            data-tname="Stocks_signature_donation"
          >
            Donate stocks on Every.org
          </Button>
          <Button
            css={{ marginBottom: spacing.l }}
            role={ButtonRole.SECONDARY}
            onClick={{
              kind: ButtonTargetKind.FUNCTION,
              action: () => {
                navigate(
                  paymentProcessRouteNameToPathMap[
                    PaymentProcessRouteName.STOCKS_MANUAL
                  ]
                );
              },
            }}
            data-tname="Stocks_manual_donation"
          >
            Get instructions for <br />
            manual stock donation
          </Button>
        </div>
      </ProcessContainer>
    </PageContainer>
  );
};
