import { fixPrototypeChain } from "@every.org/common/src/helpers/extendBuiltins";

import { FundraiserValidationErrors } from "src/pages/Fundraiser/EditForm/types";

export default class FundraiserValidationError extends Error {
  public validation: FundraiserValidationErrors;

  public constructor(params: {
    message: string;
    validation: FundraiserValidationErrors;
  }) {
    super(params.message);
    this.validation = params.validation;
    // When extending Error (or any of its subclasses), need to explicitly set
    // prototype to get proper behavior of methods like `instanceof`
    fixPrototypeChain(new.target.prototype, this);
  }
}
