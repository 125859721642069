import { ButtonRole, Button, ButtonTargetKind } from "@components/Button";
import { Card } from "@components/Card";
import { ErrorMessage } from "@components/ErrorMessage";
import { Loading } from "@components/LoadingIndicator";
import { FundraiserCard } from "@components/NewFundraiserCard";
import { FundraiserCardHeaderSectionAppearance } from "@components/NewFundraiserCard/components/HeaderSection";
import { MasonryList } from "@components/layout/MasonryList";
import React, { useCallback, useMemo, useState } from "react";

import {
  FundraiserResponse,
  NonprofitResponse,
} from "@every.org/common/src/codecs/entities";

import {
  NonprofitFundraisersFetchStatus,
  fetchNonprofitFundraisers,
} from "src/pages/Fundraiser/fetchFundraiser";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import {
  NUM_FUNDRAISERS_TO_FETCH,
  NUM_FUNDRAISERS_TO_SHOW,
} from "src/utility/apiClient/nonprofitPage";

const BATCH_FUNDRAISERS_SIZE = 100;

const NonprofitFundraisersList: React.FCC<{
  nonprofit: NonprofitResponse;
  initialFundraisers: {
    fundraisers: FundraiserResponse[];
    hasMore: boolean;
  };
}> = ({
  nonprofit,
  initialFundraisers: { fundraisers: _fundraisers, hasMore },
}) => {
  const [fundraisers, setFundraisers] = useState(_fundraisers);
  const [itemsToShow, setItemsToShow] = useState(NUM_FUNDRAISERS_TO_SHOW);
  const [fetchingMoreFundraisers, setFetchingMoreFundraisers] = useState(false);
  const [error, setError] = useState<string>();
  const showingMoreNotLessFundraisersOption =
    itemsToShow === NUM_FUNDRAISERS_TO_SHOW;

  const fundraisersToShow = useMemo(() => {
    return fundraisers.slice(0, itemsToShow);
  }, [fundraisers, itemsToShow]);

  const fetchMoreFundraisers = useCallback(async () => {
    try {
      if (itemsToShow === NUM_FUNDRAISERS_TO_SHOW) {
        setError(undefined);
        setItemsToShow((prevState) => prevState + BATCH_FUNDRAISERS_SIZE);
        if (fundraisers.length > NUM_FUNDRAISERS_TO_FETCH) {
          // If we have already fetched more fundraisers, we don't need to fetch more
          return;
        }
      } else {
        setItemsToShow(NUM_FUNDRAISERS_TO_SHOW);
        return;
      }
      setFetchingMoreFundraisers(true);
      const response = await fetchNonprofitFundraisers(
        nonprofit.id,
        BATCH_FUNDRAISERS_SIZE
      );
      if (
        response !==
        NonprofitFundraisersFetchStatus.NONPROFIT_FUNDRAISERS_NOT_FOUND
      ) {
        setFundraisers(response.fundraisers);
      }
    } catch (error) {
      setError("Error fetching fundraisers");
    } finally {
      setFetchingMoreFundraisers(false);
    }
  }, [fundraisers.length, itemsToShow, nonprofit.id]);

  return (
    <React.Fragment>
      {fundraisers && fundraisers.length > 0 && (
        <Card css={verticalStackCss.m}>
          <section
            css={[
              horizontalStackCss.l,
              { justifyContent: "space-between", alignItems: "center" },
            ]}
          >
            <h3>Fundraisers</h3>
            {hasMore && (
              <Button
                data-tname="seeMoreFundraisers"
                role={ButtonRole.TEXT_ONLY}
                onClick={{
                  kind: ButtonTargetKind.FUNCTION,
                  action: fetchMoreFundraisers,
                }}
              >
                {showingMoreNotLessFundraisersOption
                  ? "See all fundraisers"
                  : "See fewer fundraisers"}
              </Button>
            )}
          </section>
          <MasonryList
            numColumns={{ default: 2, 950: 1 }}
            items={fundraisersToShow.map((fundraiser) => {
              return {
                key: fundraiser.id,
                elem: (
                  <FundraiserCard
                    fundraiser={fundraiser}
                    nonprofit={nonprofit}
                    showHeader
                    showTextOnCover
                    showBorder
                    headerAppearance={
                      FundraiserCardHeaderSectionAppearance.ALTERNATIVE
                    }
                  />
                ),
              };
            })}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {fetchingMoreFundraisers && <Loading />}
        </Card>
      )}
    </React.Fragment>
  );
};

export default NonprofitFundraisersList;
