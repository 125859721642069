import { Card } from "@components/Card";
import { maxWidthFeedCardCss } from "@components/feed/shared";
import { fundraiserFeedCardCss } from "@components/feed/styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colorPalette } from "@every.org/common/src/display/palette";
import { spacing } from "@every.org/common/src/display/spacing";

import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { colorCssVars } from "src/theme/color";
import { verticalStackCss, horizontalStackCss } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

export const ContainerCard = styled(Card)`
  ${fundraiserFeedCardCss};
  ${maxWidthFeedCardCss};
  padding: 0;
`;

export const HeaderContainer = styled.div`
  ${horizontalStackCss.xs};
  align-items: center;
  padding: ${spacing.m};
`;

export const HeaderText = styled.div`
  ${verticalStackCss.none};
  h5 {
    ${textSizeCss.s};
    ${truncatedTextCss({ numLines: 1 })};
  }

  span {
    color: var(${colorCssVars.text.secondary});
    ${textSizeCss.xs};
    ${truncatedTextCss({ numLines: 1 })};
  }
`;

export const CoverContainer = styled.div<{ rounded?: boolean }>`
  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 16px 16px 0 0;
    `};
  overflow: hidden;
  position: relative;
`;

export const coverTitleCss = css`
  position: absolute;
  color: ${colorPalette.white};
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 2;

  h3 {
    width: 80%;
    margin: 0 auto;
    ${truncatedTextCss({ numLines: 2 })}
    align-items: center;
  }

  p {
    width: 85%;
    margin: 0 auto;
    ${truncatedTextCss({ numLines: 2 })}
    align-items: center;
  }
`;

export const DarkCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${colorPalette.blackWarmHex};
  opacity: 0.6;
  z-index: 1;
`;

export const TitleConatainer = styled.section`
  ${verticalStackCss.xs};

  h3 {
    ${textSizeCss.m}
  }

  p {
    ${textSizeCss.xs};
    ${truncatedTextCss({ numLines: 3 })};
  }
`;

export const InnerSection = styled.div`
  ${verticalStackCss.m};
  padding: ${spacing.m};
`;

export const topBorderCss = css`
  border: 1px solid var(${colorCssVars.dividerSoft});
  border-bottom: none;
  border-radius: 16px 16px 0 0;
`;

export const bottomBorderCss = css`
  border: 1px solid var(${colorCssVars.dividerSoft});
  border-top: none;
  border-radius: 0 0 16px 16px;
`;
