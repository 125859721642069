import { AvatarSize } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import { Card } from "@components/Card";
import { NonprofitLink } from "@components/NonprofitLink";

import { useParentNonprofit } from "src/context/NonprofitsContext/hooks";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { horizontalStackCss } from "src/theme/spacing";

interface ParentNonprofitNoteProps {
  nonprofit: ContextNonprofit;
}

export function ParentNonprofitNote({ nonprofit }: ParentNonprofitNoteProps) {
  const parentNonprofit = useParentNonprofit(nonprofit);

  if (!parentNonprofit) {
    return null;
  }

  return (
    <Card css={[horizontalStackCss.xs]}>
      <NonprofitAvatar nonprofit={parentNonprofit} size={AvatarSize.XX_SMALL} />
      <span>
        {parentNonprofit.metadata?.fiscalSponsorCustomDisclaimer
          ? parentNonprofit.metadata?.fiscalSponsorCustomDisclaimer.replace(
              "{projectName}",
              nonprofit.name
            )
          : `${nonprofit.name} is hosted at`}{" "}
        <NonprofitLink
          nonprofit={parentNonprofit}
          data-tname="ParentNonprofitDisclaimerLink"
        />
      </span>
    </Card>
  );
}
