import {
  Button,
  ButtonRole,
  ButtonTargetKind,
  ButtonSize,
} from "@components/Button";
import { ShareButton } from "@components/ShareButton";
import { SupportedBy } from "@components/SupportedBy";
import { DonateButton } from "@components/layout/DonateButton";

import {
  FundraiserResponse,
  NonprofitResponse,
} from "@every.org/common/src/codecs/entities";
import { spacing } from "@every.org/common/src/display/spacing";
import {
  getRoutePath,
  ClientRouteName,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { getFundraiserShareText } from "@every.org/common/src/helpers/share";

import { useLoggedInUserOrUndefined } from "src/context/AuthContext/hooks";
import { colorCssVars } from "src/theme/color";
import { horizontalStackCss } from "src/theme/spacing";
import { FontWeight } from "src/theme/text";
import { ClickAction } from "src/utility/analytics";
import { nonprofitShareImageCloudinaryParams } from "src/utility/cloudinary/nonprofit";
import {
  INSTAGRAM_POST_DIMENSIONS,
  DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS,
} from "src/utility/opengraph";

export const ActionButtons = ({
  fundraiser,
  nonprofit,
}: {
  fundraiser: FundraiserResponse;
  nonprofit: NonprofitResponse;
}) => {
  const loggedInUser = useLoggedInUserOrUndefined();
  const isMyFundraiser =
    !!loggedInUser?.id && fundraiser?.creatorUserId === loggedInUser.id;
  return (
    <div css={[horizontalStackCss.s, { alignItems: "center" }]}>
      <Button
        role={ButtonRole.UNSTYLED}
        data-tname="shareNonprofitFundraiserButtonWrapper"
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
        }}
      >
        <ShareButton
          aria-label={`Share nonprofit fundraiser ${nonprofit.name}`}
          data-tname="shareNonprofitFundraiserButton"
          data-action={ClickAction.SHARE}
          role={ButtonRole.TEXT_ONLY}
          size={ButtonSize.SMALL}
          hideText
          buttonCss={{ padding: spacing.xxs }}
          shareData={{
            url: getRoutePath({
              name: ClientRouteName.FUNDRAISER,
              tokens: {
                nonprofitSlug: nonprofit.primarySlug,
                fundraiserSlug: fundraiser.slug,
              },
              format: URLFormat.ABSOLUTE,
            }),
            text: getFundraiserShareText({
              fundraiserName: fundraiser.title,
              isMyFundraiser,
            }),
          }}
          instagramImageCloudinaryParams={
            nonprofitShareImageCloudinaryParams({
              logoCloudinaryId: fundraiser.coverImageCloudinaryId,
              coverImageCloudinaryId: nonprofit.coverImageCloudinaryId,
              imageHeight: INSTAGRAM_POST_DIMENSIONS.height,
            }) || DEFAULT_SHARE_IMAGE_CLOUDINARY_PARAMS
          }
        />
      </Button>
      <div
        css={[
          horizontalStackCss.s,
          { marginLeft: "auto", alignItems: "center" },
        ]}
      >
        {!!fundraiser.raisedData?.supporters && (
          <SupportedBy
            css={{
              fontWeight: FontWeight.MEDIUM,
              color: `var(${colorCssVars.accent.large})`,
            }}
            numSupporters={fundraiser.raisedData?.supporters}
          />
        )}
        <DonateButton
          data-tname="nonprofitFundraiserDonateButton"
          isDisbursable={nonprofit.isDisbursable}
          size={ButtonSize.SMALL}
          primarySlug={nonprofit.primarySlug}
          fromFundraiserId={fundraiser.id}
        >
          Donate
        </DonateButton>
      </div>
    </div>
  );
};
