import { useAmountLimits } from "@components/donate/DonateV3/PaymentProcess/useAmountLimits";
import { usePaymentFlowOptions } from "@components/donate/DonateV3/PaymentProcess/usePaymentFlowOptions";
import { validateNumber } from "@components/donate/DonateV3/PaymentProcess/validators";
import {
  CreateOrUpdateDonationResult,
  DonateModalAction,
  DonateFormType,
  PaymentProcessCoreProps,
  CryptoTokenRate,
} from "@components/donate/DonateV3/types";
import { DonateFormSearchValues } from "@components/donate/DonateV3/useDonateFormSearchParams";
import { getGiftCardPurchaseInfo } from "@components/donate/helpers";
import {
  useAvailableGivingCredit,
  useIsExternal,
  useNonce,
  usePaymentSourcesForUser,
} from "@components/donate/hooks";
import { Big } from "big.js";
import { UUID } from "io-ts-types";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { CurrencyValue } from "@every.org/common/src/codecs/currency";
import {
  DonationMatchCampaignType,
  DonationResponse,
  NonEmptyMatchingCampaignResponse,
  PaymentSourceResponse,
  PersonalGivingCreditResponse,
  UserResponse,
} from "@every.org/common/src/codecs/entities";
import { decodeOrUndefined } from "@every.org/common/src/codecs/index";
import {
  coerceToSafeInt,
  coerceToSafeIntOrThrow,
  SafeInt,
} from "@every.org/common/src/codecs/number";
import {
  FUND_ACCOUNT_NONPROFIT_ID,
  GIFT_CARDS_NONPROFIT_ID,
  GIVING_GAP_WEBHOOK_TOKEN,
  NO_TIPPING,
} from "@every.org/common/src/entity/constants";
import {
  DonationFlowPaymentOption,
  Country,
  DonationFrequency,
  PaymentMethod,
  Currency,
  PaymentSourceType,
} from "@every.org/common/src/entity/types";
import { min } from "@every.org/common/src/helpers/big";
import {
  ClientRouteName,
  URLFormat,
  getRoutePath,
} from "@every.org/common/src/helpers/clientRoutes";
import { minimumDenominationAmountToCurrencyValue } from "@every.org/common/src/helpers/currency";
import { notUndefined } from "@every.org/common/src/helpers/objectUtilities";
import { isUuid } from "@every.org/common/src/helpers/string";
import {
  DonationMatchCampaignIdentifier,
  getCryptoTokenRateRouteSpec,
  getPartnerWebhookDetailsRouteSpec,
  GiftCardPurchaseInfo,
} from "@every.org/common/src/routes/donate";

import { AuthContext } from "src/context/AuthContext";
import {
  useIsLoggedIn,
  useLoggedInOrGuestUserOrUndefined,
} from "src/context/AuthContext/hooks";
import { AuthStatus } from "src/context/AuthContext/types";
import { fetchNonprofit } from "src/context/NonprofitsContext/actions";
import { useParentNonprofit } from "src/context/NonprofitsContext/hooks";
import {
  PaymentRequestContext,
  PaymentRequestInitializer,
} from "src/context/PaymentRequestContext";
import { UsersContext } from "src/context/UsersContext";
import { getUser, userOrUndefined } from "src/context/UsersContext/selectors";
import { useNonprofitMatchingCampaign } from "src/hooks/useNonprofitMatchingCampaign";
import { MediaSize, useMatchesScreenSize } from "src/theme/mediaQueries";
import { queryApi, useApi, UseApiResponse } from "src/utility/apiClient";
import {
  LocalStorageKey,
  LocalStorageValue,
  getLocalStorage,
} from "src/utility/localStorage";
import { logger } from "src/utility/logger";
const HEX_COLOR_REGEX = /^([0-9a-f]{3}){1,2}$/i;

// Only redirect donation to most recent community for 60 minutes after visiting that community
const REDIRECTION_TIMEOUT_FOR_MOST_RECENT_COMMUNITY = 60 * 60 * 1000;

// All derived context for the donate form
export interface DonateFormContext {
  /**
   * Generated upon loading the donate form. Intended to prevent inadvertently
   * submitting the same donation multiple times.
   */
  nonce?: NonEmptyString;
  /**
   * Nonprofit ID
   */
  nonprofitId: string;
  /**
   * Function to refresh the generated nonce.
   */
  refreshNonce: () => Promise<void>;
  /**
   * ID of the donation being joined
   */
  donationToJoinId?: DonationResponse["id"];
  /**
   * The user being joined.
   * TODO: Actually display the info about them somewhere
   */
  userToJoin?: UserResponse;
  /**
   * [DEPRECATED] Match campaign that this donation should be associated with.
   * I think this is associated with an older iteration of matching campaigns,
   * can we remove it?
   */
  DEPRECATED_matchCampaign?: DonationMatchCampaignIdentifier;
  /**
   * Nonprofit matching campaign (fetched from API)
   */
  nonprofitMatchCampaign?: NonEmptyMatchingCampaignResponse;
  matchingAmount: Big;

  /**
   * ID of the nonprofit fundraiser that this donation should be associated
   * with.
   */
  fromFundraiserId?: UUID;
  /**
   * The donation amount for the primary charity
   */
  amountBig: Big;
  /**
   * The sum of the donation amount for the primary charity plus any tip to Every.org
   */
  totalAmountBig: Big;
  /**
   * Giving credit available to the user -- fetched from api
   */
  availableGivingCredit?: Big;
  /**
   * Giving credit available to the user, but inaccessible for this donation   -- fetched from api
   */
  inaccessibleGivingCredit?: Big;
  /**
   * Giving credit available for this donation
   */
  creditAmount: Big;
  /**
   * Amount to charge the user's payment method
   */
  amountToCharge: Big;
  /**
   * Whether the donation requires a payment source that can be charged over and
   * over
   */
  renewablePaymentSourceRequired: boolean;
  /**
   * Is this an international donation?
   */
  isInternational: boolean;
  isGiftCardPurchase: boolean;
  /**
   * Payment sources info fetched from the API
   */
  paymentSources?: PaymentSourceResponse[];
  setPaymentSources: React.Dispatch<
    React.SetStateAction<PaymentSourceResponse[] | undefined>
  >;
  defaultPaymentSource?: PaymentSourceResponse;
  /**
   * Payment flow options.
   */
  paymentFlowOptions: Set<DonationFlowPaymentOption>;
  /**
   * Status / initialization function for payment requests (Apple / Google Pay)
   */
  paymentRequestInitializer: PaymentRequestInitializer;
  /**
   * successUrl causes a redirection immediately after the donation succeeds
   * Note, this is in contrast to redirectUrl which redirects after the user
   * completes their donation and exits out of the donateModal (after
   * commenting/sharing/skipping)
   */
  successUrl?: string;
  /**
   * redirectUrl redirects the user after they complete commenting / skipping /
   * sharing.
   */
  redirectUrl?: string;
  /**
   * exitUrl redirects the user after they clicks on the exit button
   */
  exitUrl?: string;
  /**
   * For a single nonprofit target, this is just whatever the nonprofit's
   * disbursable field is. For multiple nonprofits, we fetch all the nonprofits
   * and if any of them are not disbursable then this will be false.
   */
  isDisbursable: boolean;
  /**
   * These are search params added by the gift card purchase flow.
   */
  giftCardAmount?: string;
  giftCardQuantity?: string;
  giftCardNonprofitId?: string;
  giftCardTagId?: string;
  giftCardPurchaseInfo?: GiftCardPurchaseInfo;
  /**
   * Min / max donation values
   */
  minValue: CurrencyValue;
  maxValue: CurrencyValue;
  /**
   * Info sharing configuration
   */
  requireShareInfo: boolean;
  /**
   * This informs whether we show the share checkbox for the partner webhook.
   */
  partnerWebhookDetailsResponse: UseApiResponse<
    typeof getPartnerWebhookDetailsRouteSpec
  > | null;
  /**
   * Whether to shorten some copy.
   */
  shorten: boolean;
  /**
   * Skip amount and frequency step
   */
  skipAmountAndFrequency: boolean;
  /**
   * Skip amount and currency step for donate crypto flow
   */
  skipCryptoAmountAndCurrency: boolean;
  /**
   * Skip amount and symbol step for donate stock flow
   */
  skipStockAmountAndSymbol: boolean;
  /**
   * The result of creating / updating a donation
   */
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
  setCreateOrUpdateDonationResult: (
    result: CreateOrUpdateDonationResult | undefined
  ) => void;
  /**
   * Enable the option to tip every.org
   */
  enableTipping: boolean;

  isExternal: boolean;

  donateAction: DonateModalAction;
  /**
   * Shows payment options for mobile selector
   */
  showMorePaymentOptions: boolean;

  setShowMorePaymentOptions: (value: boolean) => void;

  currency: Currency;

  /**
   * If it updates the donation, indicates how much the tip was from the donation amount as a percentage
   */
  donationTipPercent?: number;

  /**
   * The crypto amount including tip
   */
  totalCryptoAmount?: Big;
  /**
   * The stock amount including tip
   */
  totalStockAmount?: Big;
  /**
   * The conversion rate of the selected crypto to USD
   */
  cryptoTokenRate?: CryptoTokenRate;

  showNonprofitInfoSectionOnMobile: boolean;
  setShowNonprofitInfoSectionSectionOnMobile: (show: boolean) => void;

  /**
   * Stock info
   */
  stockSymbol?: string;
  stockAmount?: number;

  hideShareInfo?: boolean;
  /**
   * if true disable ability to change frequency
   */
  lockMonthlyFrequency?: boolean;

  monthlyTitle?: string;

  suggestedAmountsFromUrl?: number[];

  currentPaymentOption?: DonationFlowPaymentOption;
  setCurrentPaymentOption: (option: DonationFlowPaymentOption) => void;

  paymentSourceType?: PaymentSourceType;

  themeColor?: string;
  themeColorHighlight?: string;
  searchMeta?: { [key: string]: string };
  isDoubleDonation?: boolean;
  doubleDonationMessage?: string;

  givingCreditToRedeem?: PersonalGivingCreditResponse;
  giftCardCode?: string;
  partnerWebhookToken?: string;
  suggestedTipPercent?: number;
  actualTipPercent?: number;
}

/**
 * Returns additional context for the donate form. Some of this is derived from
 * form props or input values, some of this is retrieved from the API or other
 * contexts, and some of this computed based on some combination of all the
 * above.
 *
 * While we'd ideally like to keep as much of this in the specific components
 * where they're relevant, a lot of this info is shared across the form
 * components, and we keep that shared state here.
 */
export function useDonateFormContext({
  modalProps,
  form,
  initialValuesFromSearchParams,
}: {
  modalProps: PaymentProcessCoreProps;
  form: Omit<UseFormReturn<DonateFormType>, "handleSubmit">;
  initialValuesFromSearchParams: DonateFormSearchValues;
}): DonateFormContext {
  const { nonprofit, donateAction, defaultAmount, defaultTipAmount } =
    modalProps;
  const { watch, setValue, formState } = form;
  const {
    amount,
    frequency,
    paymentSource,
    paymentMethod,
    toNonprofits,
    currency,
    partnerWebhookToken,
    amountToEveryOrg,
    cryptoCurrency,
    cryptoPledgeAmount,
    isDoubleDonation,
    doubleDonationMessage,
    givingCreditToRedeem,
    giftCardCode,
    stockAmount,
  } = watch();

  // TODO figure out when to refresh the nonce
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { nonce, refreshNonce } = useNonce();

  const isLoggedIn = useIsLoggedIn();
  const authState = useContext(AuthContext);
  const loggedInOrGuestUser = useLoggedInOrGuestUserOrUndefined();
  const [showMorePaymentOptions, setShowMorePaymentOptions] = useState(false);

  const [skipAmountAndFrequency, setSkipAmountAndFrequency] = useState(false);
  const [skipCryptoAmountAndCurrency, setSkipCryptoAmountAndCurrency] =
    useState(false);
  const [skipStockAmountAndSymbol, setStockAmountAndSymbol] = useState(false);

  // For international nonprofit we disabled
  // using Bank Account as payment source
  // and gift credit
  const isInternational = nonprofit?.countryCode !== Country.US;

  const {
    giftCardAmount,
    giftCardQuantity,
    giftCardNonprofitId,
    giftCardTagId,
  } = initialValuesFromSearchParams;
  const isGiftCardPurchase =
    nonprofit?.id === GIFT_CARDS_NONPROFIT_ID &&
    !!giftCardAmount &&
    !!giftCardQuantity;
  const isFundAccount = nonprofit?.id === FUND_ACCOUNT_NONPROFIT_ID;

  const { availableGivingCredit, inaccessibleGivingCredit } =
    useAvailableGivingCredit({
      isLoggedIn,
      isInternational,
      isGiftCardPurchase,
      isFundAccount,
      restrictedByNonprofitId: nonprofit.id,
      paymentMethod,
      givingCreditToRedeem,
    });

  const {
    amountBig,
    creditAmount,
    amountToCharge,
    totalAmountBig,
    totalCryptoAmount,
    totalStockAmount,
  } = useMemo(() => {
    const useCredits =
      paymentMethod === PaymentMethod.CRYPTO
        ? false
        : !paymentSource ||
          paymentSource.paymentMethod === PaymentMethod.STRIPE;
    const amountBig = new Big(amount || 0);
    const totalAmountBig = amountBig.add(amountToEveryOrg || 0);
    const creditAmount = useCredits
      ? min(availableGivingCredit || Big(0), totalAmountBig)
      : Big(0);
    const amountToCharge = useCredits
      ? totalAmountBig.minus(creditAmount)
      : amountBig;

    const totalCryptoAmount = new Big(cryptoPledgeAmount || 0).add(
      amountToEveryOrg || 0
    );

    const totalStockAmount = new Big(stockAmount || 0).add(
      new Big(amountToEveryOrg || 0)
    );
    return {
      amountBig,
      creditAmount,
      amountToCharge,
      totalAmountBig,
      totalCryptoAmount,
      totalStockAmount,
    };
  }, [
    amount,
    availableGivingCredit,
    paymentSource,
    paymentMethod,
    amountToEveryOrg,
    cryptoPledgeAmount,
    stockAmount,
  ]);

  const renewablePaymentSourceRequired =
    amountBig.gt(availableGivingCredit || Big(0)) ||
    frequency !== DonationFrequency.ONCE;

  const paymentSourceInfo = usePaymentSourcesForUser({
    loggedInOrGuestUser: true,
    isInternational,
  });

  const [paymentSources, setPaymentSources] = useState<
    PaymentSourceResponse[] | undefined
  >();

  useEffect(() => {
    if (paymentSourceInfo.paymentSources) {
      setPaymentSources(paymentSourceInfo.paymentSources);
    }
    setValue(
      "paymentSource",
      paymentSourceInfo.defaultPaymentSource ||
        (paymentSourceInfo.paymentSources &&
          paymentSourceInfo.paymentSources.length > 0 &&
          paymentSourceInfo.paymentSources[0].paymentMethod !==
            PaymentMethod.PAYPAL &&
          paymentSourceInfo.paymentSources[0]) ||
        undefined
    );
  }, [
    paymentSourceInfo.defaultPaymentSource,
    paymentSourceInfo.paymentSources,
    setValue,
  ]);

  const {
    donationToJoinId = initialValuesFromSearchParams.donationToJoinId,
    userToJoinId = initialValuesFromSearchParams.userToJoinId,
    fromFundraiserId = initialValuesFromSearchParams.fundraiserId,
  } = modalProps.donateAction === DonateModalAction.DONATE ? modalProps : {};

  const usersState = useContext(UsersContext);
  // Since the visual treatment is very subtle, don't fetch the user if not
  // already present. If userToJoinId is present, it should be coming from a
  // feed card and therefore the associated user should always be present, but
  // if it's not, don't need to fetch.
  const userToJoin = userToJoinId
    ? userOrUndefined(getUser(usersState, { id: userToJoinId }))
    : undefined;

  const nonprofitMatchCampaign = useNonprofitMatchingCampaign({
    nonprofitId: nonprofit.id,
    fundraiserId: fromFundraiserId,
    disbursabilityCheck: true,
  });

  const DEPRECATED_matchCampaign =
    modalProps.donateAction === DonateModalAction.DONATE
      ? nonprofitMatchCampaign ||
        modalProps.matchCampaign ||
        initialValuesFromSearchParams.matchCampaign
      : undefined;

  const matchingAmount = useMemo(() => {
    if (
      !nonprofitMatchCampaign ||
      (nonprofitMatchCampaign.type ===
        DonationMatchCampaignType.MULTIPLE_RECURRING &&
        frequency === DonationFrequency.ONCE)
    ) {
      return new Big(0);
    }

    const maxMatchingBasedOnFrequencyRet = coerceToSafeInt({
      num:
        frequency === undefined
          ? Infinity
          : frequency !== DonationFrequency.ONCE
          ? nonprofitMatchCampaign.maxMatchPerDonationRecurringAmount ??
            Infinity
          : nonprofitMatchCampaign.maxMatchPerDonationOneTimeAmount ?? Infinity,
      round: true,
    });
    const maxMatchingBasedOnFrequency =
      "num" in maxMatchingBasedOnFrequencyRet &&
      maxMatchingBasedOnFrequencyRet.num;
    const maxMatchingAvailable = minimumDenominationAmountToCurrencyValue({
      currency: nonprofitMatchCampaign.currency,
      amount: Math.min(
        nonprofitMatchCampaign.availableForMatching,
        nonprofitMatchCampaign.maxPerUserMatchAmount,
        maxMatchingBasedOnFrequency && maxMatchingBasedOnFrequency !== Infinity
          ? maxMatchingBasedOnFrequency
          : nonprofitMatchCampaign.availableForMatching
      ) as SafeInt,
    }).amount;

    const amountBigFinal =
      frequency === undefined
        ? amountBig
        : frequency !== DonationFrequency.ONCE
        ? amountBig.mul(nonprofitMatchCampaign.matchMultiplierRecurring ?? 1)
        : amountBig.mul(nonprofitMatchCampaign.matchMultiplierOneTime);

    return amountBigFinal.lt(maxMatchingAvailable)
      ? amountBigFinal
      : maxMatchingAvailable;
  }, [amountBig, nonprofitMatchCampaign, frequency]);

  const successUrl =
    modalProps.successUrl || initialValuesFromSearchParams.successUrl;
  const mostRecentCommunity = decodeOrUndefined(
    LocalStorageValue.MOST_RECENT_COMMUNITY,
    getLocalStorage()?.getItem(LocalStorageKey.MOST_RECENT_COMMUNITY)
  );
  const mostRecentCommunitySlug =
    mostRecentCommunity &&
    new Date().getTime() - mostRecentCommunity.timestamp <
      REDIRECTION_TIMEOUT_FOR_MOST_RECENT_COMMUNITY &&
    mostRecentCommunity.communitySlug;
  const redirectUrl =
    modalProps.redirectUrl ||
    initialValuesFromSearchParams.redirectUrl ||
    (mostRecentCommunitySlug &&
      getRoutePath({
        name: ClientRouteName.NONPROFIT_OR_CAUSE,
        tokens: { nonprofitSlug: mostRecentCommunitySlug },
        format: URLFormat.RELATIVE,
      }));
  const lockMonthlyFrequency = useMemo(
    () => initialValuesFromSearchParams.frequency === DonationFrequency.MONTHLY,
    [initialValuesFromSearchParams.frequency]
  );

  /**
   * Eventually this should be configured by a url param or something like that
   */
  const paymentFlowOptions = usePaymentFlowOptions({
    nonprofit,
    successUrl,
    paymentMethodsFromUrl: initialValuesFromSearchParams.method,
    donateAction,
    lockMonthlyFrequency,
    availableGivingCredit,
    inaccessibleGivingCredit,
    givingCreditToRedeem,
  });

  const paymentRequestInitializer = useContext(PaymentRequestContext);

  const [isDisbursable, setIsDisbursable] = useState(nonprofit.isDisbursable);
  useEffect(() => {
    async function checkNonprofits() {
      if (toNonprofits) {
        const nonprofits = (
          await Promise.all(
            toNonprofits.map((identifier) => {
              const param = isUuid(identifier)
                ? { id: identifier }
                : { slug: identifier };
              return fetchNonprofit(param);
            })
          )
        ).filter(notUndefined);
        const allDisbursable =
          nonprofits.length > 0 &&
          nonprofits.every((nonprofit) => nonprofit.isDisbursable);
        if (!allDisbursable) {
          setIsDisbursable(false);
        }
      }
    }
    checkNonprofits();
  }, [toNonprofits]);

  const parentNonprofit = useParentNonprofit(nonprofit);
  const isGGNonprofit = partnerWebhookToken === GIVING_GAP_WEBHOOK_TOKEN;

  const [minValue, maxValue] = useAmountLimits(
    (isGGNonprofit && givingCreditToRedeem
      ? givingCreditToRedeem.amountRemaining
      : undefined) ||
      modalProps.minDonationValue ||
      initialValuesFromSearchParams.minValue ||
      nonprofit.metadata?.minDonationValue,
    currency
  );

  const requireShareInfo =
    initialValuesFromSearchParams.requireShareInfo ||
    !!nonprofit.metadata?.requireDonorInfo ||
    !!parentNonprofit?.metadata?.requireDonorInfo;

  // Strange that we use an effect to to do this instead of handling
  // within the form context. Could these be causing the controlled
  // and uncontrolled errors? TODO(#12448) investigate
  useEffect(() => {
    if (formState.isSubmitting || formState.errors.DONATE_FORM_ERROR) {
      return;
    }
    if (requireShareInfo) {
      setValue("shareInfo", true);
      return;
    }
    if (authState.status === AuthStatus.LOADING) {
      return;
    }
    if (
      loggedInOrGuestUser?.lastDonationShareInfo === undefined ||
      loggedInOrGuestUser.lastDonationShareInfo
    ) {
      setValue("shareInfo", true);
    }
  }, [
    setValue,
    formState.isSubmitting,
    formState.errors,
    requireShareInfo,
    loggedInOrGuestUser?.lastDonationShareInfo,
    authState.status,
  ]);

  useEffect(() => {
    if (!giftCardAmount || !giftCardQuantity) {
      return;
    }
    if (paymentMethod === PaymentMethod.CRYPTO) {
      return;
    }
    try {
      const total = new Big(giftCardAmount).times(giftCardQuantity);
      const validationError = validateNumber({
        amount: total.toString(),
        shorten: false,
        minValue,
        maxValue,
      });
      if (validationError) {
        logger.warn({
          message: "Invalid gift card params for donation",
          data: { giftCardAmount, giftCardQuantity },
        });
        return;
      }
      form.setValue("amount", total.toNumber());
      setSkipAmountAndFrequency(true);
    } catch (error) {
      logger.error({ error, message: "Error parsing gift card params." });
    }
  }, [
    form,
    giftCardAmount,
    giftCardQuantity,
    maxValue,
    minValue,
    paymentMethod,
  ]);

  const giftCardPurchaseInfo = useMemo(
    () =>
      giftCardQuantity && giftCardAmount
        ? getGiftCardPurchaseInfo(
            giftCardAmount,
            giftCardQuantity,
            giftCardNonprofitId,
            giftCardTagId
          )
        : undefined,
    [giftCardAmount, giftCardQuantity, giftCardNonprofitId, giftCardTagId]
  );

  const partnerWebhookDetailsResponse = useApi(
    () =>
      partnerWebhookToken
        ? {
            routeSpec: getPartnerWebhookDetailsRouteSpec,
            queryParams: {},
            routeTokens: { token: partnerWebhookToken },
            body: {},
          }
        : null,
    [partnerWebhookToken]
  );

  // eslint-disable-next-line no-restricted-syntax
  const shorten = useMatchesScreenSize({ max: MediaSize.SMALL }) ?? true;

  const [createOrUpdateDonationResult, setCreateOrUpdateDonationResult] =
    useState<CreateOrUpdateDonationResult | undefined>();

  const donationTipPercent =
    donateAction === DonateModalAction.UPDATE &&
    defaultAmount &&
    defaultTipAmount
      ? defaultTipAmount.times(100).div(defaultAmount).toNumber()
      : undefined;

  useEffect(() => {
    if (
      !skipAmountAndFrequency &&
      initialValuesFromSearchParams.amount &&
      initialValuesFromSearchParams.frequency
    ) {
      setSkipAmountAndFrequency(true);
    }

    if (
      !skipCryptoAmountAndCurrency &&
      initialValuesFromSearchParams.cryptoAmount &&
      initialValuesFromSearchParams.cryptoCurrency
    ) {
      setSkipCryptoAmountAndCurrency(true);
    }

    if (
      !skipStockAmountAndSymbol &&
      initialValuesFromSearchParams.stockAmount &&
      initialValuesFromSearchParams.stockSymbol
    ) {
      setStockAmountAndSymbol(true);
    }
  }, [
    initialValuesFromSearchParams,
    skipAmountAndFrequency,
    skipCryptoAmountAndCurrency,
    skipStockAmountAndSymbol,
  ]);

  const isExternal = useIsExternal(nonprofit.primarySlug) && !fromFundraiserId;
  const hideShareInfo =
    nonprofit.id === FUND_ACCOUNT_NONPROFIT_ID || !!giftCardPurchaseInfo;

  const [
    showNonprofitInfoSectionOnMobile,
    setShowNonprofitInfoSectionSectionOnMobile,
  ] = useState(true);

  const [cryptoTokenRate, setCryptoTokenRate] = useState<
    CryptoTokenRate | undefined
  >();

  useEffect(() => {
    const respond = async () => {
      if (cryptoCurrency !== cryptoTokenRate?.cryptoCurrency) {
        setCryptoTokenRate(undefined);
        try {
          if (cryptoCurrency) {
            const result = await queryApi(getCryptoTokenRateRouteSpec, {
              routeTokens: { token: cryptoCurrency },
              queryParams: {},
              body: {},
            });
            setCryptoTokenRate(result);
          }
        } catch (e) {
          if (cryptoCurrency) {
            setCryptoTokenRate({
              cryptoCurrency,
              currency,
              rate: 0,
            });
          }
        }
      }
    };
    respond();
  }, [cryptoCurrency, cryptoTokenRate, currency]);

  const [currentPaymentOption, setCurrentPaymentOption] =
    useState<DonationFlowPaymentOption>();

  const [themeColor, setThemeColor] = useState<string>();
  const [themeColorHighlight, setThemeColorHighlight] = useState<string>();

  useEffect(() => {
    if (
      initialValuesFromSearchParams.themeColor &&
      HEX_COLOR_REGEX.test(initialValuesFromSearchParams.themeColor)
    ) {
      setThemeColor(`#${initialValuesFromSearchParams.themeColor}`);
    }
    if (
      initialValuesFromSearchParams.themeColorHighlight &&
      HEX_COLOR_REGEX.test(initialValuesFromSearchParams.themeColorHighlight)
    ) {
      setThemeColorHighlight(
        `#${initialValuesFromSearchParams.themeColorHighlight}`
      );
    }
  }, [
    initialValuesFromSearchParams.themeColor,
    initialValuesFromSearchParams.themeColorHighlight,
  ]);

  const isTippingRestricted =
    NO_TIPPING.includes(nonprofit.id) ||
    !!nonprofit.metadata?.disableTipping ||
    (currentPaymentOption === DonationFlowPaymentOption.CRYPTO &&
      !cryptoTokenRate);

  const enableTipping =
    !isTippingRestricted &&
    (!isGGNonprofit ||
      amountBig.gt(
        minimumDenominationAmountToCurrencyValue({
          currency: Currency.USD,
          amountInMinDenom: coerceToSafeIntOrThrow({
            num: givingCreditToRedeem?.amountRemaining || 0,
          }),
        }).amount
      ));

  return {
    nonce,
    refreshNonce,
    donationToJoinId,
    currency,
    userToJoin,
    DEPRECATED_matchCampaign,
    nonprofitMatchCampaign,
    matchingAmount,
    fromFundraiserId,
    amountBig,
    creditAmount,
    amountToCharge,
    totalAmountBig,
    availableGivingCredit,
    inaccessibleGivingCredit,
    renewablePaymentSourceRequired,
    isInternational,
    isGiftCardPurchase,
    paymentSources,
    setPaymentSources,
    defaultPaymentSource: paymentSourceInfo.defaultPaymentSource,
    paymentFlowOptions,
    paymentRequestInitializer,
    successUrl,
    redirectUrl,
    exitUrl: initialValuesFromSearchParams.exitUrl,
    isDisbursable,
    minValue,
    maxValue,
    nonprofitId: nonprofit.id,
    requireShareInfo,
    partnerWebhookDetailsResponse,
    giftCardAmount,
    giftCardQuantity,
    giftCardPurchaseInfo,
    shorten,
    skipAmountAndFrequency,
    skipCryptoAmountAndCurrency,
    skipStockAmountAndSymbol,
    createOrUpdateDonationResult,
    setCreateOrUpdateDonationResult,
    enableTipping,
    isExternal,
    donateAction,
    showMorePaymentOptions,
    setShowMorePaymentOptions,
    donationTipPercent,
    totalCryptoAmount,
    totalStockAmount,
    cryptoTokenRate,
    showNonprofitInfoSectionOnMobile,
    setShowNonprofitInfoSectionSectionOnMobile,
    hideShareInfo,
    lockMonthlyFrequency,
    monthlyTitle: initialValuesFromSearchParams.monthlyTitle,
    suggestedAmountsFromUrl: initialValuesFromSearchParams.suggestedAmounts,
    currentPaymentOption,
    setCurrentPaymentOption,
    themeColor,
    themeColorHighlight,
    searchMeta: initialValuesFromSearchParams.searchMeta,
    isDoubleDonation,
    doubleDonationMessage,
    givingCreditToRedeem,
    giftCardCode: giftCardCode || initialValuesFromSearchParams.giftCardCode,
    partnerWebhookToken,
  };
}
