import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Card } from "@components/Card";
import { FGCDepletedMsg } from "@components/FGC/FGCDepletedMsg";
import { FGCNoMatchAvaliable } from "@components/FGC/FGCNoMatchAvaliable";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { GoBackButton } from "@components/donate/DonateV3/PaymentProcess/components/GoBackButton";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  donationFlowPaymentOptionToPaymentProcessRouteNameMap,
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { PrivateNoteInput } from "@components/donate/DonateV3/PaymentProcess/components/PrivateNoteInput";
import { SelectDonationFlowPaymentOption } from "@components/donate/DonateV3/PaymentProcess/components/SelectFlowPaymentOption";
import { TestimonyInput } from "@components/donate/DonateV3/PaymentProcess/components/TestimonyInput";
import { archiveDonation } from "@components/donate/DonateV3/PaymentProcess/helpers";
import { Amount } from "@components/donate/DonateV3/PaymentProcess/pages/Donate/Amount";
import { FrequencySelector } from "@components/donate/DonateV3/PaymentProcess/pages/Donate/FrequencySelector";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import {
  StepperType,
  useStepper,
} from "@components/donate/DonateV3/PaymentProcess/useStepper";
import { useSyncPaymentMethod } from "@components/donate/DonateV3/PaymentProcess/useSyncPaymentMethod";
import {
  validateAmountAndFrequency,
  validateCommentText,
} from "@components/donate/DonateV3/PaymentProcess/validators";
import {
  DonateFormType,
  DonateModalAction,
} from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Big } from "big.js";
import React, { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import {
  DARK_THEME,
  LIGHT_THEME,
  SHARED_PALETTE,
} from "@every.org/common/src/display/palette";
import { FGC_CAMPAIGN_CODE } from "@every.org/common/src/entity/constants";
import {
  DonationFrequency,
  DonationFlowPaymentOption,
  Currency,
  PaymentSourceType,
} from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { PaymentRequestReadyStatus } from "src/context/PaymentRequestContext";
import { useFallGivingChallengeActive } from "src/hooks/useFallGivingChallengeActive";
import { colorCssVars } from "src/theme/color";
import {
  spacing,
  verticalStackCss,
  horizontalStackCss,
} from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

export const LegendTitle = styled.legend`
  color: var(${colorCssVars.text.body});
  font-weight: ${FontWeight.BOLD};
  ${textSizeCss[TextSize.s]};
`;

export const ErrorMessage = styled.div`
  color: ${SHARED_PALETTE.error};
`;

export function getNameForPaymentOption(
  option: DonationFlowPaymentOption,
  paymentRequestIsApplePay: boolean,
  isManualDaf?: boolean
) {
  switch (option) {
    case DonationFlowPaymentOption.CREDIT_CARD:
      return "credit or debit";
    case DonationFlowPaymentOption.BANK:
      return "bank";
    case DonationFlowPaymentOption.PAYPAL:
      return "PayPal";
    case DonationFlowPaymentOption.GIFT_CARD:
      return "Gift card";
    case DonationFlowPaymentOption.VENMO:
      return "Venmo";
    case DonationFlowPaymentOption.PAYMENT_REQUEST:
      return paymentRequestIsApplePay ? "Apple Pay" : "Google Pay";
    case DonationFlowPaymentOption.DAF:
      return isManualDaf ? "DAF" : "Chariot";
    case DonationFlowPaymentOption.CRYPTO:
    case DonationFlowPaymentOption.STOCKS: {
      logger.error({
        message: "Unexpected donation flow payment option..",
        data: { option },
      });
      return null;
    }
  }
}

function confirmationRouteForPaymentOption(
  option: DonationFlowPaymentOption,
  isManualDaf?: boolean
) {
  switch (option) {
    case DonationFlowPaymentOption.CREDIT_CARD:
      return paymentProcessRouteNameToPathMap[
        PaymentProcessRouteName.CONFIRM_CARD
      ];
    case DonationFlowPaymentOption.BANK:
      return paymentProcessRouteNameToPathMap[
        PaymentProcessRouteName.CONFIRM_BANK
      ];
    case DonationFlowPaymentOption.PAYPAL:
      return paymentProcessRouteNameToPathMap[
        PaymentProcessRouteName.CONFIRM_PAYPAL
      ];
    case DonationFlowPaymentOption.VENMO:
      return paymentProcessRouteNameToPathMap[
        PaymentProcessRouteName.CONFIRM_VENMO
      ];
    case DonationFlowPaymentOption.PAYMENT_REQUEST:
      return paymentProcessRouteNameToPathMap[
        PaymentProcessRouteName.CONFIRM_APPLE_PAY
      ];
    case DonationFlowPaymentOption.DAF:
      return paymentProcessRouteNameToPathMap[
        isManualDaf
          ? PaymentProcessRouteName.DAF_MANUAL_CONFIRM
          : PaymentProcessRouteName.CONFIRM_DAF_CHARIOT
      ];
    default: {
      logger.error({
        message:
          "Unexpected payment option when continuing from standard donation flow",
        data: { option },
      });
      return undefined;
    }
  }
}

const GiftCardUnsupportedMessage = ({
  option,
  isApplePay,
  isManualDaf,
}: {
  option: DonationFlowPaymentOption;
  isApplePay: boolean;
  isManualDaf?: boolean;
}) => {
  return (
    <p
      css={css`
        color: var(${colorCssVars.text.secondary});
      `}
    >
      Gift card credit cannot currently be used when donating with{" "}
      {getNameForPaymentOption(option, isApplePay, isManualDaf)}.
    </p>
  );
};

const OneTimeDonationsMessage = ({
  option,
  isApplePay,
  isManualDaf,
}: {
  option: DonationFlowPaymentOption;
  isApplePay: boolean;
  isManualDaf?: boolean;
}) => {
  return (
    <div css={verticalStackCss.xs}>
      <h4 css={textSizeCss[TextSize.s]}>Frequency</h4>
      <p>
        {getNameForPaymentOption(option, isApplePay, isManualDaf)} only supports
        one-time donations
      </p>
    </div>
  );
};

const MonthlyDonationsMessage = ({
  text = "Monthly donation",
}: {
  text?: string;
}) => {
  return <h4 css={textSizeCss[TextSize.m]}>{text}</h4>;
};

const PAYMENT_METHODS_WITHOUT_GIFTCARDS = [
  DonationFlowPaymentOption.PAYPAL,
  DonationFlowPaymentOption.VENMO,
  DonationFlowPaymentOption.CRYPTO,
  DonationFlowPaymentOption.DAF,
  DonationFlowPaymentOption.STOCKS,
];

export function getMaxValueForPaymentOption(
  option: DonationFlowPaymentOption,
  currency: Currency,
  defaultMaxValue: Big
) {
  switch (option) {
    case DonationFlowPaymentOption.PAYPAL:
      return {
        currency,
        amount: new Big(50000),
      };
    case DonationFlowPaymentOption.VENMO:
      return {
        currency,
        amount: new Big(4000),
      };
    default:
      return {
        currency,
        amount: defaultMaxValue,
      };
  }
}

const AccountBalanceMessage = ({
  paymentOption,
  isApplePay,
  availableGivingCredit,
  inaccessibleGivingCredit,
  isManualDaf,
}: {
  paymentOption: DonationFlowPaymentOption;
  isApplePay: boolean;
  availableGivingCredit?: Big;
  inaccessibleGivingCredit?: Big;
  isManualDaf?: boolean;
}) => {
  const givingCreditUnsupported =
    PAYMENT_METHODS_WITHOUT_GIFTCARDS.includes(paymentOption);
  const showAvailableCreditsMessage =
    availableGivingCredit &&
    availableGivingCredit.gt(0) &&
    !givingCreditUnsupported;
  const showInaccessibleCreditsMessage =
    inaccessibleGivingCredit &&
    inaccessibleGivingCredit.gt(0) &&
    givingCreditUnsupported;

  const sharedCss = [
    css`
      padding: ${spacing.m};
      margin-bottom: ${spacing.xxl};
    `,
  ];

  if (showInaccessibleCreditsMessage) {
    return (
      <Card
        css={[
          sharedCss,
          css`
            background: #f3f6f6;
            color: ${LIGHT_THEME.text.body};
          `,
        ]}
      >
        Donation credits cannot currently be used when donating with{" "}
        {getNameForPaymentOption(paymentOption, isApplePay, isManualDaf)}.
      </Card>
    );
  }

  if (showAvailableCreditsMessage) {
    return (
      <Card
        css={[
          sharedCss,
          css`
            background: ${DARK_THEME.background.normal};
            color: ${DARK_THEME.text.body};
          `,
        ]}
      >
        You have{" "}
        <span
          css={css`
            color: ${DARK_THEME.accent.small};
            font-weight: ${FontWeight.BOLD};
          `}
        >
          {displayCurrencyValueInUserLocale({
            currencyValue: {
              amount: availableGivingCredit,
              currency: Currency.USD,
            },
          })}
        </span>{" "}
        in donation credits available. These will be used before other payment
        methods are applied.
      </Card>
    );
  }

  return null;
};

export const DonatePage = ({
  nonprofit,
  fundraiser,
  form,
  formContext,
  paymentOption,
  isManualDaf,
}: {
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse | null;
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  paymentOption: DonationFlowPaymentOption;
  isManualDaf?: boolean;
}) => {
  const {
    formState: { errors },
    control,
  } = form;

  useSyncPaymentMethod({ paymentOption, form, formContext });

  const frequency = form.watch("frequency");
  const paymentSourceType = form.watch("paymentSourceType");

  useEffect(() => {
    if (
      paymentOption === DonationFlowPaymentOption.VENMO ||
      (paymentOption === DonationFlowPaymentOption.DAF && !isManualDaf)
    ) {
      frequency !== DonationFrequency.ONCE &&
        form.setValue("frequency", DonationFrequency.ONCE);
    }
    if (paymentOption === DonationFlowPaymentOption.DAF && !isManualDaf) {
      paymentSourceType !== PaymentSourceType.CHARIOT &&
        form.setValue("paymentSourceType", PaymentSourceType.CHARIOT);
    } else {
      paymentSourceType !== undefined &&
        form.setValue("paymentSourceType", undefined);
    }
  }, [
    form,
    formContext,
    frequency,
    paymentOption,
    paymentSourceType,
    isManualDaf,
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    const window = getWindow();
    if (window && formContext.skipAmountAndFrequency) {
      const route = confirmationRouteForPaymentOption(paymentOption);
      if (route) {
        navigate(route, { replace: true });
      }
    }
  }, [formContext.skipAmountAndFrequency, paymentOption, navigate]);

  useEffect(() => {
    getWindow()?.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (
      formContext.paymentRequestInitializer.readyStatus ===
        PaymentRequestReadyStatus.UNABLE &&
      paymentOption === DonationFlowPaymentOption.PAYMENT_REQUEST
    ) {
      const nextAvailablePaymentFlow = Array.from(
        formContext.paymentFlowOptions
      ).find((op) => op !== DonationFlowPaymentOption.PAYMENT_REQUEST);

      if (nextAvailablePaymentFlow) {
        navigate(
          paymentProcessRouteNameToPathMap[
            donationFlowPaymentOptionToPaymentProcessRouteNameMap[
              nextAvailablePaymentFlow
            ]
          ]
        );
      }
    }
  }, [
    formContext.paymentRequestInitializer.readyStatus,
    navigate,
    paymentOption,
    formContext.paymentFlowOptions,
  ]);

  const submit = form.handleSubmit(
    (formValues) => {
      const { frequency, amount, privateNote, commentText } = formValues;
      const { shorten, minValue, maxValue, currency } = formContext;
      if (
        !validateAmountAndFrequency({
          frequency,
          amount: amount?.toString() || "",
          shorten,
          minValue,
          maxValue: getMaxValueForPaymentOption(
            paymentOption,
            currency,
            maxValue.amount
          ),
          setError: form.setError,
        }) ||
        !validateCommentText(
          privateNote,
          form.setError,
          "privateNote",
          nonprofit.metadata?.privateNoteLimit
        ) ||
        !validateCommentText(commentText, form.setError, "commentText")
      ) {
        return;
      }

      const route = confirmationRouteForPaymentOption(
        paymentOption,
        isManualDaf
      );
      if (route) {
        navigate(route);
      }
    },
    (errors) => {
      logger.error({
        message: "Error validating form amount and frequency",
        data: errors,
      });
    }
  );

  const paymentOptionName = getNameForPaymentOption(
    paymentOption,
    formContext.paymentRequestInitializer.isApplePay,
    isManualDaf
  );

  const selectedPaymentSource = form.watch("paymentSource");

  const [frequencyErrorMessage, setFrequencyErrorMessage] = useState<
    string | undefined
  >(undefined);

  const [amountErrorMessage, setAmountErrorMessage] = useState<
    string | undefined
  >(undefined);

  const amount = form.watch("amount");

  const payOnlyWithCredit =
    formContext.availableGivingCredit?.gt(0) &&
    formContext.availableGivingCredit.gte(amount || 0);

  const { displayFGC, FGCDepleted } = useFallGivingChallengeActive(
    nonprofit.id
  );

  const showFGCNoMatchAvaliable =
    displayFGC && !FGCDepleted && !formContext.nonprofitMatchCampaign;

  const FGChint =
    displayFGC &&
    !FGCDepleted &&
    formContext.nonprofitMatchCampaign &&
    formContext.nonprofitMatchCampaign.code === FGC_CAMPAIGN_CODE
      ? frequency === DonationFrequency.MONTHLY
        ? "*Monthly donations will be matched for the first two months."
        : "*Make your donation monthly to unlock a match for the first two months."
      : undefined;

  const showFGCDepleted = displayFGC && FGCDepleted;

  useEffect(() => {
    const donationId = formContext.createOrUpdateDonationResult?.donation.id;
    if (
      formContext.donateAction !== DonateModalAction.UPDATE &&
      donationId &&
      paymentOption === DonationFlowPaymentOption.DAF
    ) {
      archiveDonation(donationId, formContext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stepper = useStepper(StepperType.MANUAL_DAF, 0);

  return (
    <PageContainer>
      {paymentOption === DonationFlowPaymentOption.DAF ? (
        <GoBackButton form={form} formContext={formContext} />
      ) : (
        <SelectDonationFlowPaymentOption
          selectedPaymentOption={paymentOption}
          paymentRequestReadyStatus={
            formContext.paymentRequestInitializer.readyStatus
          }
          paymentRequestIsApplePay={
            formContext.paymentRequestInitializer.isApplePay
          }
          paymentFlowOptions={formContext.paymentFlowOptions}
          showMorePaymentOptions={formContext.showMorePaymentOptions}
          setShowMorePaymentOptions={formContext.setShowMorePaymentOptions}
        />
      )}
      <ProcessContainer>
        <AccountBalanceMessage
          paymentOption={paymentOption}
          isApplePay={formContext.paymentRequestInitializer.isApplePay}
          availableGivingCredit={formContext.availableGivingCredit}
          inaccessibleGivingCredit={formContext.inaccessibleGivingCredit}
          isManualDaf={isManualDaf}
        />
        <form onSubmit={submit} css={verticalStackCss.l}>
          {isManualDaf && stepper}
          {paymentOption === DonationFlowPaymentOption.DAF && !isManualDaf && (
            <h4 css={textSizeCss.s}>Connect your DAF with DAFpay</h4>
          )}
          {formContext.lockMonthlyFrequency ? (
            <MonthlyDonationsMessage text={formContext.monthlyTitle} />
          ) : paymentOption === DonationFlowPaymentOption.VENMO ||
            (paymentOption === DonationFlowPaymentOption.DAF &&
              !isManualDaf) ? (
            <OneTimeDonationsMessage
              option={paymentOption}
              isApplePay={formContext.paymentRequestInitializer.isApplePay}
              isManualDaf={isManualDaf}
            />
          ) : (
            <Controller
              control={control}
              key="frequency"
              name="frequency"
              render={({ field: { onChange, value } }) => (
                <FrequencySelector
                  donateAction={formContext.donateAction}
                  matchingCampaign={formContext.nonprofitMatchCampaign}
                  fieldError={errors.frequency}
                  setFrequencyErrorMessage={setFrequencyErrorMessage}
                  frequencyErrorMessage={frequencyErrorMessage}
                  onChange={onChange}
                  frequency={value}
                  monthlyDescriptionOverride={
                    nonprofit.metadata?.customMonthlyDescription
                  }
                />
              )}
            />
          )}
          <div css={verticalStackCss.m}>
            <Controller
              control={control}
              key="amount"
              name="amount"
              render={({ field: { onChange, value } }) => (
                <Amount
                  shorten={formContext.shorten}
                  selectedPaymentSource={selectedPaymentSource}
                  onChange={onChange}
                  amount={value?.toString() || ""}
                  amountBig={formContext.amountBig}
                  fieldError={errors.amount}
                  nonprofit={nonprofit}
                  fundraiser={fundraiser || undefined}
                  availableGivingCredit={formContext.availableGivingCredit}
                  paymentOption={paymentOption}
                  minDonationValue={formContext.minValue}
                  maxDonationValue={formContext.maxValue}
                  setAmountErrorMessage={setAmountErrorMessage}
                  amountErrorMessage={amountErrorMessage}
                  frequency={frequency}
                  paymentMethod={form.getValues().paymentMethod}
                  suggestedAmountsFromUrl={formContext.suggestedAmountsFromUrl}
                />
              )}
            />
            {showFGCNoMatchAvaliable && <FGCNoMatchAvaliable />}
            {FGChint && <p>{FGChint}</p>}
            {showFGCDepleted && <FGCDepletedMsg />}
          </div>
          <PrivateNoteInput
            nonprofit={nonprofit}
            form={form}
            fundraiser={fundraiser}
          />
          <TestimonyInput nonprofit={nonprofit} form={form} />
          {PAYMENT_METHODS_WITHOUT_GIFTCARDS.includes(paymentOption) &&
            formContext.inaccessibleGivingCredit?.gt(0) && (
              <GiftCardUnsupportedMessage
                option={paymentOption}
                isApplePay={formContext.paymentRequestInitializer.isApplePay}
                isManualDaf={isManualDaf}
              />
            )}
          {(frequencyErrorMessage || amountErrorMessage) && (
            <div css={horizontalStackCss.xs}>
              <Icon
                iconImport={() => import("@components/Icon/icons/AlertIcon")}
                size={IconSize.MEDIUM}
                display={IconDisplay.ERROR}
              />
              <div css={verticalStackCss.xxxs}>
                {frequencyErrorMessage && (
                  <ErrorMessage>{frequencyErrorMessage}</ErrorMessage>
                )}
                {amountErrorMessage && (
                  <ErrorMessage>{amountErrorMessage}</ErrorMessage>
                )}
              </div>
            </div>
          )}
          <Button
            role={ButtonRole.PRIMARY}
            onClick={{
              kind: ButtonTargetKind.SUBMIT,
            }}
            data-tname="donateV3Submit"
          >
            Continue
            {payOnlyWithCredit
              ? " with credits"
              : paymentOptionName
              ? ` with ${paymentOptionName}`
              : ""}
          </Button>
        </form>
      </ProcessContainer>
    </PageContainer>
  );
};
