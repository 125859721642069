import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { TextInput } from "@components/TextInput";
import { BackButton } from "@components/donate/DonateV3/PaymentProcess/components/BackButton";
import { FullNameSection } from "@components/donate/DonateV3/PaymentProcess/components/FullNameSection";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { StockSummary } from "@components/donate/DonateV3/PaymentProcess/pages/Stocks/StockSummary";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { useSyncPaymentMethod } from "@components/donate/DonateV3/PaymentProcess/useSyncPaymentMethod";
import {
  validateFullNameText,
  validateText,
} from "@components/donate/DonateV3/PaymentProcess/validators";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { verticalStackCss } from "src/theme/spacing";
import { TextSize, textSizeCss } from "src/theme/text";
import { getWindow } from "src/utility/window";

export const StockSignatureDonorInfoPage = ({
  form,
  formContext,
  nonprofit,
  fundraiser,
}: {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse | null;
}) => {
  const stockSymbol = form.watch("stockSymbol");
  const stockAmount = form.watch("stockAmount");
  const firstName = form.watch("firstName");
  const lastName = form.watch("lastName");
  const email = form.watch("email");

  useSyncPaymentMethod({
    paymentOption: DonationFlowPaymentOption.STOCKS,
    form,
    formContext,
  });

  useEffect(() => {
    getWindow()?.scrollTo({ top: 0 });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const window = getWindow();
    if (window && (!stockSymbol || !stockAmount)) {
      navigate(
        paymentProcessRouteNameToPathMap[
          PaymentProcessRouteName.STOCKS_SIGNATURE
        ],
        { replace: true }
      );
    }
  }, [stockSymbol, stockAmount, navigate]);

  // TODO #15621: enable pull stock flow for everybody (remove this navigate)
  useEffect(() => {
    const window = getWindow();
    if (window && !nonprofit.metadata?.showStockPull) {
      navigate(
        paymentProcessRouteNameToPathMap[PaymentProcessRouteName.STOCKS_MANUAL],
        { replace: true }
      );
    }
  }, [navigate, nonprofit]);

  return (
    <PageContainer>
      <BackButton
        backAction={() => {
          navigate(-1);
        }}
        paymentOption={DonationFlowPaymentOption.STOCKS}
      />
      <ProcessContainer>
        <form css={verticalStackCss.l}>
          <div css={verticalStackCss.xxs}>
            <h5
              css={css`
                ${textSizeCss[TextSize.s]};
              `}
            >
              Your Donation
            </h5>
            <StockSummary stockSymbol={stockSymbol} stockAmount={stockAmount} />
          </div>
          <FullNameSection control={form.control} />
          <div css={verticalStackCss.xxs}>
            <h5
              css={css`
                ${textSizeCss[TextSize.s]};
              `}
            >
              Email
            </h5>
            <div css={verticalStackCss.s}>
              <Controller
                control={form.control}
                name="email"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    name="email"
                    id="email"
                    data-tname="email"
                    onChange={onChange}
                    value={value}
                    validationStatus={{
                      success: !form.formState.errors["email"]?.message,
                      message: form.formState.errors["email"]?.message,
                    }}
                    collapseDescriptionSpace
                  />
                )}
              />
            </div>
          </div>
          <div css={verticalStackCss.l}>
            <Button
              role={ButtonRole.PRIMARY}
              onClick={{
                kind: ButtonTargetKind.FUNCTION,
                action: () => {
                  let valid = true;
                  // prettier-ignore
                  {
                    valid = valid && validateFullNameText(firstName, "firstName", "First name", form.setError)
                    valid = valid && validateFullNameText(lastName, "lastName", "Last name", form.setError)
                    valid = valid && validateText(email, "email", "Email", form.setError)
                  }
                  if (!valid) {
                    return;
                  }
                  navigate(
                    paymentProcessRouteNameToPathMap[
                      PaymentProcessRouteName.STOCKS_SIGNATURE_BROKER
                    ]
                  );
                },
              }}
              data-tname="donateStockSignatureFlowDonorInfoNext"
            >
              Next
            </Button>
          </div>
        </form>
      </ProcessContainer>
    </PageContainer>
  );
};
