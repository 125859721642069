import iotsReporter from "@osdiab/io-ts-reporters";
import { Left } from "fp-ts/Either";
import { Errors } from "io-ts";

import { fixPrototypeChain } from "../helpers/extendBuiltins";

import { ApplicationError } from "./ApplicationError";

export const reporter = iotsReporter.report;

export class IotsCodecError extends ApplicationError<{
  errorMessages: string[];
  errors: Left<Errors>;
}> {
  public constructor(errors: Left<Errors>) {
    super("Validation errors from io-ts codec", {
      errorMessages: iotsReporter.report(errors),
      errors: errors,
    });

    // When extending Error (or any of its subclasses), need to explicitly set
    // prototype to get proper behavior of methods like `instanceof`
    fixPrototypeChain(new.target.prototype, this);
  }
}
