import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { ErrorMessage } from "@components/ErrorMessage";
import { BackButton } from "@components/donate/DonateV3/PaymentProcess/components/BackButton";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { StockSummary } from "@components/donate/DonateV3/PaymentProcess/pages/Stocks/StockSummary";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { useSyncPaymentMethod } from "@components/donate/DonateV3/PaymentProcess/useSyncPaymentMethod";
import {
  CreateOrUpdateDonationResult,
  DonateFormType,
} from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import dynamic from "next/dynamic";
import { useEffect, useMemo, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import type SignatureCanvas from "react-signature-canvas";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";
import { stockDonationAuthorizationLegalLanguage } from "@every.org/common/src/helpers/brokerage";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { verticalStackCss } from "src/theme/spacing";
import { TextSize, textSizeCss } from "src/theme/text";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

const StockSignatureCanvas = dynamic(
  () =>
    import(
      "@components/donate/DonateV3/PaymentProcess/pages/Stocks/StockSignatureCanvas"
    ),
  { ssr: false }
);

export const StockSignatureSignPage = ({
  form,
  formContext,
  nonprofit,
  fundraiser,
  submitDonation,
  handleConfirmedDonation,
  createOrUpdateDonationResult,
  paymentOption,
}: {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse | null;
  submitDonation: (
    formValues: DonateFormType
  ) => Promise<CreateOrUpdateDonationResult | undefined>;
  handleConfirmedDonation: (result: CreateOrUpdateDonationResult) => boolean;
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
  paymentOption: DonationFlowPaymentOption;
}) => {
  const stockSymbol = form.watch("stockSymbol");
  const stockAmount = form.watch("stockAmount");
  const firstName = form.watch("firstName");
  const lastName = form.watch("lastName");
  const email = form.watch("email");
  const brokerageFirm = form.watch("brokerageFirm");
  const brokerageAccountNumber = form.watch("brokerageAccountNumber");

  const [signatureErrorMessage, setSignatureErrorMessage] = useState<
    string | undefined
  >(undefined);

  useSyncPaymentMethod({
    paymentOption: DonationFlowPaymentOption.STOCKS,
    form,
    formContext,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getWindow()?.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    form.setValue("brokeragePull", true);
  }, [form]);

  useEffect(() => {
    const window = getWindow();
    if (window) {
      if (!stockSymbol || !stockAmount) {
        navigate(
          paymentProcessRouteNameToPathMap[
            PaymentProcessRouteName.STOCKS_SIGNATURE
          ],
          { replace: true }
        );
      }
      if (!firstName || !lastName || !email) {
        navigate(
          paymentProcessRouteNameToPathMap[
            PaymentProcessRouteName.STOCKS_SIGNATURE_DONOR_INFO
          ],
          { replace: true }
        );
      }
      if (!brokerageFirm || !brokerageAccountNumber) {
        navigate(
          paymentProcessRouteNameToPathMap[
            PaymentProcessRouteName.STOCKS_SIGNATURE_DONOR_INFO
          ],
          { replace: true }
        );
      }
    }
  }, [
    firstName,
    lastName,
    email,
    stockSymbol,
    stockAmount,
    brokerageFirm,
    brokerageAccountNumber,
    navigate,
  ]);

  // TODO #15621: enable pull stock flow for everybody (remove this navigate)
  useEffect(() => {
    const window = getWindow();
    if (window && !nonprofit.metadata?.showStockPull) {
      navigate(
        paymentProcessRouteNameToPathMap[PaymentProcessRouteName.STOCKS_MANUAL],
        { replace: true }
      );
    }
  }, [navigate, nonprofit]);

  const sigPadRef = useRef<SignatureCanvas>();

  const submit = useMemo(
    () =>
      form.handleSubmit(
        async () => {
          const sigPad = sigPadRef.current;
          if (!sigPad || sigPad.isEmpty()) {
            setSignatureErrorMessage("Please sign above to continue");
            return;
          }

          const value = sigPad.getTrimmedCanvas().toDataURL("image/png");
          form.setValue("signature", value);

          const formValues = form.getValues();

          if (!createOrUpdateDonationResult) {
            logger.info({
              message: "Submitted!",
              data: {
                ...formValues,
                paymentOption,
              },
            });
          }
          const result =
            createOrUpdateDonationResult ||
            (await submitDonation({
              ...formValues,
              paymentTab: paymentOption,
            }));
          if (result) {
            handleConfirmedDonation(result) ||
              navigate(
                paymentProcessRouteNameToPathMap[
                  PaymentProcessRouteName.THANK_YOU
                ]
              );
          }
        },
        (errors) => {
          logger.error({
            message: "Error validating form when confirming donation",
            data: errors,
          });
        }
      ),
    [
      form,
      navigate,
      submitDonation,
      createOrUpdateDonationResult,
      handleConfirmedDonation,
      paymentOption,
    ]
  );

  return (
    <PageContainer>
      <BackButton
        backAction={() => {
          navigate(-1);
        }}
        paymentOption={DonationFlowPaymentOption.STOCKS}
      />
      <ProcessContainer>
        <form css={verticalStackCss.l} onSubmit={submit}>
          <h5
            css={css`
              ${textSizeCss[TextSize.s]};
            `}
          >
            Your Donation
          </h5>
          <StockSummary stockSymbol={stockSymbol} stockAmount={stockAmount} />

          <div css={verticalStackCss.xxs}>
            <h5
              css={css`
                ${textSizeCss[TextSize.s]};
              `}
            >
              {firstName} {lastName} Signature
            </h5>
            <StockSignatureCanvas
              forwardedRef={(ref) => {
                if (ref) {
                  sigPadRef.current = ref;
                }
              }}
              onBegin={() => {
                setSignatureErrorMessage(undefined);
              }}
            />
          </div>
          <p>{stockDonationAuthorizationLegalLanguage(brokerageFirm)}</p>
          {signatureErrorMessage && (
            <ErrorMessage>{signatureErrorMessage}</ErrorMessage>
          )}
          <div css={verticalStackCss.xs}>
            <Button
              data-tname="donateStockSignatureFlowSignClear"
              role={ButtonRole.SECONDARY}
              onClick={{
                kind: ButtonTargetKind.FUNCTION,
                action: () =>
                  sigPadRef.current ? sigPadRef.current.clear() : null,
              }}
            >
              Clear
            </Button>
            <Button
              role={ButtonRole.PRIMARY}
              onClick={{
                kind: ButtonTargetKind.SUBMIT,
              }}
              data-tname="donateStockSignatureFlowSignNext"
              submitting={form.formState.isSubmitting}
              disabled={form.formState.isSubmitting}
            >
              Complete stock donation
            </Button>
          </div>
        </form>
      </ProcessContainer>
    </PageContainer>
  );
};
