import * as t from "io-ts";
import { BooleanFromString } from "io-ts-types/BooleanFromString";
import { NumberFromString } from "io-ts-types/NumberFromString";

import { stringEnumCodec } from "../codecs";
import {
  userResponseCodec,
  nonprofitResponseCodec,
  donationChargeResponseCodec,
  donationResponseCodec,
  tagResponseCodec,
} from "../codecs/entities";
import { NonprofitRevenueSize } from "../entity/types";
import { HttpMethod } from "../helpers/http";

import { makeCachedRouteSpec, listParamsCodec, listResponseCodec } from ".";

const commonSearchParamsCodec = t.type({ query: t.string });
const termlessSearchParamsCodec = t.partial({ query: t.string });

export const userSearchResultCodec = t.intersection([
  t.type({
    user: userResponseCodec,
  }),
  t.partial({
    donationCharges: t.array(donationChargeResponseCodec),
    donations: t.array(donationResponseCodec),
  }),
]);
export type UserSearchResult = t.TypeOf<typeof userSearchResultCodec>;

export enum SearchSource {
  BING_GUIDESTAR = "BING_GUIDESTAR",
  BING_WEB = "BING_WEB",
  DB_NAME_SIMILARITY = "DB_NAME_SIMILARITY",
  DB_SLUG_SIMILARITY = "DB_SLUG_SIMILARITY",
  EIN_MATCH = "EIN_MATCH",
  TERMLESS = "TERMLESS",
  SEARCH_FEED = "SEARCH_FEED",
}
// TODO: remove after we gather enough information for #7489
const nonprofitSearchSourcesResultCodec = t.partial({
  nonprofitSearchSources: t.array(
    stringEnumCodec({
      name: "SearchSource",
      enumObject: SearchSource,
    })
  ),
});

const nonprofitSearchResultCodec = t.type({
  nonprofit: nonprofitResponseCodec,
});
export type NonprofitSearchResult = t.TypeOf<typeof nonprofitSearchResultCodec>;

const paginatedSearchParamsCodec = t.intersection([
  commonSearchParamsCodec,
  listParamsCodec,
]);

const searchAllRouteResponseBodyCodec = t.intersection([
  t.type({
    nonprofits: t.array(nonprofitSearchResultCodec),
    users: t.array(userSearchResultCodec),
    totalEstimatedMatchedNonprofits: t.number,
  }),
  t.partial({ totalAmount: t.number }),
  // TODO: remove after we gather enough information for #7489
  nonprofitSearchSourcesResultCodec,
  listResponseCodec,
]);
export type SearchAllResponseBody = t.TypeOf<
  typeof searchAllRouteResponseBodyCodec
>;
export const searchParamsCodec = t.intersection([
  termlessSearchParamsCodec,
  listParamsCodec,
  t.partial({
    causes: t.string,
    prioritizeCauseCount: BooleanFromString, // true to return nonprofits matching many causes at the top
    includeCount: BooleanFromString, // true to include the total amount of matching nonprofits
  }),
  t.partial({
    size: stringEnumCodec({ name: "size", enumObject: NonprofitRevenueSize }),
  }),
  t.partial({
    lat: NumberFromString,
    lng: NumberFromString,
    distance: NumberFromString,
  }),
]);
export type SearchParams = t.TypeOf<typeof searchParamsCodec>;
export const searchAllRouteSpec = makeCachedRouteSpec({
  path: "/search",
  method: HttpMethod.GET,
  version: 0,
  tokensCodec: t.type({}),
  paramsCodec: searchParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: searchAllRouteResponseBodyCodec,
});

export const tagListResultCodec = t.intersection([
  t.type({
    causes: t.array(tagResponseCodec),
  }),
  listResponseCodec,
]);
export type TagListResponse = t.TypeOf<typeof tagListResultCodec>;
export const searchTagRouteSpec = makeCachedRouteSpec({
  path: "/search/causes",
  method: HttpMethod.GET,
  version: 0,
  tokensCodec: t.type({}),
  paramsCodec: paginatedSearchParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: tagListResultCodec,
});
