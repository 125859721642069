import { createClient } from "@vercel/edge-config";
import { EdgeConfigDataAdapter } from "statsig-node-vercel";

import { STATSIG_PROXY_URL } from "@every.org/common/src/entity/constants";
import { StatsigTier } from "@every.org/common/src/entity/types";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

export type StatsigNode = typeof import("statsig-node")["Statsig"];

let initialized = false;
const initializationPromise = (statsig: StatsigNode) => {
  const tier: StatsigTier = ["production", "staging", "development"].includes(
    process.env.NEXT_PUBLIC_ENVIRONMENT_NAME || ""
  )
    ? (process.env.NEXT_PUBLIC_ENVIRONMENT_NAME as StatsigTier)
    : undefined;

  return statsig
    .initialize(
      assertEnvPresent(
        process.env.STATSIG_SECRET_API_KEY,
        "STATSIG_SECRET_API_KEY"
      ),
      {
        localMode: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === "development",
        environment: {
          tier,
        },
        initTimeoutMs: 5000,
        dataAdapter: getEdgeConfigDataAdapter(),
        api: STATSIG_PROXY_URL,
      }
    )
    .then(() => {
      initialized = true;
    });
};

const getEdgeConfigDataAdapter = () => {
  if (process.env.EDGE_CONFIG && process.env.STATSIG_EDGE_CONFIG_ITEM_KEY) {
    const edgeConfigClient = createClient(process.env.EDGE_CONFIG);
    const dataAdapter = new EdgeConfigDataAdapter({
      edgeConfigClient: edgeConfigClient,
      edgeConfigItemKey: process.env.STATSIG_EDGE_CONFIG_ITEM_KEY,
    });

    return dataAdapter;
  }

  return undefined;
};

export const initializeStatsig = async (statsig: StatsigNode) => {
  if (!initialized) {
    await initializationPromise(statsig);
  }
};
