import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { MAX_INPUT_WIDTH } from "src/components/Nonprofit/EditForm/shared";
import { TextArea, TextInput } from "src/components/TextInput";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { FontWeight } from "src/theme/text";

export const inputWidthCss = css`
  width: 100%;
  max-width: ${MAX_INPUT_WIDTH};
`;

export const StyledTextInput = styled(TextInput)`
  ${inputWidthCss};
`;

export const StyledTextArea = styled(TextArea)`
  ${inputWidthCss};
`;

export const StyledInputDescription = styled.div`
  ${verticalStackCss.xs}
  color: var(${colorCssVars.text.secondary});

  strong {
    color: var(${colorCssVars.text.body});
    font-weight: ${FontWeight.MEDIUM};
  }
`;

export const InputWithLabelContainer = styled.div`
  ${verticalStackCss.xs}
`;

export const TopActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionButtonsContainer = styled.div`
  ${horizontalStackCss.m};
  align-items: center;
  justify-content: flex-end;
  padding: 0 ${spacing.s};

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      padding: 0 ${spacing.l};
    `,
  })}
`;
