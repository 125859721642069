import { fixPrototypeChain } from "@every.org/common/src/helpers/extendBuiltins";

import { ApiResponseShape } from "src/utility/apiClient/types";
import { HttpStatus } from "src/utility/httpStatus";

/**
 * Errors thrown by our API. Includes the response's json-formatted body as the
 * data property.
 */
export class ApiError<
  Data extends ApiResponseShape = ApiResponseShape
> extends Error {
  public data: Data;
  public httpStatus: number;

  public constructor(params: {
    message: string;
    httpStatus: number;
    data: Data;
  }) {
    super(params.message);

    this.data = params.data;
    this.httpStatus = params.httpStatus;

    // When extending Error (or any of its subclasses), need to explicitly set
    // prototype to get proper behavior of methods like `instanceof`
    fixPrototypeChain(new.target.prototype, this);
  }
}

export function is400LevelApiError(e: unknown): e is ApiError {
  return e instanceof ApiError && e.httpStatus >= 400 && e.httpStatus <= 499;
}

export function isNotFoundApiError(e: unknown): e is ApiError {
  return e instanceof ApiError && e.httpStatus === HttpStatus.NOT_FOUND;
}

export function isForbiddenApiError(e: unknown): e is ApiError {
  return e instanceof ApiError && e.httpStatus === HttpStatus.FORBIDDEN;
}
