import { CloudinaryImg } from "@components/CloudinaryImg";
import styled from "@emotion/styled";
import { ImageProps } from "next/image";
import React from "react";

import { colorPalette } from "@every.org/common/src/display/palette";

import { IMAGE_NONPROFIT_DEFAULT_MIN_DIMENSIONS } from "src/constants/imagesSizes";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";

const LARGE_SCREEN_COVER_HEIGHT = 200;
const COVER_HEIGHT = 150;
const IMAGE_RATIO = 1.91;

const StyledCover = styled.div<{
  hasImage: boolean;
  editable?: boolean;
  height?: number;
  largeScreenHeight?: number;
  backgroundImage?: string;
}>`
  overflow: hidden;
  width: 100%;
  position: relative;
  height: ${({ height }) => height};
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url("${backgroundImage}")` : undefined};
  padding-bottom: ${({ hasImage, editable }) =>
    hasImage || editable ? `calc(1 / ${IMAGE_RATIO} * 100%)` : 0};

  & > img {
    width: 100%;
    position: absolute;
    min-height: 100%;
    min-width: 100%;
  }
  ${({ largeScreenHeight, height }) =>
    cssForMediaSize({
      min: MediaSize.MEDIUM,
      /* We only want to override largeScreenHeight when height is 0 */
      css: { height: height === 0 ? height : largeScreenHeight },
    })}
`;

interface CoverProps {
  coverImageCloudinaryId: string | null;
  onError?: () => void;
  editable?: boolean;
  height?: number;
  largeScreenHeight?: number;
  placeholderImage?: string;
  priority?: ImageProps["priority"];
  isLargeCard?: boolean;
  alt?: string;
}

export const CardCover: React.FCC<CoverProps> = React.memo(
  function CardCoverImpl({
    coverImageCloudinaryId,
    onError,
    editable,
    height = COVER_HEIGHT,
    largeScreenHeight = LARGE_SCREEN_COVER_HEIGHT,
    placeholderImage = undefined,
    priority,
    isLargeCard,
    alt,
  }) {
    return (
      <StyledCover
        hasImage={!!coverImageCloudinaryId || !!placeholderImage}
        editable={editable}
        backgroundImage={!coverImageCloudinaryId ? placeholderImage : undefined}
        height={coverImageCloudinaryId || !placeholderImage ? 0 : height}
        largeScreenHeight={largeScreenHeight}
      >
        {coverImageCloudinaryId ? (
          <CloudinaryImg
            onError={onError}
            cloudinaryId={coverImageCloudinaryId}
            alt={alt}
            sizes={`(max-width: 500px) 100vw, (max-width: 740px) 424px, (max-width: 1000px) 50vw, ${
              isLargeCard ? 950 : 350
            }px`}
            dimensions={{
              width: IMAGE_NONPROFIT_DEFAULT_MIN_DIMENSIONS.width,
              height: IMAGE_NONPROFIT_DEFAULT_MIN_DIMENSIONS.height,
            }}
            priority={priority}
          />
        ) : (
          <div
            css={{
              height: `${height}px`,
              background: placeholderImage
                ? `url("${placeholderImage}")`
                : `${colorPalette.tealLogoDark}`,
            }}
          />
        )}
      </StyledCover>
    );
  }
);
