export function stockDonationAuthorizationLegalLanguage(
  brokerageFirm: string | undefined
) {
  brokerageFirm = brokerageFirm ?? "";
  return `Please accept this as authorization to irrevocably transfer the above-listed security(ies) from my/our ${brokerageFirm} account to Every.org. I hereby release and discharge both ${brokerageFirm} and Every.org and their affiliates from any liability or claims in connection with the aforementioned instructions and agree to indemnify and hold ${brokerageFirm} and Every.org harmless against any losses from any action, claim, or demand of any person based upon ${brokerageFirm} or Every.org acting under these instructions.`;
}

const STOCK_BROKERAGE = {
  institution: "AlpacaSecurities LLC",
  dtc: "3021",
  account: "456981656",
  title: "Every.org",
};

const MUTUAL_FUND_BROKERAGE = {
  institution: "Vanguard Brokerage Services",
  dtc: "0062",
  account: "43851578",
  title: "Every.org",
};

/**
 * Use Alpaca by default, for all Stocks and ETFs.
 * Alpaca does not support mutual funds where the
 * ticker symbol ends in X, so then use FRB.
 */
export function getBrokerageDtcInfo(tickerSymbol?: string) {
  if (tickerSymbol?.toUpperCase()?.endsWith("X")) {
    return MUTUAL_FUND_BROKERAGE;
  }
  return STOCK_BROKERAGE;
}
