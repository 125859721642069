import { ThankYouPageContainer } from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import { AddComment } from "@components/donate/DonateV3/PaymentProcess/pages/DonationThankYou/AddComment";
import { Signup } from "@components/donate/DonateV3/PaymentProcess/pages/DonationThankYou/Signup";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import {
  CreateOrUpdateDonationResult,
  DonateFormType,
  PaymentProcessProps,
} from "@components/donate/DonateV3/types";
import { usePostDonationAction } from "@components/donate/DonateV3/usePostDonationAction";
import { css } from "@emotion/react";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { commentTextCodec } from "@every.org/common/src/codecs/text";
import { PaymentMethod } from "@every.org/common/src/entity/types";
import { DONATE_HASH } from "@every.org/common/src/helpers/clientRoutes";

import {
  useABTestingId,
  useIsLoggedIn,
  useLoggedInOrGuestUserOrUndefined,
} from "src/context/AuthContext/hooks";
import { colorCssVars } from "src/theme/color";
import { verticalStackCss } from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";
import { getWindow } from "src/utility/window";

export type DonationConfirmationProps = {
  donateModalProps: PaymentProcessProps;
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
  goToGivelistUrl: string | null;
};

export const getEmailReceiptMessage = (
  paymentsMethod: PaymentMethod,
  brokeragePull?: boolean
) =>
  paymentsMethod === PaymentMethod.STOCKS && brokeragePull
    ? "We are sending your donation request to your broker."
    : `We will email your receipt${
        [
          PaymentMethod.CRYPTO,
          PaymentMethod.DAF,
          PaymentMethod.STOCKS,
          PaymentMethod.MUTUAL_FUND,
        ].includes(paymentsMethod)
          ? " once your transaction is confirmed"
          : ""
      }.`;

export const ThankYouMessage = (props: {
  paymentMethod: PaymentMethod;
  brokeragePull?: boolean;
}) => (
  <div css={[verticalStackCss.xxs, { width: "100%" }]}>
    <strong
      css={[
        textSizeCss.xl,
        css`
          font-weight: ${FontWeight.BOLD};
        `,
      ]}
    >
      Thank you!{" "}
    </strong>
    <span css={{ color: `var(${colorCssVars.text.secondary})` }}>
      {getEmailReceiptMessage(props.paymentMethod, props.brokeragePull)}
    </span>
  </div>
);

export const DonationThankYou = ({
  donateModalProps: {
    defaultComment,
    nonprofit,
    redirectUrl,
    onComplete,
    donateAction,
  },
  form,
  formContext,
  createOrUpdateDonationResult,
  goToGivelistUrl,
}: DonationConfirmationProps) => {
  const abTestingId = useABTestingId();
  const loggedInOrGuestUser = useLoggedInOrGuestUserOrUndefined();
  const isLoggedIn = useIsLoggedIn();
  const brokeragePull = form.watch("brokeragePull");

  const {
    shorten,
    setShowNonprofitInfoSectionSectionOnMobile: setShowNonprofitInfoSection,
  } = formContext;

  useEffect(() => {
    getWindow()?.scrollTo({ top: 0 });
    setShowNonprofitInfoSection(false);

    return () => {
      setShowNonprofitInfoSection(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postDonationAction = usePostDonationAction({
    redirectUrl,
    onComplete,
    nonprofit,
  });

  const navigate = useNavigate();

  const email = form.watch("email");
  const commentTextString = form.watch("commentText");
  const commentText = commentTextCodec.is(commentTextString)
    ? commentTextString
    : undefined;

  if (!createOrUpdateDonationResult) {
    // TODO it would be cool if we could actually load this donation and show
    // the confirmation page
    navigate(DONATE_HASH, { replace: true });
    return null;
  }

  return isLoggedIn ? (
    <ThankYouPageContainer>
      <ThankYouMessage
        paymentMethod={createOrUpdateDonationResult.donation.paymentMethod}
        brokeragePull={brokeragePull}
      />
      <AddComment
        donateAction={donateAction}
        shorten={shorten}
        defaultComment={commentText || defaultComment}
        nonprofit={nonprofit}
        createOrUpdateDonationResult={createOrUpdateDonationResult}
        user={loggedInOrGuestUser}
        postDonationAction={postDonationAction}
        goToGivelistUrl={goToGivelistUrl}
      />
    </ThankYouPageContainer>
  ) : (
    <Signup
      abTestingId={abTestingId}
      createOrUpdateDonationResult={createOrUpdateDonationResult}
      userEmail={email}
      goToGivelistUrl={goToGivelistUrl}
      postDonationAction={postDonationAction}
      paymentMethod={createOrUpdateDonationResult.donation.paymentMethod}
    />
  );
};
