import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import styled from "@emotion/styled";

import { spacing } from "@every.org/common/src/display/spacing";
import { TextSize } from "@every.org/common/src/display/text";

import { colorCssVars } from "src/theme/color";
import { verticalStackCss, horizontalStackCss } from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";

const SummaryWrapper = styled.div`
  color: var(${colorCssVars.text.secondary});
  ${textSizeCss[TextSize.s]};
  ${verticalStackCss.xs};
`;

const SummaryList = styled.ul`
  li {
    ${horizontalStackCss.none};
    justify-content: space-between;
    padding: ${spacing.s} ${spacing.none};
    box-shadow: 0px 1px 0px #eaeded;
    > span {
      color: var(${colorCssVars.text.body});
    }

    button {
      color: var(${colorCssVars.text.secondary}) !important;
      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      color: var(${colorCssVars.accent.large});
    }
  }
`;

export const StockSummary = ({
  stockSymbol,
  stockAmount,
}: Pick<DonateFormContext, "stockSymbol" | "stockAmount">) => {
  return (
    <SummaryWrapper>
      <SummaryList>
        <li>
          <div>Stock Symbol</div>
          <div>{stockSymbol}</div>
        </li>
        <li>
          <div>Number of Shares</div>
          <div>{stockAmount}</div>
        </li>
      </SummaryList>
    </SummaryWrapper>
  );
};
