import { ErrorMessage } from "@components/ErrorMessage";
import { Loading } from "@components/LoadingIndicator";
import { AddTipAndDonationSummary } from "@components/donate/DonateV3/PaymentProcess/components/AddTipAndDonationSummary";
import { Chariot } from "@components/donate/DonateV3/PaymentProcess/components/Chariot";
import { DonorInfo } from "@components/donate/DonateV3/PaymentProcess/components/DonorInfo";
import { GoBackButton } from "@components/donate/DonateV3/PaymentProcess/components/GoBackButton";
import { OurPaypalButton } from "@components/donate/DonateV3/PaymentProcess/components/OurPaypalButton";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import { PaymentMethodPicker } from "@components/donate/DonateV3/PaymentProcess/components/PaymentMethodPicker";
import {
  CONFIRMATION_POSTFIX,
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { PaymentRequestButton } from "@components/donate/DonateV3/PaymentProcess/components/PaymentRequestButton";
import { SelectDonationFlowPaymentOption } from "@components/donate/DonateV3/PaymentProcess/components/SelectFlowPaymentOption";
import { ShareInfo } from "@components/donate/DonateV3/PaymentProcess/components/ShareInfo";
import { archiveDonation } from "@components/donate/DonateV3/PaymentProcess/helpers";
import { getMaxValueForPaymentOption } from "@components/donate/DonateV3/PaymentProcess/pages/Donate";
import { DonateButton } from "@components/donate/DonateV3/PaymentProcess/pages/Donate/DonateButton";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import {
  StepperType,
  useStepper,
} from "@components/donate/DonateV3/PaymentProcess/useStepper";
import { useSyncPaymentMethod } from "@components/donate/DonateV3/PaymentProcess/useSyncPaymentMethod";
import {
  validateAmountAndFrequency,
  validateCommentText,
} from "@components/donate/DonateV3/PaymentProcess/validators";
import {
  CreateOrUpdateDonationResult,
  DonateFormType,
  DonateModalAction,
  DONATE_FORM_ERROR,
} from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import Big from "big.js";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { spacing } from "@every.org/common/src/display/spacing";
import {
  DonationFrequency,
  PaymentSourceType,
  DonationFlowPaymentOption,
} from "@every.org/common/src/entity/types";
import {
  getRoutePath,
  ClientRouteName,
  URLFormat,
  DONATE_HASH,
} from "@every.org/common/src/helpers/clientRoutes";

import { AuthContext } from "src/context/AuthContext";
import { useLoggedInOrGuestUserOrUndefined } from "src/context/AuthContext/hooks";
import { AuthStatus } from "src/context/AuthContext/types";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

export const DonationConfirmation = ({
  nonprofit,
  form,
  formContext,
  submitDonation,
  handleConfirmedDonation,
  createOrUpdateDonationResult,
  paymentOption,
  fundraiser,
  isManualDaf,
}: {
  nonprofit: ContextNonprofit;
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  submitDonation: (
    formValues: DonateFormType
  ) => Promise<CreateOrUpdateDonationResult | undefined>;
  handleConfirmedDonation: (result: CreateOrUpdateDonationResult) => boolean;
  paymentOption: DonationFlowPaymentOption;
  fundraiser?: FundraiserResponse | null;
  createOrUpdateDonationResult?: CreateOrUpdateDonationResult;
  isManualDaf?: boolean;
}) => {
  const {
    formState: { errors },
    control,
  } = form;
  const { successUrl } = formContext;
  const loggedInOrGuestUser = useLoggedInOrGuestUserOrUndefined();

  const authState = useContext(AuthContext);

  const location = useLocation();
  const navigate = useNavigate();
  const router = useEdoRouter();
  const navigationType = useNavigationType();

  useEffect(() => {
    const locationArray = location.pathname.split("/");
    if (
      navigationType === "POP" &&
      locationArray[3] &&
      locationArray[3] === "confirm"
    ) {
      navigate(`${DONATE_HASH}/${locationArray[2]}`);
    }
  }, [navigate, navigationType, location]);

  useEffect(() => {
    getWindow()?.scrollTo({ top: 0 });
  }, []);

  useSyncPaymentMethod({ paymentOption, form, formContext });

  const clearFormError = useCallback(async () => {
    if (errors.DONATE_FORM_ERROR) {
      form.clearErrors(DONATE_FORM_ERROR);
      await formContext.refreshNonce();
    }
  }, [form, formContext, errors]);

  const [paypalButton, setPaypalButton] = useState<
    React.ReactNode | undefined
  >();
  const [showPaypalButton, setShowPaypalButton] = useState(
    !!(
      [
        DonationFlowPaymentOption.PAYPAL,
        DonationFlowPaymentOption.VENMO,
      ].includes(paymentOption) && createOrUpdateDonationResult
    )
  );

  const [showChariotButton, setShowChariotButton] = useState(
    paymentOption === DonationFlowPaymentOption.DAF &&
      !!createOrUpdateDonationResult
  );

  const { nonprofitMatchCampaign, minValue, maxValue, shorten } = formContext;
  const displayShareMatchForm =
    nonprofitMatchCampaign?.matchShare &&
    nonprofitMatchCampaign?.shareMatchAmount > 0;

  const handleDonationComplete = useCallback(
    (result?: CreateOrUpdateDonationResult) => {
      const isGuestWithExistingEmail =
        loggedInOrGuestUser?.isGuestWithExistingEmail;
      const completedDonation = result || createOrUpdateDonationResult;
      if (isManualDaf) {
        navigate(
          paymentProcessRouteNameToPathMap[
            PaymentProcessRouteName.DAF_MANUAL_INSTRUCTIONS
          ]
        );
        return;
      }
      if (paymentOption === DonationFlowPaymentOption.STOCKS) {
        navigate(
          paymentProcessRouteNameToPathMap[
            PaymentProcessRouteName.STOCKS_INSTRUCTIONS
          ]
        );
        return;
      }
      if (paymentOption === DonationFlowPaymentOption.CRYPTO) {
        navigate(
          paymentProcessRouteNameToPathMap[
            PaymentProcessRouteName.CRYPTO_COMPLETE
          ]
        );
        return;
      }
      if (completedDonation && !handleConfirmedDonation(completedDonation)) {
        if (formContext.donateAction === DonateModalAction.UPDATE) {
          router.push(
            getRoutePath({
              name: ClientRouteName.MY_GIVING_RECURRING,
              format: URLFormat.RELATIVE,
              query: {},
            })
          );
          return;
        }
        if (isGuestWithExistingEmail && !successUrl) {
          navigate(
            paymentProcessRouteNameToPathMap[PaymentProcessRouteName.LOGIN]
          );
          return;
        }
        if (!formContext.isGiftCardPurchase) {
          navigate(
            displayShareMatchForm
              ? paymentProcessRouteNameToPathMap[
                  PaymentProcessRouteName.SHARE_MATCH
                ]
              : paymentProcessRouteNameToPathMap[
                  PaymentProcessRouteName.THANK_YOU
                ]
          );
        }
      }
    },
    [
      formContext.donateAction,
      createOrUpdateDonationResult,
      handleConfirmedDonation,
      navigate,
      router,
      displayShareMatchForm,
      paymentOption,
      formContext.isGiftCardPurchase,
      loggedInOrGuestUser,
      successUrl,
      isManualDaf,
    ]
  );

  const amount = form.watch("amount");
  const frequency = form.watch("frequency");
  const selectedPaymentSource = form.watch("paymentSource");
  const firstName = form.watch("firstName");
  const lastName = form.watch("lastName");
  const email = form.watch("email");
  const privateNote = form.watch("privateNote");
  const commentText = form.watch("commentText");
  const currency = form.watch("currency");
  const cryptoCurrency = form.watch("cryptoCurrency");

  /**
   * On first render, if amount and frequency are not valid, go back to the
   * previous step
   */
  useEffect(() => {
    if (
      paymentOption !== DonationFlowPaymentOption.CRYPTO &&
      paymentOption !== DonationFlowPaymentOption.STOCKS
    ) {
      if (
        !validateAmountAndFrequency({
          frequency,
          amount: amount?.toString() || "",
          minValue,
          maxValue: getMaxValueForPaymentOption(
            paymentOption,
            currency,
            maxValue.amount
          ),
          shorten,
          setError: form.setError,
        }) ||
        !validateCommentText(
          privateNote,
          form.setError,
          "privateNote",
          nonprofit.metadata?.privateNoteLimit
        ) ||
        !validateCommentText(commentText, form.setError, "commentText")
      ) {
        navigate(location.pathname.replace(CONFIRMATION_POSTFIX, ""), {
          replace: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Paypal case: This needs to happen
   * whenever a detail about the donation changes, as currently we require the
   * Paypal Button to have a donation with matching details.
   *
   * Manual DAF case: Reset the donation state when the user returns to the confirmation page form the instruction page
   *
   * TODO: One of
   * * update the donation instead of creating a new one
   * * be more flexible when the paypal donation details don't match the donation
   * * initialize a donation as a blank slate and fill in the details when the paypal donation completes
   */
  const resetCreatedDonation = useCallback(() => {
    const donationId = createOrUpdateDonationResult?.donation.id;
    setShowPaypalButton(false);
    setShowChariotButton(false);
    if (donationId) {
      archiveDonation(donationId, formContext);
    }
  }, [createOrUpdateDonationResult?.donation.id, formContext]);

  useEffect(() => {
    if (
      formContext.donateAction !== DonateModalAction.UPDATE &&
      (paymentOption === DonationFlowPaymentOption.DAF ||
        paymentOption === DonationFlowPaymentOption.STOCKS) &&
      createOrUpdateDonationResult
    ) {
      resetCreatedDonation();
      // forbid the user from going back to the insructions page without ckicking the "Get instructions" button
      navigate(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * If the current donation details don't match the details of the created
   * donation, then reset the donation state so we can initialize a new paypal
   * button.
   */
  useEffect(() => {
    const { amount, frequency, amountToEveryOrg } = form.getValues();
    if (
      (showPaypalButton || showChariotButton) &&
      createOrUpdateDonationResult &&
      (!createOrUpdateDonationResult.donation.value.amount.eq(amount || 0) ||
        createOrUpdateDonationResult.donation.frequency !== frequency ||
        !createOrUpdateDonationResult.donation.tipAmount.amount.eq(
          amountToEveryOrg || 0
        ))
    ) {
      resetCreatedDonation();
    }
  }, [
    createOrUpdateDonationResult,
    form,
    resetCreatedDonation,
    showPaypalButton,
    showChariotButton,
  ]);

  useEffect(() => {
    const { amount, frequency } = form.getValues();
    const { amountToCharge } = formContext;
    const donationId = createOrUpdateDonationResult?.donation.id;
    if (
      (paymentOption === DonationFlowPaymentOption.PAYPAL ||
        paymentOption === DonationFlowPaymentOption.VENMO) &&
      amount &&
      frequency &&
      donationId &&
      !paypalButton
    ) {
      const archivePaypal = (errorMessage?: string) => {
        resetCreatedDonation();
        errorMessage &&
          form.setError(DONATE_FORM_ERROR, {
            type: "string",
            message: errorMessage,
          });
      };
      const button = (
        <OurPaypalButton
          amount={amountToCharge.toString()}
          frequency={frequency}
          donationId={donationId}
          finishCallback={handleDonationComplete}
          cancelCallback={archivePaypal}
          enableVenmo={paymentOption === DonationFlowPaymentOption.VENMO}
        />
      );
      setPaypalButton(button);
    }
  }, [
    form,
    formContext,
    createOrUpdateDonationResult,
    handleDonationComplete,
    paymentOption,
    resetCreatedDonation,
    paypalButton,
  ]);

  const submit = form.handleSubmit(
    async (formValues) => {
      logger.info({
        message: "Submitted!",
        data: {
          ...formValues,
          paymentTab: paymentOption,
        },
      });
      const result = await submitDonation({
        ...formValues,
        paymentTab: paymentOption,
      });
      if (result) {
        if (
          paymentOption === DonationFlowPaymentOption.PAYPAL ||
          paymentOption === DonationFlowPaymentOption.VENMO
        ) {
          setShowPaypalButton(true);
        } else if (
          paymentOption === DonationFlowPaymentOption.DAF &&
          !isManualDaf
        ) {
          setShowChariotButton(true);
        } else {
          handleDonationComplete(result);
        }
      }
    },
    (errors) => {
      logger.error({
        message: "Error validating form when confirming donation",
        data: errors,
      });
    }
  );

  const isCrypto = paymentOption === DonationFlowPaymentOption.CRYPTO;
  const cashAmount = form.watch("amount") || 0;
  const cryptoAmount = form.watch("cryptoPledgeAmount") || 0;
  const amountBig = useMemo(
    () => new Big(isCrypto ? cryptoAmount : cashAmount),
    [isCrypto, cashAmount, cryptoAmount]
  );

  const showStepper =
    isManualDaf || paymentOption === DonationFlowPaymentOption.STOCKS;
  const stepperType = isManualDaf ? StepperType.MANUAL_DAF : StepperType.STOCKS;
  const stepperActiveStep = isManualDaf ? 1 : 0;
  const stepper = useStepper(stepperType, stepperActiveStep);

  if (authState.status === AuthStatus.LOADING) {
    return <Loading />;
  }

  const creditsOnly =
    frequency === DonationFrequency.ONCE &&
    formContext.creditAmount.gt(0) &&
    formContext.amountToCharge.eq(0);

  return (
    <PageContainer>
      {formContext.skipAmountAndFrequency ? (
        <SelectDonationFlowPaymentOption
          selectedPaymentOption={paymentOption}
          paymentRequestReadyStatus={
            formContext.paymentRequestInitializer.readyStatus
          }
          paymentRequestIsApplePay={
            formContext.paymentRequestInitializer.isApplePay
          }
          paymentFlowOptions={formContext.paymentFlowOptions}
          showMorePaymentOptions={formContext.showMorePaymentOptions}
          setShowMorePaymentOptions={formContext.setShowMorePaymentOptions}
        />
      ) : (
        <GoBackButton form={form} formContext={formContext} />
      )}
      <ProcessContainer>
        <form
          css={css`
            > :not(:last-child) {
              margin-bottom: ${spacing.l};
            }
          `}
          onSubmit={submit}
        >
          {showStepper && stepper}
          <Controller
            control={control}
            name={"amountToEveryOrg"}
            render={({ field: { onChange, value } }) => (
              <AddTipAndDonationSummary
                onChange={onChange}
                value={value}
                amountBig={amountBig}
                form={form}
                formContext={formContext}
                nonprofit={nonprofit}
                paymentOption={paymentOption}
              />
            )}
          />
          {authState.status === AuthStatus.LOGGED_OUT &&
            paymentOption !== DonationFlowPaymentOption.PAYMENT_REQUEST && (
              <DonorInfo form={form} formContext={formContext} />
            )}
          {!formContext.hideShareInfo && (
            <ShareInfo
              form={form}
              formContext={formContext}
              nonprofit={nonprofit}
              fundraiser={fundraiser}
            />
          )}
          {[
            DonationFlowPaymentOption.BANK,
            DonationFlowPaymentOption.CREDIT_CARD,
          ].includes(paymentOption) && (
            <React.Fragment>
              <Controller
                control={control}
                name="paymentSource"
                render={({ field: { onChange, value } }) => (
                  <PaymentMethodPicker
                    paymentSourceType={
                      paymentOption === DonationFlowPaymentOption.BANK
                        ? PaymentSourceType.ACH_DEBIT
                        : PaymentSourceType.CARD
                    }
                    amountToCharge={formContext.amountToCharge}
                    creditAmount={formContext.creditAmount}
                    creditsOnly={creditsOnly}
                    renewablePaymentSourceRequired={
                      formContext.renewablePaymentSourceRequired
                    }
                    isInternational={formContext.isInternational}
                    isGiftCardPurchase={formContext.isGiftCardPurchase}
                    paymentSources={formContext.paymentSources}
                    setPaymentSources={formContext.setPaymentSources}
                    selectedPaymentSource={value}
                    onChange={onChange}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    clearFormError={clearFormError}
                  />
                )}
              />
              {/* TODO: figure out how to fix this error message */}
              {/* {errors.paymentSource && <p>{errors.paymentSource}</p>} */}
            </React.Fragment>
          )}
          {errors.DONATE_FORM_ERROR?.message && (
            <ErrorMessage text={errors.DONATE_FORM_ERROR.message} />
          )}
          {formContext.isDoubleDonation && (
            <ErrorMessage text={formContext.doubleDonationMessage} />
          )}
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            {showChariotButton &&
            createOrUpdateDonationResult &&
            paymentOption === DonationFlowPaymentOption.DAF &&
            !isManualDaf ? (
              <Chariot
                form={form}
                formContext={formContext}
                createOrUpdateDonationResult={createOrUpdateDonationResult}
              />
            ) : (paymentOption === DonationFlowPaymentOption.PAYPAL ||
                paymentOption === DonationFlowPaymentOption.VENMO) &&
              showPaypalButton ? (
              paypalButton
            ) : !creditsOnly &&
              paymentOption === DonationFlowPaymentOption.PAYMENT_REQUEST ? (
              <PaymentRequestButton
                donateAction={formContext.donateAction}
                form={form}
                formContext={formContext}
                nonprofit={nonprofit}
                submitDonation={submitDonation}
                handleConfirmedDonation={handleConfirmedDonation}
              />
            ) : (
              (selectedPaymentSource !== undefined ||
                creditsOnly ||
                [
                  DonationFlowPaymentOption.PAYPAL,
                  DonationFlowPaymentOption.VENMO,
                  DonationFlowPaymentOption.CRYPTO,
                  DonationFlowPaymentOption.DAF,
                  DonationFlowPaymentOption.STOCKS,
                ].includes(paymentOption)) && (
                <DonateButton
                  totalAmountBig={
                    paymentOption === DonationFlowPaymentOption.CRYPTO &&
                    formContext.totalCryptoAmount
                      ? formContext.totalCryptoAmount
                      : formContext.totalAmountBig
                  }
                  currency={
                    paymentOption === DonationFlowPaymentOption.CRYPTO &&
                    cryptoCurrency
                      ? cryptoCurrency
                      : formContext.currency
                  }
                  donateAction={formContext.donateAction}
                  paymentOption={paymentOption}
                  isManualDaf={isManualDaf}
                  submitting={form.formState.isSubmitting}
                  disabled={form.formState.isSubmitting}
                  creditsOnly={creditsOnly}
                  data-tname={`clickDonate__${paymentOption}`}
                  isDoubleDonation={formContext.isDoubleDonation}
                />
              )
            )}
          </div>
        </form>
      </ProcessContainer>
    </PageContainer>
  );
};
