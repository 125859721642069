import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { TextInput } from "@components/TextInput";
import { BackButton } from "@components/donate/DonateV3/PaymentProcess/components/BackButton";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { PrivateNoteInput } from "@components/donate/DonateV3/PaymentProcess/components/PrivateNoteInput";
import { ShareInfo } from "@components/donate/DonateV3/PaymentProcess/components/ShareInfo";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { useSyncPaymentMethod } from "@components/donate/DonateV3/PaymentProcess/useSyncPaymentMethod";
import { validateStockSymbolAndAmount } from "@components/donate/DonateV3/PaymentProcess/validators";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";

import { AuthContext } from "src/context/AuthContext";
import { AuthStatus } from "src/context/AuthContext/types";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { verticalStackCss } from "src/theme/spacing";
import { TextSize, textSizeCss } from "src/theme/text";
import { getWindow } from "src/utility/window";

export const StockSignaturePage = ({
  form,
  formContext,
  nonprofit,
  fundraiser,
}: {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse | null;
}) => {
  const stockAmount = form.watch("stockAmount");
  const stockSymbol = form.watch("stockSymbol");
  const [inputValue, setInputValue] = useState(
    (stockAmount && stockAmount.toString()) || ""
  );

  useSyncPaymentMethod({
    paymentOption: DonationFlowPaymentOption.STOCKS,
    form,
    formContext,
  });

  const authState = useContext(AuthContext);

  const navigate = useNavigate();

  const stockSymbolErrorMessage = form.formState.errors["stockSymbol"]?.message;
  const stockAmountErrorMessage = form.formState.errors["stockAmount"]?.message;

  const onAmountChanged = (val: string) => {
    if (val !== "") {
      if (!/^\d*\.?\d*$/.test(val)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const window = getWindow();
    if (window && formContext.skipStockAmountAndSymbol) {
      navigate(
        paymentProcessRouteNameToPathMap[
          PaymentProcessRouteName.STOCKS_SIGNATURE_BROKER
        ],
        { replace: true }
      );
    }
  }, [formContext.skipStockAmountAndSymbol, navigate]);

  // TODO #15621: enable pull stock flow for everybody (remove this navigate)
  useEffect(() => {
    const window = getWindow();
    if (window && !nonprofit.metadata?.showStockPull) {
      navigate(
        paymentProcessRouteNameToPathMap[PaymentProcessRouteName.STOCKS_MANUAL],
        { replace: true }
      );
    }
  }, [navigate, nonprofit]);

  return (
    <PageContainer>
      <BackButton
        backAction={() => {
          navigate(-1);
        }}
        paymentOption={DonationFlowPaymentOption.STOCKS}
      />
      <ProcessContainer>
        <form css={verticalStackCss.l}>
          <div css={verticalStackCss.xxs}>
            <h5
              css={css`
                ${textSizeCss[TextSize.s]};
              `}
            >
              What is the symbol of the shares?
            </h5>
            <p
              css={css`
                ${textSizeCss[TextSize.xs]};
              `}
            >
              Example: AAPL
            </p>
            <div css={verticalStackCss.s}>
              <Controller
                control={form.control}
                name="stockSymbol"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    name="stockSymbol"
                    id="stockSymbol"
                    data-tname="stockSymbol"
                    onChange={onChange}
                    value={value}
                    validationStatus={{
                      success: !stockSymbolErrorMessage,
                      message: stockSymbolErrorMessage,
                    }}
                    collapseDescriptionSpace
                  />
                )}
              />
            </div>
          </div>
          <div css={verticalStackCss.xxs}>
            <h5
              css={css`
                ${textSizeCss[TextSize.s]};
              `}
            >
              How many shares are you donating?
            </h5>
            <div css={verticalStackCss.s}>
              <Controller
                control={form.control}
                key="stockAmount"
                name="stockAmount"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    name="stockAmount"
                    id="stockAmount"
                    data-tname="stockAmount"
                    onChange={(e) => {
                      if (onAmountChanged(e.target.value)) {
                        setInputValue(e.target.value);
                        if (e.target.value === ".") {
                          setInputValue("0.");
                        }
                        onChange(parseFloat(e.target.value));
                      }
                    }}
                    value={inputValue || ""}
                    validationStatus={{
                      success: !stockAmountErrorMessage,
                      message: stockAmountErrorMessage,
                    }}
                    collapseDescriptionSpace
                  />
                )}
              />
            </div>
          </div>
          <div css={verticalStackCss.m}>
            {!formContext.hideShareInfo &&
              authState.status === AuthStatus.LOGGED_IN && (
                <ShareInfo
                  form={form}
                  formContext={formContext}
                  nonprofit={nonprofit}
                  fundraiser={fundraiser}
                />
              )}
            <PrivateNoteInput
              nonprofit={nonprofit}
              form={form}
              fundraiser={fundraiser}
            />
          </div>
          <div css={verticalStackCss.l}>
            <Button
              role={ButtonRole.PRIMARY}
              onClick={{
                kind: ButtonTargetKind.FUNCTION,
                action: () => {
                  const valid = validateStockSymbolAndAmount({
                    stockSymbol,
                    stockAmount,
                    setError: form.setError,
                  });
                  if (!valid) {
                    return;
                  }
                  navigate(
                    paymentProcessRouteNameToPathMap[
                      PaymentProcessRouteName.STOCKS_SIGNATURE_DONOR_INFO
                    ]
                  );
                },
              }}
              data-tname="donateStockSignatureFlowStartNext"
            >
              Next
            </Button>
          </div>
        </form>
      </ProcessContainer>
    </PageContainer>
  );
};
