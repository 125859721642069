import { AvatarSize } from "@components/Avatar";
import { NonprofitAvatar } from "@components/Avatar/NonprofitAvatar";
import { FundraiserCard } from "@components/NewFundraiserCard";
import {
  Chip,
  ExternalCard,
  HeaderChip,
} from "@components/donate/DonateV3/NonprofitInfo/styles";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { NonprofitCard } from "@components/feed/NewNonprofitCard";
import {
  FundraiserTitleSection,
  TitleSection,
} from "@components/feed/NewNonprofitCard/TitleSection";
import { nameCss } from "@components/feed/NewNonprofitCard/TitleSection/styles";
import { css } from "@emotion/react";
import React, { useMemo } from "react";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss } from "src/theme/spacing";
import { textSizeCss, FontWeight } from "src/theme/text";

interface NonprofitInfoProps {
  nonprofit: ContextNonprofit;
  customNonprofitDescription?: string;
  fundraiser?: FundraiserResponse;
  formContext: DonateFormContext;
}

export const NonprofitInfo = (props: NonprofitInfoProps) => {
  return (
    <React.Fragment>
      <div
        css={css`
          display: block;
          ${cssForMediaSize({
            min: MediaSize.LARGE,
            css: { display: "none" },
          })}
        `}
      >
        <NonprofitInfoMediumScreen {...props} />
      </div>
      <div
        css={css`
          display: none;
          ${cssForMediaSize({
            min: MediaSize.LARGE,
            css: { display: "block" },
          })}
        `}
      >
        <NonprofitInfoLargeScreen {...props} />
      </div>
    </React.Fragment>
  );
};

const NonprofitInfoLargeScreen = ({
  nonprofit: initialNonprofit,
  customNonprofitDescription,
  fundraiser,
  formContext,
}: NonprofitInfoProps) => {
  const nonprofit = useMemo((): ContextNonprofit => {
    return {
      ...initialNonprofit,
      description: customNonprofitDescription ?? initialNonprofit.description,
    };
  }, [initialNonprofit, customNonprofitDescription]);

  if (formContext.isExternal) {
    return (
      <ExternalCard>
        <div css={horizontalStackCss.s}>
          <NonprofitAvatar nonprofit={nonprofit} size={AvatarSize.MEDIUM} />
          <h5 css={textSizeCss.s}>
            {nonprofit.name}{" "}
            <span css={{ fontWeight: FontWeight.REGULAR }}>
              is using Every.org to accept this donation
            </span>
          </h5>
        </div>
        <p>Every.org is a nonprofit dedicated to philanthropy for everyone.</p>
        {customNonprofitDescription && <p>{customNonprofitDescription}</p>}
      </ExternalCard>
    );
  }

  if (fundraiser) {
    return (
      <FundraiserCard
        nonprofit={nonprofit}
        fundraiser={fundraiser}
        showActions={false}
        showHeader
        clickable={false}
      />
    );
  }

  return (
    <NonprofitCard
      nonprofit={nonprofit}
      showButtons={false}
      causeCategoryBackgroundColor
      clickable={false}
    />
  );
};

const NonprofitInfoMediumScreen = ({
  nonprofit,
  fundraiser,
  formContext,
}: NonprofitInfoProps) => {
  return (
    <Chip>
      <NonprofitAvatar nonprofit={nonprofit} size={AvatarSize.SMALL} />
      {formContext.isExternal ? (
        <h5 css={textSizeCss.s}>
          {nonprofit.hasAdmin
            ? `${nonprofit.name} is using Every.org to accept this donation`
            : `Every.org created this page for ${nonprofit.name}`}
        </h5>
      ) : fundraiser ? (
        <FundraiserTitleSection nonprofit={nonprofit} fundraiser={fundraiser} />
      ) : (
        <TitleSection nonprofit={nonprofit} />
      )}
    </Chip>
  );
};

export const NonprofitInfoHeaderSmallScreen = ({
  nonprofit,
  fundraiser,
  formContext,
}: NonprofitInfoProps) => {
  return (
    <HeaderChip>
      <NonprofitAvatar nonprofit={nonprofit} size={AvatarSize.SMALL} />
      <h3 css={nameCss}>
        {fundraiser ? fundraiser?.title || nonprofit.name : nonprofit.name}
      </h3>
    </HeaderChip>
  );
};
