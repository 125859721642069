/**
 * Extending builtins like Error leads to unexpected behavior like `instanceof`
 * not working as expected, because the language-level implementation of those
 * builtins overwrites the prototype chain when you call `super`. Call this
 * function to reset the prototype chain to the correct value and get behavior
 * you expect.
 * @param actualPrototype The true prototype of the new class, accessible by
 * `new.target.prototype` in the constructor
 * @param classToPatch the class that needs to get patched, accessible by `this`
 * in the constructor
 */
export function fixPrototypeChain(
  // eslint-disable-next-line @typescript-eslint/ban-types
  actualPrototype: object,
  // __proto__ is actually deprecated and this is compatibility code
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  classToPatch: any
) {
  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(classToPatch, actualPrototype);
  } else {
    classToPatch.__proto__ = actualPrototype;
  }
}
