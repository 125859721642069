import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import {
  TextInput,
  TextInputType,
  TextInputProps,
} from "@components/TextInput";
import { outsideBoundsErrorMessage } from "@components/donate/helpers";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Big } from "big.js";
import { useState, useEffect, useCallback, useRef } from "react";
import { useDebounce } from "use-debounce";

import { CurrencyValue } from "@every.org/common/src/codecs/currency";
import { spacing } from "@every.org/common/src/display/spacing";
import { Currency, PaymentMethod } from "@every.org/common/src/entity/types";
import {
  DonationValueErrorCode,
  donationValueIsInvalid,
} from "@every.org/common/src/helpers/donationValue";
import { abbreviateNumber } from "@every.org/common/src/helpers/number";

import { ErrorMessage } from "src/components/donate/DonateV3/PaymentProcess/pages/Donate";
import { colorCssVars } from "src/theme/color";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { textSizeCss, FontWeight } from "src/theme/text";
import { trackEvent } from "src/utility/analytics";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

// TODO #8478: parametrize currency
export function validateNumber({
  amount,
  setErrorMessage,
  setAmount,
  shorten,
  minValue,
  maxValue,
}: {
  amount: string;
  minValue: CurrencyValue;
  maxValue: CurrencyValue;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setAmount?: React.Dispatch<React.SetStateAction<string>>;
  shorten: boolean;
}): boolean {
  let amountBig: Big;
  try {
    amountBig = new Big(amount);
  } catch (e) {
    setErrorMessage &&
      setErrorMessage(
        shorten
          ? "Please enter an amount"
          : "Please enter an amount to continue"
      );
    return false;
  }

  // TODO #8478: consider the selected currency
  const valueError = donationValueIsInvalid({
    value: { currency: Currency.USD, amount: amountBig },
    minValue,
    maxValue,
  });
  if (!valueError) {
    setAmount && setAmount(amount);
    setErrorMessage && setErrorMessage(undefined);
  } else {
    setErrorMessage &&
      setErrorMessage(
        outsideBoundsErrorMessage({
          // TODO #8478: consider the selected currency
          currency: Currency.USD,
          maxOrMin: valueError,
          shorten,
          minValue,
          maxValue,
        })
      );
    return false;
  }

  if (amount.length && !isNaN(Number(amount))) {
    setErrorMessage && setErrorMessage(undefined);
    return true;
  }
  return false;
}

const AmountPickerWrapper = styled.div`
  ${horizontalStackCss.xs};
  margin: 0 auto;
  button {
    border: 1.5px solid #cccccc;
    padding: ${spacing.xs} ${spacing.l};
  }
  ${cssForMediaSize({
    min: MediaSize.XX_LARGE,
    css: css`
      button {
        border: 1.5px solid #cccccc;
        padding: ${spacing.xs} ${spacing.m};
      }
    `,
  })};
  ${cssForMediaSize({
    max: MediaSize.X_LARGE,
    css: css`
      ${horizontalStackCss.m};
      button {
        padding: 0;
        border: none;
      }
    `,
  })};
`;

export const truncateDecimalPoint = (string: string) => {
  return string.slice(-1) === "." ? string.slice(0, -1) : string;
};

type AmountTextInputProps = {
  shorten: boolean;
  minValue?: CurrencyValue;
  maxValue?: CurrencyValue;
  descriptionElement?: JSX.Element;

  amount?: string;
  setAmount: (amount: string) => void;

  amountErrorMessage?: string;
  setAmountErrorMessage?: (errorMessage: string | undefined) => void;

  collapseDescriptionSpace?: boolean;

  allowZero?: boolean;
  allowDecimal?: boolean;
} & Pick<
  TextInputProps,
  "id" | "name" | "labelText" | "data-tname" | "autoFocus" | "onBlur"
>;

// TODO #8478: parametrize currency
export const AmountTextInput = ({
  shorten,
  minValue,
  maxValue,
  descriptionElement,
  amount,
  setAmount,
  amountErrorMessage,
  setAmountErrorMessage,
  autoFocus,
  collapseDescriptionSpace,
  allowZero = false,
  allowDecimal = false,
  ...rest
}: AmountTextInputProps) => {
  const isValueLimits = maxValue && minValue;
  const amountInputRef = useRef<HTMLInputElement>(null);
  const [amountWarning, setAmountWarning] = useState(false);
  const window = getWindow();
  const isSafariOrFirefox =
    window &&
    /safari|firefox/i.test(window.navigator.userAgent) &&
    !/chrome/i.test(window.navigator.userAgent) &&
    !/android/i.test(window.navigator.userAgent);

  const isAndroid = window && /android/i.test(window.navigator.userAgent);

  // Really we should only debounce the minimum amount error message.
  const [debouncedAmountErrorMessage] = useDebounce(amountErrorMessage, 200);

  useEffect(() => {
    if (!amountWarning) {
      return;
    }
    const timer = setTimeout(() => {
      setAmountWarning(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [amountWarning]);

  // Return true iff amount value is valid
  const onAmountChanged = useCallback(
    (strValue: string) => {
      if (strValue === "") {
        setAmount("");
        return false;
      }

      if (allowDecimal) {
        if (strValue === ".") {
          strValue = "0.";
        } else if (!/^[0-9]+\.?[0-9]{0,2}$/g.test(strValue)) {
          setAmountWarning(true);
          return false;
        }
      } else {
        const usedDecimal = strValue.includes(".");
        if (usedDecimal || !/^[0-9]+$/g.test(strValue)) {
          setAmountWarning(true);
          if (
            usedDecimal &&
            amount &&
            !amountErrorMessage &&
            amountInputRef.current
          ) {
            // If user is trying to quickly type something like 10.00,
            // in addition to showing error message unfocus the input
            // so they can't continue quickly typing if they aren't looking
            amountInputRef.current.blur();
          }
          return false;
        }
      }

      setAmountWarning(false);
      const bigValue = new Big(truncateDecimalPoint(strValue));
      if (
        (!(allowZero || allowDecimal) && bigValue.lte(0)) ||
        // disallow something like "01"
        (allowZero &&
          !allowDecimal &&
          strValue.length === 2 &&
          strValue[0] === "0")
      ) {
        return false;
      }

      // TODO #8478: parametrize currency
      const donationValue = {
        currency: Currency.USD,
        amount: bigValue,
      };
      const valueError =
        isValueLimits &&
        donationValueIsInvalid({
          value: donationValue,
          minValue,
          maxValue,
        });
      if (setAmountErrorMessage && isValueLimits) {
        switch (valueError) {
          case DonationValueErrorCode.CURRENCY_MISMATCH:
            // this shouldn't ever be seen because we don't allow currency
            // customization
            logger.error({
              data: { donationValue, minValue, maxValue },
              message: "Currency mismatch during donation.",
            });
            // TODO this should be a field error, not a state thingey
            setAmountErrorMessage(
              "There was a currency mismatch. Please refresh and try again."
            );
            break;
          case DonationValueErrorCode.TOO_LARGE:
            setAmountErrorMessage(
              outsideBoundsErrorMessage({
                // TODO #8478: parametrize currency
                currency: Currency.USD,
                maxOrMin: DonationValueErrorCode.TOO_LARGE,
                shorten,
                minValue,
                maxValue,
              })
            );
            break;
          case DonationValueErrorCode.TOO_SMALL:
            setAmountErrorMessage(
              outsideBoundsErrorMessage({
                // TODO #8478: parametrize currency
                currency: Currency.USD,
                maxOrMin: DonationValueErrorCode.TOO_SMALL,
                shorten,
                minValue,
                maxValue,
              })
            );
            break;
          case undefined:
            setAmountErrorMessage(undefined);
            break;
          default: {
            const errorCode: never = valueError;
            logger.warn({
              message: "Unhandled value error type",
              data: { errorCode, donationValue },
            });
          }
        }
      }
      setAmount(strValue);
      trackEvent("Donation amount changed", {});
      return valueError === undefined;
    },
    [
      allowDecimal,
      allowZero,
      amount,
      amountErrorMessage,
      maxValue,
      minValue,
      setAmount,
      setAmountErrorMessage,
      shorten,
      isValueLimits,
    ]
  );

  return (
    <TextInput
      css={css`
        white-space: pre;
      `}
      {...rest}
      type={TextInputType.TEXT}
      inputMode="numeric"
      ref={amountInputRef}
      value={amount || ""}
      onChange={(e) => onAmountChanged(e.target.value)}
      inputBoxCss={css`
        & > input {
          ${textSizeCss.l}
          font-weight: ${FontWeight.BOLD};
          position: relative;
          &::placeholder {
            ${textSizeCss.m};
            font-weight: ${FontWeight.REGULAR};

            // align center
            position: absolute;

            ${isSafariOrFirefox
              ? css`
                  line-height: 2;
                  top: -0.2em;
                `
              : isAndroid
              ? css`
                  transform: translateY(-10%);
                `
              : css`
                  top: 50%;
                  transform: translateY(-50%);
                `}
          }
        }
      `}
      validationStatus={
        debouncedAmountErrorMessage || amountWarning
          ? { success: false }
          : undefined
      }
      inputPrefix={
        <span
          css={css`
            ${textSizeCss.l}
            color: var(${amount
              ? colorCssVars.text.body
              : colorCssVars.text.secondary});
            font-weight: ${FontWeight.BOLD};
          `}
        >
          {"$ "}
        </span>
      }
      inputSuffix={
        <span
          css={css`
            ${textSizeCss.m}
            color: var(${colorCssVars.accent.large});
            font-weight: ${FontWeight.BOLD};
          `}
        >
          {" USD"}
        </span>
      }
      collapseDescriptionSpace={
        !debouncedAmountErrorMessage && collapseDescriptionSpace
      }
      description={
        (descriptionElement || debouncedAmountErrorMessage) && (
          <div css={verticalStackCss.xs}>
            {debouncedAmountErrorMessage && (
              <ErrorMessage>{debouncedAmountErrorMessage}</ErrorMessage>
            )}
            {descriptionElement}
          </div>
        )
      }
      // it's self-explanatory that when you click donate you need to provide an
      // amount, so autofocus shouldn't be jarring.
      // autoFocus from props has highest priority
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus ?? !shorten}
    />
  );
};

export const AdditiveAmountsPicker = ({
  amounts,
  addAmount,
  setAmountErrorMessage,
  amountErrorMessage,
}: {
  amounts: number[];
  addAmount: (amount: number) => void;
  setAmountErrorMessage: (message: string | undefined) => void;
  amountErrorMessage: string | undefined;
}) => {
  return (
    <AmountPickerWrapper>
      {amounts.map((quickAmount, index) => (
        <Button
          key={index}
          role={ButtonRole.TEXT_ONLY}
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => {
              addAmount(quickAmount);
              amountErrorMessage && setAmountErrorMessage(undefined);
            },
          }}
          data-tname={`DonateAmountShortcut${index}`}
        >
          {`+${
            Number.isInteger(quickAmount)
              ? abbreviateNumber(quickAmount)
              : quickAmount.toFixed(2)
          }`}
        </Button>
      ))}
    </AmountPickerWrapper>
  );
};

// TODO #8478: parametrize currency
export const AvailableCreditDescription = ({
  availableGivingCredit,
  setAmount,
  paymentMethod,
}: {
  availableGivingCredit: Big | undefined;
  setAmount: (amount: string) => void;
  paymentMethod: PaymentMethod;
}) => {
  if (!availableGivingCredit || availableGivingCredit.lte(0)) {
    return null;
  }
  if (
    [
      PaymentMethod.PAYPAL,
      PaymentMethod.DAF,
      PaymentMethod.CRYPTO,
      PaymentMethod.STOCKS,
      PaymentMethod.MUTUAL_FUND,
    ].includes(paymentMethod)
  ) {
    return null;
  }

  const creditString = displayCurrencyValueInUserLocale({
    currencyValue: {
      amount: availableGivingCredit,
      // TODO #8478: parametrize currency
      currency: Currency.USD,
    },
  });

  return (
    <span css={{ color: `var(${colorCssVars.text.secondary})` }}>
      You have{" "}
      <Button
        data-tname={"applyGiftAmount_Button"}
        role={ButtonRole.TEXT_ONLY}
        onClick={{
          kind: ButtonTargetKind.FUNCTION,
          action: () => setAmount(availableGivingCredit.toString()),
        }}
      >
        {creditString}
      </Button>{" "}
      in donation credits available
    </span>
  );
};
