import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { css } from "@emotion/react";
import React from "react";

import { spacing } from "@every.org/common/src/display/spacing";
import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";

import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { horizontalStackCss } from "src/theme/spacing";

export const BackButton = ({
  backAction,
  paymentOption,
}: {
  backAction: () => void;
  paymentOption: DonationFlowPaymentOption;
}) => {
  return (
    <div
      css={css`
        padding: 20px;
        ${cssForMediaSize({
          max: MediaSize.MEDIUM_LARGE,
          css: css`
            padding: 0;
            margin-bottom: ${spacing.l};
          `,
        })};
      `}
    >
      <Button
        role={ButtonRole.TEXT_ONLY}
        onClick={{ kind: ButtonTargetKind.FUNCTION, action: backAction }}
        data-tname={`donateClickBack__${paymentOption}`}
        contentCss={horizontalStackCss.xxs}
      >
        <Icon
          iconImport={() => import("@components/Icon/icons/ArrowBackIcon")}
          size={IconSize.MEDIUM}
          display={IconDisplay.ACCENT}
        />
        <span>Back</span>
      </Button>
    </div>
  );
};
