import { useContext, useEffect, useState } from "react";
import { StatsigSynchronousProvider, StatsigUser } from "statsig-react";

import { ABTestingId } from "@every.org/common/src/codecs/entities";
import { STATSIG_PROXY_URL } from "@every.org/common/src/entity/constants";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import {
  AbTestActionType,
  dispatchAbTestAction,
} from "src/context/AbTestContext";
import { AuthContext } from "src/context/AuthContext";
import { getTestingId } from "src/utility/abtesting";

type StatsigProviderProps = {
  children: React.ReactNode;
  statsigInitProps?: Record<string, unknown>;
  abTestingId?: string;
};
export const StatsigProvider = ({
  children,
  statsigInitProps = {},
  abTestingId = undefined,
}: StatsigProviderProps) => {
  const authState = useContext(AuthContext);
  const testingId = getTestingId({
    authState,
    ssrTestingId: abTestingId,
  }) as ABTestingId;

  const [user, setUser] = useState({} as StatsigUser);

  useEffect(() => {
    if (user?.customIDs?.nonprofitId) {
      dispatchAbTestAction({
        type: AbTestActionType.SET_NONPROFIT_ID,
        nonprofitId: user.customIDs.nonprofitId,
      });
    }
  }, [user?.customIDs?.nonprofitId]);

  return (
    <StatsigSynchronousProvider
      sdkKey={assertEnvPresent(
        process.env.NEXT_PUBLIC_STATSIG_PUBLIC_API_KEY,
        "STATSIG_API_KEY"
      )}
      user={{ userID: testingId, ...user }}
      initializeValues={statsigInitProps}
      options={{
        localMode: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === "development",
        environment: {
          tier: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME,
        },
        initTimeoutMs: 5000,
        overrideStableID: abTestingId || testingId,
        api: STATSIG_PROXY_URL,
      }}
      setUser={setUser}
    >
      {children}
    </StatsigSynchronousProvider>
  );
};
