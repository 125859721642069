import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import {
  PaymentProcessCoreProps,
  DonateFormType,
  DONATE_FORM_ERROR,
  DonateModalAction,
} from "@components/donate/DonateV3/types";
import { DonateFormSearchValues } from "@components/donate/DonateV3/useDonateFormSearchParams";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { FUND_ACCOUNT_NONPROFIT_ID } from "@every.org/common/src/entity/constants";
import { Currency, PaymentMethod } from "@every.org/common/src/entity/types";
import { DONATE_HASH } from "@every.org/common/src/helpers/clientRoutes";

import { useLoggedInOrGuestUserOrUndefined } from "src/context/AuthContext/hooks";
import { isLoggedInUser } from "src/context/AuthContext/selectors";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

const DEFAULT_PAYMENT_METHOD = PaymentMethod.STRIPE;

const getPaymentMethodFromRoute = (paymentRoute: string) => {
  paymentRoute = paymentRoute.replace(/^\//, "");
  if (
    paymentRoute.startsWith(
      paymentProcessRouteNameToPathMap[PaymentProcessRouteName.CREDIT_CARD]
    ) ||
    paymentRoute.startsWith(
      paymentProcessRouteNameToPathMap[PaymentProcessRouteName.APPLE_PAY]
    ) ||
    paymentRoute.startsWith(
      paymentProcessRouteNameToPathMap[PaymentProcessRouteName.BANK]
    )
  ) {
    return PaymentMethod.STRIPE;
  }
  if (
    paymentRoute.startsWith(
      paymentProcessRouteNameToPathMap[PaymentProcessRouteName.PAYPAL]
    )
  ) {
    return PaymentMethod.PAYPAL;
  }
  if (
    paymentRoute.startsWith(
      paymentProcessRouteNameToPathMap[PaymentProcessRouteName.VENMO]
    )
  ) {
    return PaymentMethod.PAYPAL;
  }
  if (
    paymentRoute.startsWith(
      paymentProcessRouteNameToPathMap[PaymentProcessRouteName.CRYPTO]
    )
  ) {
    return PaymentMethod.CRYPTO;
  }
  if (
    paymentRoute.startsWith(
      paymentProcessRouteNameToPathMap[PaymentProcessRouteName.STOCKS]
    )
  ) {
    return PaymentMethod.STOCKS;
  }
  if (
    paymentRoute.startsWith(
      paymentProcessRouteNameToPathMap[PaymentProcessRouteName.DAF]
    )
  ) {
    return PaymentMethod.DAF;
  }
  if (paymentRoute.endsWith(DONATE_HASH)) {
    logger.error({
      message: "Unexpected payment type from route",
      data: { paymentRoute, hasWindow: !!getWindow() },
    });
  }
  return DEFAULT_PAYMENT_METHOD;
};

export const useDonateForm = ({
  nonprofit,
  minDonationValue,
  donateAction,
  defaultAmount,
  defaultTipAmount,
  defaultFrequency,
  frequencyOptions: allowedFrequencies,
  defaultShareInfo,
  initialValuesFromSearchParams,
}: PaymentProcessCoreProps & {
  initialValuesFromSearchParams: DonateFormSearchValues;
}) => {
  const loggedInOrGuestUser = useLoggedInOrGuestUserOrUndefined();

  const initialCurrency = minDonationValue?.currency || Currency.USD;

  const requireShareInfo =
    initialValuesFromSearchParams.requireShareInfo ||
    nonprofit.metadata?.requireDonorInfo;
  const initialShareInfo = !!(
    requireShareInfo ||
    defaultShareInfo ||
    initialValuesFromSearchParams.shareInfo ||
    !isLoggedInUser(loggedInOrGuestUser) ||
    loggedInOrGuestUser.lastDonationShareInfo ||
    nonprofit.id === FUND_ACCOUNT_NONPROFIT_ID
  );

  const location = useLocation();
  const paymentRoute = location.pathname;
  const paymentMethod = getPaymentMethodFromRoute(paymentRoute);

  const form = useForm<DonateFormType>({
    defaultValues: {
      paymentMethod,
      amount:
        donateAction === DonateModalAction.UPDATE
          ? defaultAmount?.toNumber()
          : undefined,
      amountToEveryOrg:
        donateAction === DonateModalAction.UPDATE
          ? defaultTipAmount?.toString()
          : undefined,
      currency: initialCurrency,
      frequency:
        (donateAction === DonateModalAction.UPDATE && defaultFrequency) ||
        initialValuesFromSearchParams.frequency,
      paymentSource: undefined,
      shareInfo: initialShareInfo,
      optInToMailingList: nonprofit.metadata?.optInCheckbox ? false : undefined,
      partnerDonationId: undefined, // initialized in search params
      partnerWebhookToken: undefined, // initialized in search params
      partnerMetadata: undefined, // initialized in search params
      firstName:
        loggedInOrGuestUser?.firstName ||
        initialValuesFromSearchParams.firstName ||
        undefined,
      lastName:
        loggedInOrGuestUser?.lastName ||
        initialValuesFromSearchParams.lastName ||
        undefined,
      email:
        loggedInOrGuestUser?.email ||
        initialValuesFromSearchParams.email ||
        undefined,

      payerEmail: undefined,
      payerName: undefined,
      paymentRequestPaymentMethodId: undefined,

      privateNote: initialValuesFromSearchParams.privateNote,
      toNonprofits: undefined, // initialized in search params
      toNonprofitWeights: undefined, // initialized in search params
      toNonprofitMatchings: undefined, // initialized in search params
      recurringMatches: undefined, // initialized in search params

      cryptoCurrency: initialValuesFromSearchParams.cryptoCurrency || undefined,
      cryptoPledgeAmount:
        initialValuesFromSearchParams.cryptoAmount || undefined,

      stockSymbol: initialValuesFromSearchParams.stockSymbol || undefined,
      stockAmount: initialValuesFromSearchParams.stockAmount || undefined,

      designation: initialValuesFromSearchParams.designation || undefined,

      // Overwrite the initial blank default values with anything specified in
      // search params
      ...initialValuesFromSearchParams,
    },
    criteriaMode: "all",
  });

  useEffect(() => {
    if (loggedInOrGuestUser) {
      const { firstName, lastName, email } = loggedInOrGuestUser;
      if (firstName && firstName !== form.getValues("firstName")) {
        form.setValue("firstName", firstName);
      }
      if (lastName && lastName !== form.getValues("lastName")) {
        form.setValue("lastName", lastName);
      }
      if (email && email !== form.getValues("email")) {
        form.setValue("email", email);
      }
    }
  }, [loggedInOrGuestUser, form]);

  const {
    amount,
    frequency,
    amountToEveryOrg,
    cryptoCurrency,
    cryptoPledgeAmount,
  } = form.watch();

  useEffect(() => {
    form.clearErrors(DONATE_FORM_ERROR);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, frequency, amountToEveryOrg, cryptoCurrency, cryptoPledgeAmount]);

  useEffect(() => {
    form.setValue("isDoubleDonation", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, frequency]);

  return form;
};
