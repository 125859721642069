import React, { useReducer, createContext } from "react";

import { TagResponse } from "@every.org/common/src/codecs/entities";

import { tagOrUndefined, getTag } from "src/context/TagContext/selectors";
import {
  TagsState,
  TagsAction,
  TrendingTagsFetchStatus,
  TagFetchStatus,
  TagActionType,
  TagIdentifier,
  InternalTagsState,
} from "src/context/TagContext/types";

const INITIAL_TAG_STATE: Omit<TagsState, "dispatchTagsAction"> = {
  tagsByTagName: new Map(),
  tagsById: new Map(),
  tagFetchStatus: new Map(),
  trendingTags: [],
  trendingTagsFetchStatus: undefined,
  trendingTagsHasMore: true,
};

function updateStateWithTrending(
  state: InternalTagsState,
  status: TrendingTagsFetchStatus,
  trendingTags?: { tags: TagResponse[]; hasMore: boolean } | undefined
): InternalTagsState {
  if (trendingTags !== undefined) {
    return {
      ...state,
      trendingTagsFetchStatus: status,
      trendingTags: trendingTags.tags,
      trendingTagsHasMore: trendingTags.hasMore,
    };
  }
  return { ...state, trendingTagsFetchStatus: status };
}

function updateStateWithArrayOfTags(
  state: InternalTagsState,
  tags: TagResponse[]
): InternalTagsState {
  const incomingTagsByTagName = new Map(tags.map((tag) => [tag.tagName, tag]));
  const incomingTagsById = new Map(tags.map((tag) => [tag.id, tag]));
  const tagsByTagName = new Map([
    ...state.tagsByTagName,
    ...incomingTagsByTagName,
  ]);
  const tagsById = new Map([...state.tagsById, ...incomingTagsById]);

  const incomingTagsFetchStatus = new Map();

  tags.forEach(({ id, tagName }) => {
    incomingTagsFetchStatus.set(id, TagFetchStatus.FOUND);
    incomingTagsFetchStatus.set(tagName, TagFetchStatus.FOUND);
  });

  const tagFetchStatus = new Map([
    ...state.tagFetchStatus,
    ...incomingTagsFetchStatus,
  ]);

  return { ...state, tagsByTagName, tagsById, tagFetchStatus };
}

/**
 * Update fetched status of a tag currently being fetched.
 */
function updateStateWithStatus(
  state: InternalTagsState,
  identifier: TagIdentifier,
  status: TagFetchStatus
): InternalTagsState {
  const { tagFetchStatus: prevTagFetchStatus, ...rest } = state;
  const tag = tagOrUndefined(getTag(state, identifier));
  if (tag) {
    return state;
  }
  const idOrName = "id" in identifier ? identifier.id : identifier.tagName;
  const tagFetchStatus = new Map(prevTagFetchStatus);
  tagFetchStatus.set(idOrName, status);
  return {
    ...rest,
    tagFetchStatus,
  };
}

/**
 * Adds a tag to the store of tags, when one has been successfully
 * fetched.
 */
function updateStateWithTag(params: {
  state: InternalTagsState;
  tag: TagResponse;
}): InternalTagsState {
  const { state, tag } = params;
  const tagsByTagName = new Map(state.tagsByTagName).set(tag.tagName, tag);
  const tagsById = new Map(state.tagsById).set(tag.id, tag);

  const tagFetchStatus = new Map(state.tagFetchStatus);
  tagFetchStatus.set(tag.tagName, TagFetchStatus.FOUND);
  tagFetchStatus.set(tag.id, TagFetchStatus.FOUND);
  const trendingTags = state.trendingTags;
  const trendingTagsFetchStatus = state.trendingTagsFetchStatus;
  const trendingTagsHasMore = state.trendingTagsHasMore;
  return {
    tagsByTagName,
    tagsById,
    tagFetchStatus,
    trendingTags,
    trendingTagsFetchStatus,
    trendingTagsHasMore,
  };
}

function tagsReducer(
  state: InternalTagsState,
  action: TagsAction
): InternalTagsState {
  switch (action.type) {
    case TagActionType.FETCHING_TAG:
      return updateStateWithStatus(
        state,
        action.data,
        TagFetchStatus.FETCHING_TAG
      );
    case TagActionType.TAG_NOT_FOUND:
      return updateStateWithStatus(
        state,
        action.data,
        TagFetchStatus.TAG_NOT_FOUND
      );
    case TagActionType.ADD_TAG:
      return updateStateWithTag({ state: state, tag: action.data });
    case TagActionType.ADD_TAGS:
      return updateStateWithArrayOfTags(state, action.data);
    case TagActionType.ADD_TRENDING_TAGS:
      return updateStateWithTrending(
        action.data.tags.reduce(
          (curState, curTag) =>
            updateStateWithTag({
              state: curState,
              tag: curTag,
            }),
          state
        ),
        TrendingTagsFetchStatus.FOUND,
        action.data
      );
    case TagActionType.FETCHING_TRENDING_TAGS:
      return updateStateWithTrending(
        state,
        TrendingTagsFetchStatus.FETCHING_TRENDING_TAGS
      );
    case TagActionType.TRENDING_TAGS_NOT_FOUND:
      return updateStateWithTrending(
        state,
        TrendingTagsFetchStatus.TRENDING_TAGS_NOT_FOUND
      );
    default:
      throw new Error(`Tag action with unknown type: ${action}`);
  }
}

export let dispatchTagsAction: React.Dispatch<TagsAction>;

export const TagsContext = createContext<TagsState>(
  // technically this is unsound since `dispatchTagsAction` will not be present,
  // but we always provide it in the provider below so for ergonomics we take
  // this risk
  INITIAL_TAG_STATE as TagsState
);

export const TagsProvider: React.FCC<{ initialData?: TagResponse[] }> = ({
  children,
  initialData,
}) => {
  const [tagsState, tagsDispatcher] = useReducer(
    tagsReducer,
    initialData
      ? updateStateWithArrayOfTags(INITIAL_TAG_STATE, initialData)
      : INITIAL_TAG_STATE
  );

  dispatchTagsAction = tagsDispatcher;
  return (
    <TagsContext.Provider
      value={{ ...tagsState, dispatchTagsAction: tagsDispatcher }}
    >
      {children}
    </TagsContext.Provider>
  );
};
