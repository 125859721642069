import { fixPrototypeChain } from "../helpers/extendBuiltins";

/**
 * A base class for any known error that occurs in our application.
 */
export abstract class ApplicationError<ErrorData = unknown> extends Error {
  public declare readonly data: ErrorData;

  public constructor(message: string, data: ErrorData) {
    super(message);
    this.data = data;

    // When extending Error (or any of its subclasses), need to explicitly set
    // prototype to get proper behavior of methods like `instanceof`
    fixPrototypeChain(new.target.prototype, this);
  }
}
