import { AvatarSize } from "@components/Avatar";
import { HeaderCard } from "@components/Card";
import {
  HeaderCardTitle,
  HeaderCardTitleProps,
} from "@components/PageHeaderCard/HeaderCardTitle";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing, horizontalStackCss } from "src/theme/spacing";

export const headerMetaWrapperStyles = [
  cssForMediaSize({
    max: MediaSize.MEDIUM,
    css: css`
      ${horizontalStackCss.m}
      justify-content: space-between;
      align-items: center;
    `,
  }),
  cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      ${horizontalStackCss.xl};
      height: 100%;
      align-items: center;
    `,
  }),
  cssForMediaSize({
    max: MediaSize.SMALL,
    css: css`
      ${horizontalStackCss.s}
      justify-content: space-between;
      align-items: center;
    `,
  }),
];

type HeaderCardProps = HeaderCardTitleProps & {
  /**
   * Metadata displayed alongside the header title on large screens, and on the
   * bottom on smaller screens.
   */
  metadata?: React.ReactNode;

  /**
   * Body, placed below the title section.
   */
  children?: React.ReactNode;

  className?: string;
};

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${spacing.m};
`;

export const ProfilePageHeaderCard: React.FCC<HeaderCardProps> = React.memo(
  function ProfilePageHeaderCardImpl({
    avatar,
    title,
    preTitle,
    subtitle,
    metadata,
    children,
    className,
  }) {
    return (
      <HeaderCard
        className={className}
        css={[
          cssForMediaSize({
            max: MediaSize.MEDIUM,
            css: css`
              display: block;
              /* TODO: encapsulate this padding + border logic in a mixin */
              > * {
                padding: 0 0 ${spacing.l} 0;
              }
              > :first-of-type {
                padding-top: 0;
              }
              > :last-child {
                padding-top: ${spacing.l};
                padding-bottom: 0;
              }
              > :last-child {
                border-top: 1px solid var(${colorCssVars.dividerSoft});
              }
            `,
          }),
          cssForMediaSize({
            min: MediaSize.LARGE,
            css: [
              horizontalStackCss.m,
              css`
                /* Align body title text */
                align-items: center;
                > *:not(${TitleSection}) {
                  margin-left: calc(${AvatarSize.X_LARGE}px + ${spacing.l});
                }
              `,
            ],
          }),
        ]}
      >
        {/* Title Section */}
        <TitleSection>
          <HeaderCardTitle
            title={title}
            subtitle={subtitle}
            preTitle={preTitle}
            avatar={avatar}
          />
          {children}
        </TitleSection>
        {metadata && <section>{metadata}</section>}
      </HeaderCard>
    );
  }
);
