import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import { matchingCampaignResponseCodec } from "../codecs/entities";
import { HttpMethod } from "../helpers/http";

import { makeRouteSpec } from ".";

export const claimCampaignGiftCardRouteSpec = makeRouteSpec({
  path: "/giftCardCampaign/:id/claim",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({ canUserClaim: t.boolean, claimed: t.boolean }),
});

export const getDonationMatchCampaignRouteSpec = makeRouteSpec({
  path: "/donationMatchCampaign/:identifier",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ identifier: t.string }),
  paramsCodec: t.partial({}),
  bodyCodec: t.type({}),
  publicRoute: {
    publicCacheLengthMinutes: 1,
    alsoCacheIfAuthenticated: true,
  },
  responseBodyCodec: matchingCampaignResponseCodec,
});
