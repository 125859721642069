import { TitleConatainer } from "@components/NewFundraiserCard/styles";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";

export const TitleSection = ({
  fundraiser,
  className,
}: {
  fundraiser: FundraiserResponse;
  className?: string;
}) => (
  <TitleConatainer className={className}>
    <h3>{fundraiser.title}</h3>
    {fundraiser.description && <p>{fundraiser.description}</p>}
  </TitleConatainer>
);
