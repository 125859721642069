import { Big } from "big.js";
import { useState, useCallback, useContext, useMemo } from "react";

import {
  PersonalDonationChargeResponse,
  DonationChargeResponse,
  DonationBoostResponse,
  DonationResponse,
} from "@every.org/common/src/codecs/entities";
import { SafeInt } from "@every.org/common/src/codecs/number";
import { Username } from "@every.org/common/src/codecs/username";
import { Currency } from "@every.org/common/src/entity/types";
import { TypeOfPromise } from "@every.org/common/src/helpers/types";
import {
  getDonationRouteSpec,
  getDonationByShortIdRouteSpec,
  ValueRaisedResponse,
  getDonationBoostsRouteSpec,
} from "@every.org/common/src/routes/donation";

import {
  NonprofitsContext,
  addNonprofits,
} from "src/context/NonprofitsContext";
import { UsersContext } from "src/context/UsersContext";
import { addUser } from "src/context/UsersContext/actions";
import {
  ViewDonationContext,
  ViewDonationData,
} from "src/context/ViewDonationContext";
import { ApiError } from "src/errors/ApiError";
import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";

export enum GetDonationStatus {
  LOADING = "LOADING",
  FETCH_ERROR = "FETCH_ERROR",
  SUCCESS = "SUCCESS",
}

export type GetDonationArgs =
  | { donationId: DonationResponse["id"] }
  | { shortId: number; nonprofitSlug: string; username: Username }
  | null;

// TODO #8478: re-check this, remember if the user selected something before interacting?
const NO_VALUE_RAISED = {
  oneTime: { currency: Currency.USD, amount: new Big(0) },
  monthly: { currency: Currency.USD, amount: new Big(0) },
  total: { currency: Currency.USD, amount: new Big(0) },
};

/**
 * If data is in location state (i.e. from a Read More link), gets data
 * regarding the donation from there; else fetches the API for data
 */
export function useDonationFromContext(
  args: GetDonationArgs,
  initialData?: ViewDonationData
): RetrieveDonationDataResult | null {
  const [donationCharge, setDonationCharge] = useState<
    PersonalDonationChargeResponse | DonationChargeResponse | undefined
  >(initialData?.donationCharge);
  const [boost, setBoost] = useState<DonationBoostResponse | undefined>(
    initialData?.boost
  );
  const [valueRaised, setValueRaised] = useState<
    ValueRaisedResponse | null | undefined
  >(initialData?.valueRaised);
  const nonprofitContext = useContext(NonprofitsContext);
  const usersContext = useContext(UsersContext);
  const { donationData = null } = useContext(ViewDonationContext) || {};
  const [fetchError, setFetchError] = useState<Error | null>(null);

  // Extract and memoize these to avoid unnecessary refetches in case client
  // failed to memoize properly
  const { donationId: argsDonationId = null } =
    args && "donationId" in args ? args : {};
  const {
    shortId = null,
    nonprofitSlug = null,
    username = null,
  } = args && "shortId" in args ? args : {};
  const memoizedArgs: GetDonationArgs = useMemo(
    () =>
      argsDonationId
        ? { donationId: argsDonationId }
        : shortId && nonprofitSlug && username
        ? { shortId, nonprofitSlug, username }
        : null,
    [argsDonationId, nonprofitSlug, shortId, username]
  );

  const fetchDonation = useCallback(() => {
    if (!memoizedArgs) {
      return Promise.resolve(undefined);
    }

    // Based on donationId
    const requestedId =
      "donationId" in memoizedArgs ? memoizedArgs.donationId : null;
    if (requestedId && donationData) {
      if (donationData.donationCharge.donation.id !== requestedId) {
        return Promise.resolve(undefined);
      }
      return Promise.resolve(donationData);
    }

    if ("donationId" in memoizedArgs) {
      return queryApi(getDonationRouteSpec, {
        routeTokens: { identifier: memoizedArgs.donationId },
        queryParams: {},
        body: {},
      });
    }

    // Based on slug/username/shortId
    const { shortId, nonprofitSlug, username } = memoizedArgs;
    if (donationData) {
      const {
        user: { username: fromUsername },
        nonprofit: { primarySlug: toNonprofitSlug },
        donationCharge: {
          donation: { shortId: donationDataShortId },
        },
      } = donationData;

      if (
        fromUsername == username &&
        toNonprofitSlug == nonprofitSlug &&
        donationDataShortId == (shortId || 0)
      ) {
        return Promise.resolve(donationData);
      }
    }

    return queryApi(getDonationByShortIdRouteSpec, {
      routeTokens: {
        nonprofitSlug,
        shortId: Math.floor(shortId) as SafeInt,
        username,
      },
      queryParams: {},
      body: {},
    });
  }, [memoizedArgs, donationData]);
  type FetchDonationResponse = TypeOfPromise<ReturnType<typeof fetchDonation>>;

  const handleFetchDonation = useCallback((resp: FetchDonationResponse) => {
    if (!resp) {
      return;
    }

    if ("valueRaised" in resp) {
      setValueRaised(resp.valueRaised);
    }
    setDonationCharge(resp.donationCharge);
    setBoost(resp.boost);
    addNonprofits([
      {
        ...resp.nonprofit,
        supporterCount: resp.nonprofitSupporterCount || undefined,
        nonprofitTags: resp.nonprofitTags,
      },
    ]);
    addUser(resp.user);
  }, []);
  useAsyncEffect({
    asyncOperation: fetchDonation,
    handleResponse: handleFetchDonation,
    handleError: useCallback((err) => {
      setFetchError(err);
    }, []),
  });

  const fetchDonationBoosts = useCallback(() => {
    const retrievedDonationId = donationCharge?.donation.id;
    // only actually fetch explicitly if data from state/previous fetch didn't
    // include valueRaised, but has already been fetched somehow
    if (valueRaised !== undefined || !retrievedDonationId) {
      return Promise.resolve(undefined);
    }
    return queryApi(getDonationBoostsRouteSpec, {
      routeTokens: { id: retrievedDonationId },
      queryParams: {},
      body: {},
    });
  }, [donationCharge, valueRaised]);
  type FetchDonationBoostsResult = TypeOfPromise<
    ReturnType<typeof fetchDonationBoosts>
  >;
  const handleFetchDonationBoosts = useCallback(
    (result: FetchDonationBoostsResult) => {
      if (!result) {
        return;
      }
      setValueRaised(result.valueRaised || null);
    },
    []
  );
  useAsyncEffect({
    asyncOperation: fetchDonationBoosts,
    handleResponse: handleFetchDonationBoosts,
    handleError: useCallback((error) => {
      if (error instanceof ApiError && error.httpStatus === 404) {
        // if its a 404 just means no boosts present
        setValueRaised(NO_VALUE_RAISED);
        return;
      }
      // don't error out the whole thing for this, non-essential
      logger.error({ error, message: "Could not fetch donation boosts" });
      setValueRaised(null);
    }, []),
  });

  const nonprofit = donationCharge?.toNonprofitId
    ? nonprofitContext.nonprofitsById.get(donationCharge?.toNonprofitId)
    : undefined;
  const user = donationCharge?.fromUserId
    ? usersContext.usersById.get(donationCharge?.fromUserId)
    : undefined;

  if (!memoizedArgs) {
    return null;
  }
  return fetchError
    ? { status: GetDonationStatus.FETCH_ERROR, error: fetchError }
    : donationCharge && nonprofit && user && boost && typeof user !== "symbol"
    ? {
        status: GetDonationStatus.SUCCESS,
        donationCharge,
        nonprofit,
        user,
        boost,
        valueRaised,
      }
    : { status: GetDonationStatus.LOADING };
}

type RetrieveDonationDataResult =
  | { status: GetDonationStatus.LOADING }
  | { status: GetDonationStatus.FETCH_ERROR; error: Error }
  | ({ status: GetDonationStatus.SUCCESS } & ViewDonationData);
