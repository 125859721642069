import { AvatarSize } from "@components/Avatar";
import {
  Button,
  ButtonRole,
  ButtonSize,
  ButtonTargetKind,
} from "@components/Button";
import { ErrorMessage } from "@components/ErrorMessage";
import { Icon, IconDisplay, IconSize } from "@components/Icon";
import { Loading } from "@components/LoadingIndicator";
import { Select, SelectProps } from "@components/Select";
import { TagIllustrationLabel } from "@components/TagIllustrationLabel";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { UUID } from "io-ts-types/UUID";
import React, { useCallback, useState } from "react";

import { TagResponse } from "@every.org/common/src/codecs/entities";
import { CAUSE_PASTEL_BG_PALETTE } from "@every.org/common/src/display/palette";
import {
  CauseCategory,
  CauseMetadata,
  TagNameByCauseCategory,
} from "@every.org/common/src/entity/types";
import { TakeInt, SkipInt } from "@every.org/common/src/routes/index";
import { listTagsRouteSpec } from "@every.org/common/src/routes/publicCached";
import { TagListResponse } from "@every.org/common/src/routes/search";

import { useAsyncEffect } from "src/hooks/useAsyncEffect";
import { colorCssVars, darkBgThemeCss, lightBgThemeCss } from "src/theme/color";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { queryApi } from "src/utility/apiClient";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

const MAX_AMOUNT_OF_CAUSES = 8;

export const TagSelect: React.FCC<
  Omit<SelectProps, "options"> & {
    tags: Pick<
      TagResponse,
      "id" | "causeCategory" | "tagName" | "title" | "tagImageCloudinaryId"
    >[];
  }
> = ({ tags, ...rest }) => {
  const window = getWindow();
  const isCoarsePointer: boolean | undefined =
    window?.matchMedia("(pointer: coarse)").matches;

  const options = tags.map((item) => ({
    label: isCoarsePointer ? (
      item.title
    ) : (
      <TagIllustrationLabel size={AvatarSize.XX_SMALL} tag={item} />
    ),
    value: item.tagName,
  }));

  return <Select options={options} {...rest} />;
};

interface CauseItemContainerProps {
  causeCategory: CauseCategory;
  selected: boolean;
}

const CauseItemContainer = styled.div<CauseItemContainerProps>`
  ${horizontalStackCss.xs};

  align-items: center;
  padding: ${spacing.xxs};
  padding-right: ${spacing.s};
  border-radius: 80px;
  cursor: pointer;

  ${({ selected, causeCategory }) => css`
    ${selected ? darkBgThemeCss : lightBgThemeCss};
    background-color: var(
      ${selected && colorCssVars.causeCategory[causeCategory].smallHighlight}
    );
    border: 2px solid
      var(${colorCssVars.causeCategory[causeCategory].smallHighlight});
  `};
`;

const HorizontalList = styled.div<{ causeCategory: CauseCategory }>`
  background-color: ${({ causeCategory }) =>
    CAUSE_PASTEL_BG_PALETTE[causeCategory].pastel40};

  ${horizontalStackCss.xs};

  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  padding: 0 ${spacing.xs} ${spacing.xs};
  border-radius: 24px;
  flex-shrink: 0;
  flex-wrap: wrap;

  ${CauseItemContainer} {
    flex-shrink: 0;
    margin-top: ${spacing.xs};
  } ;
`;

// helpers
function remove<T extends { id: UUID }>(tag: T) {
  return (arr: T[]) => arr.filter(({ id }) => id !== tag.id);
}
function add<T>(tag: T) {
  return (arr: T[]) => [...arr, tag];
}
function removeTagsDuplicate<T extends { id: UUID }>(tags: T[]) {
  return tags.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
}

export function mapTagsByCauseCategory(causes: TagResponse[]) {
  const allCauses = new Map<CauseCategory, TagResponse[]>();
  const parentCauses = new Map<CauseCategory, TagResponse>();

  causes.forEach((item: TagResponse) => {
    const isParentCause =
      TagNameByCauseCategory[item.causeCategory] === item.tagName;
    let tagsForCurrentCauseCategory = allCauses.get(item.causeCategory);
    if (!tagsForCurrentCauseCategory) {
      tagsForCurrentCauseCategory = [];
      allCauses.set(item.causeCategory, tagsForCurrentCauseCategory);
    }
    if (isParentCause) {
      parentCauses.set(item.causeCategory, item);
    } else {
      tagsForCurrentCauseCategory.push(item);
    }
  });

  // Sort alphabetically, add parent cause to the front if present
  for (const [causeCategory, causes] of allCauses.entries()) {
    causes.sort((a, b) => (a.tagName > b.tagName ? 1 : -1));
    const parentCause = parentCauses.get(causeCategory);
    if (parentCause) {
      causes.unshift(parentCause);
    }
  }

  return allCauses;
}

export const useAllCauses = () => {
  const [allCauses, setAllCauses] = useState<TagListResponse["causes"]>([]);

  useAsyncEffect({
    asyncOperation: useCallback(async () => {
      const { causes } = await queryApi(listTagsRouteSpec, {
        routeTokens: {},
        queryParams: { take: 100 as TakeInt, skip: 0 as SkipInt },
        body: {},
      });
      return causes;
    }, []),
    handleResponse: useCallback((causes: TagListResponse["causes"]) => {
      setAllCauses(causes);
    }, []),
    handleError: useCallback((error) => {
      logger.error({
        error,
        message: "Error while fetching all tags",
      });
    }, []),
  });

  return allCauses;
};

const useAllCausesByCauseCategory = () => {
  const allCauses = useAllCauses();

  return mapTagsByCauseCategory(allCauses);
};

interface EditTagsSelectorProps {
  selectedTags: TagResponse[];
  onSelectedTagsChange: (
    arg: TagResponse[] | ((tags: TagResponse[]) => TagResponse[])
  ) => void;
}

export const EditTagsSelector: React.FCC<EditTagsSelectorProps> = ({
  selectedTags,
  onSelectedTagsChange,
}) => {
  const allTagsByCauseCategory = useAllCausesByCauseCategory();
  const [error, setError] = useState<string>();

  if (!allTagsByCauseCategory.size) {
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          padding: ${spacing.xl};
        `}
      >
        <Loading />
      </div>
    );
  }

  const errorMsg = error && <ErrorMessage>{error}</ErrorMessage>;

  return (
    <div css={verticalStackCss.xs}>
      {errorMsg}
      {[...allTagsByCauseCategory]
        .sort((a, b) =>
          CauseMetadata[a[0]].title > CauseMetadata[b[0]].title ? 1 : -1
        )
        .map(([causeCategory, rowTags]) => (
          <HorizontalList
            css={horizontalStackCss.xs}
            key={causeCategory}
            causeCategory={causeCategory}
          >
            {rowTags.map((tag) => {
              return (
                <SelectedTagItem
                  key={tag.id}
                  tag={tag}
                  selected={!!selectedTags.find(({ id }) => tag.id === id)}
                  onRemove={(tag) => {
                    onSelectedTagsChange((tags) => {
                      const sortTegs = removeTagsDuplicate(tags);

                      if (sortTegs.length <= MAX_AMOUNT_OF_CAUSES) {
                        setError("");
                      }
                      return remove(tag)(tags);
                    });
                  }}
                  onAdd={(tag) => {
                    onSelectedTagsChange((tags) => {
                      const sortTegs = removeTagsDuplicate(tags);

                      // Note: this might allow 1 extra cause than the max if the user adds a subcause + the parent cause last
                      // If we don't allow this, to add a sub-cause from a new cause, the user would have to de-select 1 cause first,
                      // add the new one, remove the parent cause, and re-add the 7th cause
                      if (sortTegs.length >= MAX_AMOUNT_OF_CAUSES) {
                        setError(
                          `You can add at most ${MAX_AMOUNT_OF_CAUSES} causes, please remove a cause before adding another. Every cause you add should be reflected in your About and Learn more sections above.`
                        );
                        return tags;
                      }
                      return add(tag)(tags);
                    });
                  }}
                />
              );
            })}
          </HorizontalList>
        ))}
      {errorMsg}
    </div>
  );
};

const SelectedTagItem: React.FCC<{
  onRemove: (tag: TagResponse) => void;
  onAdd: (tag: TagResponse) => void;

  tag: TagResponse;
  selected: boolean;
}> = ({ onRemove, onAdd, tag, selected }) => {
  const onClick = () => {
    selected ? onRemove(tag) : onAdd(tag);
  };

  return (
    <CauseItemContainer
      key={tag.id}
      selected={selected}
      causeCategory={tag.causeCategory}
      onClick={onClick}
    >
      <TagIllustrationLabel
        size={AvatarSize.XX_SMALL}
        tag={tag}
        uncolored={selected}
      />
      {selected && (
        <Button
          role={ButtonRole.TEXT_ONLY}
          data-tname={tag.tagName + "--removeButton"}
          size={ButtonSize.SMALL}
          icon={
            <Icon
              iconImport={() => import("@components/Icon/icons/XIcon")}
              display={IconDisplay.TEXT}
              size={IconSize.SMALL}
            />
          }
          onClick={{
            kind: ButtonTargetKind.FUNCTION,
            action: () => {
              onRemove(tag);
            },
          }}
        />
      )}
    </CauseItemContainer>
  );
};
