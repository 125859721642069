import { Logo } from "@components/Logo";
import { Cell, Grid } from "@components/layout/GridSystem";
import { headerPopoverMenu } from "@components/layout/HeaderPopoverMenu";
import { PageSection } from "@components/layout/PageSection";
import type { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import dynamic from "next/dynamic";
import React, { useContext, useMemo } from "react";

import { MembershipResponse } from "@every.org/common/src/codecs/entities";
import { colorPalette } from "@every.org/common/src/display/palette";
import { NonprofitAdminStatus } from "@every.org/common/src/entity/types";
import { UTM_QUERY_PARAM } from "@every.org/common/src/helpers/analytics";
import {
  clientRouteMetas,
  ClientRouteName,
  getRoutePath,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

import { ReactComponent as PlaceholderIllustration } from "src/assets/illustrations/nonprofit_color.svg";
import { AvatarSize, RawAvatar } from "src/components/Avatar";
import { NonprofitAvatar } from "src/components/Avatar/NonprofitAvatar";
import { UserAvatar } from "src/components/Avatar/UserAvatar";
import {
  Button,
  ButtonRole,
  ButtonTargetKind,
  ButtonSize,
} from "src/components/Button";
import { IconSize, IconDisplay, Icon } from "src/components/Icon";
import { Link } from "src/components/Link";
import { LoadingIcon } from "src/components/LoadingIndicator";
import { AuthContext } from "src/context/AuthContext";
import { getLogoutApiEndpointUrl } from "src/context/AuthContext/actions";
import { AuthStatus } from "src/context/AuthContext/types";
import { useNonprofit } from "src/context/NonprofitsContext/hooks";
import { nonprofitOrUndefined } from "src/context/NonprofitsContext/selectors";
import { useEdoTheme } from "src/context/ThemeContext";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { LinkAppearance } from "src/styles/link";
import {
  colorCssVars,
  darkBgThemeNoBgCss,
  lightBgThemeNoBgCss,
} from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { spacing, horizontalStackCss } from "src/theme/spacing";
import { isMissingRequiredUserFields } from "src/utility/user";

const SearchBar = dynamic(async () => {
  // If we do it with `.then()` TS complains
  const mod = await import("@components/layout/SearchBar");
  return mod.SearchBar;
});

const PLACEHOLDER_CSS = css`
  height: 100%;
  width: auto;
`;

const StyledLogo = styled(Logo)`
  display: block;
  transition: height 0.2s ease;
  width: auto;
  height: 32px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  .logo-text {
    display: none;
  }
  padding-left: ${spacing.m};
  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      padding-left: 0;
    `,
  })}

  ${cssForMediaSize({
    min: MediaSize.XX_LARGE,
    css: css`
      height: 40px;
      .logo-text {
        display: block;
      }
    `,
  })}
`;

const LightThemeStyledLogo = styled(StyledLogo)`
  .dotorg,
  .logo-mark {
    fill: var(${colorCssVars.accent.smallHighlight});
  }
`;

const iconNavElemCss = [
  css`
    display: block;
    border-radius: 500px;
    padding: ${spacing.xs};

    background-color: var(${colorCssVars.headerIcon.background.normal});
    color: var(${colorCssVars.headerIcon.text.normal});
    transition: background-color 0.1s ease-in, color 0.1s ease-in;
    &:hover {
      background-color: var(${colorCssVars.headerIcon.background.hover});
      color: var(${colorCssVars.headerIcon.text.hover});
    }
  `,
];

const notifsIndicatorCss = [
  iconNavElemCss,
  css`
    z-index: 1; /* so that it goes over other header nav elems */
  `,
];

const blockLinkCss = css`
  display: block;
  /* for focus style */
  border-radius: 100%;
`;

const textNavListCss = [
  horizontalStackCss.l,
  css`
    align-items: center;
    padding-right: ${spacing.m};
  `,
  cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: [horizontalStackCss.xl, { paddingRight: 0 }],
  }),
];

const headerHavListCss = (isLight?: boolean) => css`
  ${horizontalStackCss.xs};
  align-items: center;
  padding-right: ${spacing.m};
  ${isLight ? lightBgThemeNoBgCss : darkBgThemeNoBgCss};
  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: { paddingRight: 0 },
  })}
`;

const defaultNavContentCss = (isLight?: boolean) => css`
  ${isLight ? lightBgThemeNoBgCss : darkBgThemeNoBgCss}
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${spacing.xs};
  padding-left: ${spacing.xs};
`;

const GroupsLink = ({ memberships }: { memberships: MembershipResponse[] }) => {
  const group = nonprofitOrUndefined(
    useNonprofit(
      memberships.length === 1 ? { id: memberships[0].nonprofitId } : undefined
    )
  );

  if (group) {
    return <NonprofitAvatar size={AvatarSize.SMALL} nonprofit={group} />;
  }

  return (
    <Link
      css={iconNavElemCss}
      data-tname="nav--memberships"
      to={getRoutePath({
        name: ClientRouteName.GROUPS,
        format: URLFormat.RELATIVE,
        tokens: {},
      })}
      title="Navigate to my groups"
    >
      <Icon
        iconImport={() => import("src/components/Icon/icons/Users")}
        size={IconSize.MEDIUM}
        display={IconDisplay.CURRENT_COLOR}
      />
    </Link>
  );
};

interface HeaderProps {
  className?: string;
  contentCss?: CSSInterpolation[];
  /**
   * If true, hides the searchbar in the nav header
   *
   * @default false if logged in, true if not
   */
  hideSearchbar?: boolean;
  fullWidth?: boolean;
  hideSignup?: boolean;
  showGetStarted?: boolean;
}
const HeaderNavWithRef = React.forwardRef<HTMLElement, HeaderProps>(
  function HeaderNavImpl(
    {
      className,
      contentCss,
      hideSearchbar: hideSearchbarParam = false,
      fullWidth = false,
      hideSignup,
      showGetStarted,
    },
    ref
  ) {
    const { isLight } = useEdoTheme();
    return (
      <PageSection
        className={className}
        contentCss={[contentCss, defaultNavContentCss(isLight)]}
        ref={ref}
        fullWidth={fullWidth}
      >
        <Grid
          css={[
            css`
              padding-top: ${spacing.m};
              padding-bottom: ${spacing.m};
            `,
            cssForMediaSize({
              min: MediaSize.MEDIUM,
              css: css`
                padding-top: ${spacing.l};
                padding-bottom: ${spacing.l};
              `,
            }),
          ]}
          flexCss={[
            css`
              align-items: center;
              justify-content: space-between;
            `,
            cssForMediaSize({
              min: MediaSize.MEDIUM,
              css: css`
                flex-wrap: nowrap;
              `,
            }),
          ]}
        >
          <Cell numCols={1} numColsXLarge={3}>
            <Link
              appearance={LinkAppearance.UNSTYLED}
              data-tname="HeaderNav--Home"
              title="Navigate to home page"
              to={getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.HOME,
              })}
            >
              {isLight ? <LightThemeStyledLogo /> : <StyledLogo />}
            </Link>
          </Cell>
          {!hideSearchbarParam && <SearchBar />}
          <HeaderNavCell
            isLight={isLight}
            hideSignup={hideSignup}
            showGetStarted={showGetStarted}
          />
        </Grid>
      </PageSection>
    );
  }
);
export const HeaderNav = React.memo(HeaderNavWithRef);

const HeaderNavCell = React.memo(function HeaderNavCellImpl({
  isLight,
  hideSignup,
  showGetStarted,
}: {
  isLight?: boolean;
  hideSignup?: boolean;
  showGetStarted?: boolean;
}) {
  const authState = useContext(AuthContext);
  return (
    <Cell
      css={css`
        display: flex;
        justify-content: flex-end;
        position: relative;
      `}
      numCols={authState.status === AuthStatus.LOADING ? 2 : "FLEX_INITIAL"}
      numColsXLarge={
        authState.status === AuthStatus.LOGGED_OUT ? "FLEX_INITIAL" : 4
      }
      role="navigation"
      aria-label="Header navigation"
    >
      <HeaderNavList hideSignup={hideSignup} showGetStarted={showGetStarted} />
    </Cell>
  );
});

const LoggedOutHeaderNavList = React.memo(function LoggedOutHeaderNavListImpl({
  isLight,
  hideSignup,
  showGetStarted,
}: {
  isLight?: boolean;
  hideSignup?: boolean;
  showGetStarted?: boolean;
}) {
  const { pathname, search, hash } = useEdoRouter();
  const searchParams = new URLSearchParams(search.substr(1));
  const redirectUrlStr = searchParams.get("redirectUrl");
  const restrictedRedirectPaths = [
    ClientRouteName.HOME,
    ClientRouteName.SIGNUP,
    ClientRouteName.LOGIN,
  ].map((route) => clientRouteMetas[route].path);
  const redirectUrl = redirectUrlStr
    ? redirectUrlStr
    : !restrictedRedirectPaths.includes(pathname)
    ? pathname + search + hash
    : null;

  const HeaderPopoverMenu = useMemo(() => headerPopoverMenu(), []);

  return (
    <ul
      css={[
        { display: "flex", alignItems: "center", paddingRight: spacing.m },
        cssForMediaSize({ min: MediaSize.MEDIUM, css: { paddingRight: 0 } }),
      ]}
    >
      <li css={{ marginRight: spacing.m }}>
        <Link
          data-tname="navLogInButton"
          to={getRoutePath({
            format: URLFormat.RELATIVE,
            name: ClientRouteName.LOGIN,
            query: redirectUrl ? { redirectUrl } : undefined,
          })}
          appearance={LinkAppearance.HYPERLINK}
        >
          Log in
        </Link>
      </li>
      {!hideSignup && (
        <li css={{ marginRight: spacing.xs }}>
          <Button
            data-tname="navSignUpButton"
            role={ButtonRole.PRIMARY}
            css={{ backgroundColor: colorPalette.tealLogoDark }}
            size={ButtonSize.SMALL}
            contentCss={isLight && { color: colorPalette.white }}
            onClick={{
              kind: ButtonTargetKind.LINK,
              to: getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.SIGNUP,
                query: redirectUrl ? { redirectUrl } : undefined,
              }),
            }}
          >
            Sign up
          </Button>
        </li>
      )}
      {showGetStarted && (
        <li css={{ marginRight: spacing.xs }}>
          <Button
            data-tname="navGetStartedButton"
            role={ButtonRole.PRIMARY}
            css={{ backgroundColor: colorPalette.tealLogoDark }}
            size={ButtonSize.SMALL}
            contentCss={isLight && { color: colorPalette.white }}
            onClick={{
              kind: ButtonTargetKind.LINK,
              to: getRoutePath({
                format: URLFormat.RELATIVE,
                name: ClientRouteName.NONPROFITS_ONBOARDING_SEARCH,
                query: { [UTM_QUERY_PARAM.utm_source]: "splash" },
              }),
            }}
          >
            Get started
          </Button>
        </li>
      )}
      <li>
        <HeaderPopoverMenu />
      </li>
    </ul>
  );
});

const HeaderNavList = React.memo(function HeaderNavListImpl({
  hideSignup,
  showGetStarted,
}: {
  hideSignup?: boolean;
  showGetStarted?: boolean;
}) {
  const { isLight } = useEdoTheme();
  const authState = useContext(AuthContext);

  const adminFor = authState.user?.adminFor || [];

  const Notifications = useMemo(
    () =>
      dynamic(() => import("@components/notifications/Notifications"), {
        loading: () => (
          <LoadingIcon display={IconDisplay.TEXT} size={IconSize.MEDIUM} />
        ),
      }),
    []
  );

  const HeaderPopoverMenu = useMemo(() => headerPopoverMenu(), []);

  if (authState.status === AuthStatus.LOADING) {
    // The height of the icons when the user is logged in.
    // It prevents layout shift after SSR
    return <div css={{ height: "40px" }} />;
  }

  if (authState.status === AuthStatus.LOGGED_OUT) {
    return (
      <LoggedOutHeaderNavList
        isLight={isLight}
        hideSignup={hideSignup}
        showGetStarted={showGetStarted}
      />
    );
  }

  if (isMissingRequiredUserFields(authState.user)) {
    // partially created profile, still signing up
    return (
      <ul css={textNavListCss}>
        <li>
          <Link
            data-tname="nav--signingUp--logout"
            to={getLogoutApiEndpointUrl()}
            forceExternal
            appearance={LinkAppearance.HYPERLINK}
          >
            Log out
          </Link>
        </li>
        <li>
          <HeaderPopoverMenu />
        </li>
      </ul>
    );
  }

  const { memberships } = authState.user;

  const filteredAdminFor = adminFor.filter(
    (admin) =>
      [NonprofitAdminStatus.REQUESTED, NonprofitAdminStatus.CONFIRMED].includes(
        admin.status
      ) && admin.directAdmin
  );

  const isAdmin = !!filteredAdminFor.length;

  const manageNonprofitsLink =
    isAdmin && filteredAdminFor.length === 1
      ? getRoutePath({
          format: URLFormat.RELATIVE,
          name: ClientRouteName.NONPROFIT_ADMIN,
          tokens: {
            nonprofitSlug: filteredAdminFor[0].nonprofit.primarySlug,
          },
        })
      : getRoutePath({
          format: URLFormat.RELATIVE,
          name: ClientRouteName.MANAGE_NONPROFITS,
        });

  return (
    <ul css={headerHavListCss(isLight)}>
      {!isAdmin && (
        <li>
          <Link
            title="Navigate to my giving page"
            css={iconNavElemCss}
            data-tname="nav--myGiving"
            to={getRoutePath({
              format: URLFormat.RELATIVE,
              name: ClientRouteName.MY_GIVING,
            })}
          >
            <Icon
              iconImport={() => import("src/components/Icon/icons/DollarIcon")}
              size={IconSize.MEDIUM}
              display={IconDisplay.CURRENT_COLOR}
            />
          </Link>
        </li>
      )}
      <li>
        <Notifications css={notifsIndicatorCss} />
      </li>
      <li>
        <Link
          title="Navigate to gift cards"
          data-tname="nav--giftCards"
          to={getRoutePath({
            format: URLFormat.RELATIVE,
            name: ClientRouteName.GIFT_CARD_PURCHASE,
          })}
          css={iconNavElemCss}
        >
          <Icon
            iconImport={() => import("src/components/Icon/icons/GiftIcon")}
            size={IconSize.MEDIUM}
            display={IconDisplay.CURRENT_COLOR}
          />
        </Link>
      </li>
      {memberships && memberships.length > 0 && (
        <li
          css={cssForMediaSize({
            max: MediaSize.X_SMALL,
            css: { display: "none" },
          })}
        >
          <GroupsLink memberships={memberships} />
        </li>
      )}
      {!isAdmin && (
        <li>
          <Link
            title="Navigate to profile page"
            css={blockLinkCss}
            data-tname="nav--profileAvatar"
            to={getRoutePath({
              format: URLFormat.RELATIVE,
              name: ClientRouteName.ME,
            })}
          >
            <UserAvatar
              size={AvatarSize.SMALL}
              user={authState.user}
              disableLink
            />
          </Link>
        </li>
      )}
      {isAdmin && (
        <li>
          <Link
            title="Navigate to nonprofit admin page"
            css={blockLinkCss}
            data-tname="nav--nonprofitAdmin"
            to={manageNonprofitsLink}
          >
            <RawAvatar
              cloudinaryId={
                filteredAdminFor.length === 1
                  ? filteredAdminFor[0].nonprofit.logoCloudinaryId
                  : null
              }
              size={AvatarSize.SMALL}
              placeholder={<PlaceholderIllustration css={PLACEHOLDER_CSS} />}
            />
          </Link>
        </li>
      )}
      <li>
        <HeaderPopoverMenu
          isAdmin={isAdmin}
          manageNonprofitsLink={manageNonprofitsLink}
        />
      </li>
    </ul>
  );
});
