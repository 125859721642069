import { preformRaisedAmounts } from "@components/NewFundraiserCard/preformRaisedAmounts";
import { LabeledThermometer } from "@components/Thermometer/LabeledThermometer";
import { css } from "@emotion/react";
import { Big } from "big.js";

import {
  FundraiserResponse,
  FundraiserRaisedResponse,
} from "@every.org/common/src/codecs/entities";
import { SafeInt } from "@every.org/common/src/codecs/number";
import { Currency } from "@every.org/common/src/entity/types";
import { abbreviateNumber } from "@every.org/common/src/helpers/number";

import { colorCssVars } from "src/theme/color";
import { verticalStackCss } from "src/theme/spacing";
import { FontWeight, numPxTextSizes, textSizeCss } from "src/theme/text";
import { displayCurrencyValueInUserLocale } from "src/utility/currency";

const StatsForAdmin = ({
  currentAmount,
  raisedOfflineAmount,
  currency,
  donations,
  supporters,
}: {
  currentAmount: Big;
  raisedOfflineAmount?: Big | null;
  currency: Currency;
  donations: SafeInt;
  supporters: SafeInt;
}) => {
  const totalAmount = new Big(currentAmount).add(raisedOfflineAmount ?? 0);

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-weight: ${FontWeight.MEDIUM};
        ${textSizeCss.s};
        span {
          color: var(${colorCssVars.text.secondary});
          font-weight: ${FontWeight.REGULAR};
        }
      `}
    >
      <div
        css={css`
          color: var(${colorCssVars.text.body});
          font-weight: ${FontWeight.BOLD};
        `}
      >
        {displayCurrencyValueInUserLocale({
          currencyValue: {
            currency,
            amount: new Big(totalAmount.toFixed(0)),
          },
        })}{" "}
        <span>Raised</span>
      </div>
      <div>
        {abbreviateNumber(donations, 1)}{" "}
        <span>donation{donations > 1 ? "s" : ""}</span>
      </div>
      <div>
        {abbreviateNumber(supporters, 1)}{" "}
        <span>supporter{supporters > 1 ? "s" : ""}</span>
      </div>
    </div>
  );
};

export const ThermometerSection = ({
  fundraiser,
  raisedData,
  showStatsForAdmin = false,
}: {
  fundraiser: FundraiserResponse;
  raisedData: FundraiserRaisedResponse;
  showStatsForAdmin?: boolean;
}) => {
  const { currentAmount, goalAmount, raisedMounthly, raisedOfflineAmount } =
    preformRaisedAmounts({ fundraiser, raisedData });

  return (
    <div css={[{ position: "relative" }, verticalStackCss.m]}>
      {fundraiser.goalAmount !== 0 && (
        <LabeledThermometer
          currency={fundraiser.goalCurrency ?? Currency.USD}
          goalLabel={fundraiser.goalAmount ? "Goal" : "Next milestone"}
          progressLabel="Raised"
          goalAmount={goalAmount}
          currentAmount={currentAmount}
          monthly={raisedMounthly}
          raisedOffline={raisedOfflineAmount}
          labelPosition="top"
          css={css`
            dt {
              font-size: ${numPxTextSizes.xs}px!important;
            }
          `}
        />
      )}
      {showStatsForAdmin && (
        <StatsForAdmin
          currentAmount={currentAmount}
          raisedOfflineAmount={raisedOfflineAmount}
          currency={raisedData.currency}
          donations={raisedData.supporters}
          supporters={raisedData.supporters}
        />
      )}
    </div>
  );
};
