import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import { TextInput } from "@components/TextInput";
import { BackButton } from "@components/donate/DonateV3/PaymentProcess/components/BackButton";
import {
  PageContainer,
  ProcessContainer,
} from "@components/donate/DonateV3/PaymentProcess/components/PageContainer";
import {
  PaymentProcessRouteName,
  paymentProcessRouteNameToPathMap,
} from "@components/donate/DonateV3/PaymentProcess/components/PaymentProcessLink";
import { StockSummary } from "@components/donate/DonateV3/PaymentProcess/pages/Stocks/StockSummary";
import { DonateFormContext } from "@components/donate/DonateV3/PaymentProcess/useDonateFormContext";
import { useSyncPaymentMethod } from "@components/donate/DonateV3/PaymentProcess/useSyncPaymentMethod";
import { validateText } from "@components/donate/DonateV3/PaymentProcess/validators";
import { DonateFormType } from "@components/donate/DonateV3/types";
import { css } from "@emotion/react";
import { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FundraiserResponse } from "@every.org/common/src/codecs/entities";
import { DonationFlowPaymentOption } from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { verticalStackCss } from "src/theme/spacing";
import { TextSize, textSizeCss } from "src/theme/text";
import { getWindow } from "src/utility/window";

export const StockSignatureBrokerPage = ({
  form,
  formContext,
  nonprofit,
  fundraiser,
}: {
  form: UseFormReturn<DonateFormType>;
  formContext: DonateFormContext;
  nonprofit: ContextNonprofit;
  fundraiser?: FundraiserResponse | null;
}) => {
  const stockSymbol = form.watch("stockSymbol");
  const stockAmount = form.watch("stockAmount");
  const firstName = form.watch("firstName");
  const lastName = form.watch("lastName");
  const email = form.watch("email");
  const brokerageFirm = form.watch("brokerageFirm");
  const brokerageAccountNumber = form.watch("brokerageAccountNumber");

  useSyncPaymentMethod({
    paymentOption: DonationFlowPaymentOption.STOCKS,
    form,
    formContext,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getWindow()?.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const window = getWindow();
    if (window) {
      if (!stockSymbol || !stockAmount) {
        navigate(
          paymentProcessRouteNameToPathMap[
            PaymentProcessRouteName.STOCKS_SIGNATURE
          ],
          { replace: true }
        );
      }
      if (!firstName || !lastName || !email) {
        navigate(
          paymentProcessRouteNameToPathMap[
            PaymentProcessRouteName.STOCKS_SIGNATURE_DONOR_INFO
          ],
          { replace: true }
        );
      }
    }
  }, [firstName, lastName, email, stockSymbol, stockAmount, navigate]);

  // TODO #15621: enable pull stock flow for everybody (remove this navigate)
  useEffect(() => {
    const window = getWindow();
    if (window && !nonprofit.metadata?.showStockPull) {
      navigate(
        paymentProcessRouteNameToPathMap[PaymentProcessRouteName.STOCKS_MANUAL],
        { replace: true }
      );
    }
  }, [navigate, nonprofit]);

  return (
    <PageContainer>
      <BackButton
        backAction={() => {
          navigate(-1);
        }}
        paymentOption={DonationFlowPaymentOption.STOCKS}
      />
      <ProcessContainer>
        <form css={verticalStackCss.l}>
          <h5
            css={css`
              ${textSizeCss[TextSize.s]};
            `}
          >
            Your Donation
          </h5>
          <StockSummary stockSymbol={stockSymbol} stockAmount={stockAmount} />

          <div css={verticalStackCss.xxs}>
            <h5
              css={css`
                ${textSizeCss[TextSize.s]};
              `}
            >
              Brokerage firm *
            </h5>
            <div css={verticalStackCss.s}>
              <Controller
                control={form.control}
                name="brokerageFirm"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    name="brokerageFirm"
                    id="brokerageFirm"
                    data-tname="brokerageFirm"
                    onChange={onChange}
                    value={value}
                    validationStatus={{
                      success: !form.formState.errors["brokerageFirm"]?.message,
                      message: form.formState.errors["brokerageFirm"]?.message,
                    }}
                    collapseDescriptionSpace
                  />
                )}
              />
            </div>
          </div>
          <div css={verticalStackCss.xxs}>
            <h5
              css={css`
                ${textSizeCss[TextSize.s]};
              `}
            >
              Brokerage account number *
            </h5>
            <div css={verticalStackCss.s}>
              <Controller
                control={form.control}
                name="brokerageAccountNumber"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    name="brokerageAccountNumber"
                    id="brokerageAccountNumber"
                    data-tname="brokerageAccountNumber"
                    onChange={onChange}
                    value={value}
                    validationStatus={{
                      success:
                        !form.formState.errors["brokerageAccountNumber"]
                          ?.message,
                      message:
                        form.formState.errors["brokerageAccountNumber"]
                          ?.message,
                    }}
                    collapseDescriptionSpace
                  />
                )}
              />
            </div>
          </div>
          <div css={verticalStackCss.xxs}>
            <h5
              css={css`
                ${textSizeCss[TextSize.s]};
              `}
            >
              Broker contact name
            </h5>
            <div css={verticalStackCss.s}>
              <Controller
                control={form.control}
                name="brokerContactName"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    name="brokerContactName"
                    id="brokerContactName"
                    data-tname="brokerContactName"
                    onChange={onChange}
                    value={value}
                    validationStatus={{
                      success:
                        !form.formState.errors["brokerContactName"]?.message,
                      message:
                        form.formState.errors["brokerContactName"]?.message,
                    }}
                    collapseDescriptionSpace
                  />
                )}
              />
            </div>
          </div>
          <div css={verticalStackCss.xxs}>
            <h5
              css={css`
                ${textSizeCss[TextSize.s]};
              `}
            >
              Broker email
            </h5>
            <div css={verticalStackCss.s}>
              <Controller
                control={form.control}
                name="brokerEmailAddress"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    name="brokerEmailAddress"
                    id="brokerEmailAddress"
                    data-tname="brokerEmailAddress"
                    onChange={onChange}
                    value={value}
                    validationStatus={{
                      success:
                        !form.formState.errors["brokerEmailAddress"]?.message,
                      message:
                        form.formState.errors["brokerEmailAddress"]?.message,
                    }}
                    collapseDescriptionSpace
                  />
                )}
              />
            </div>
          </div>
          <div css={verticalStackCss.xxs}>
            <h5
              css={css`
                ${textSizeCss[TextSize.s]};
              `}
            >
              Broker phone
            </h5>
            <div css={verticalStackCss.s}>
              <Controller
                control={form.control}
                name="brokerPhoneNumber"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    name="brokerPhoneNumber"
                    id="brokerPhoneNumber"
                    data-tname="brokerPhoneNumber"
                    onChange={onChange}
                    value={value}
                    validationStatus={{
                      success:
                        !form.formState.errors["brokerPhoneNumber"]?.message,
                      message:
                        form.formState.errors["brokerPhoneNumber"]?.message,
                    }}
                    collapseDescriptionSpace
                  />
                )}
              />
            </div>
          </div>
          <div css={verticalStackCss.l}>
            <Button
              role={ButtonRole.PRIMARY}
              onClick={{
                kind: ButtonTargetKind.FUNCTION,
                action: () => {
                  let valid = true;
                  // prettier-ignore
                  {
                    valid = valid && validateText(brokerageFirm, "brokerageFirm", "Brokerage firm", form.setError)
                    valid = valid && validateText(brokerageAccountNumber, "brokerageAccountNumber", "brokerageAccountNumber", form.setError)
                  }
                  if (!valid) {
                    return;
                  }
                  navigate(
                    paymentProcessRouteNameToPathMap[
                      PaymentProcessRouteName.STOCKS_SIGNATURE_SIGN
                    ]
                  );
                },
              }}
              data-tname="donateStockSignatureFlowBrokerNext"
            >
              Next
            </Button>
          </div>
        </form>
      </ProcessContainer>
    </PageContainer>
  );
};
