import { Big } from "big.js";
import * as t from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import {
  PersonalDonationChargeResponse,
  DonationResponse,
  UserResponse,
  FundraiserResponse,
} from "@every.org/common/src/codecs/entities";
import { decodeOrUndefined } from "@every.org/common/src/codecs/index";
import {
  BoundedIntIotsBrand,
  coerceToSafeIntOrThrow,
  SafeInt,
} from "@every.org/common/src/codecs/number";
import { Currency } from "@every.org/common/src/entity/types";
import {
  currencyValueToMinimumDenominationAmount,
  minimumDenominationAmountToCurrencyValue,
} from "@every.org/common/src/helpers/currency";
import { isPresent } from "@every.org/common/src/helpers/objectUtilities";
import { TypeOfPromise } from "@every.org/common/src/helpers/types";
import { getCommunityFeedRouteSpec } from "@every.org/common/src/routes/community";
import {
  createDonationRouteSpec,
  updateCommentRouteSpec,
} from "@every.org/common/src/routes/donate";
import { getDonationBoostsRouteSpec } from "@every.org/common/src/routes/donation";
import { getFollowRecommendationsRouteSpec } from "@every.org/common/src/routes/follow";
import { getFundraiserFeedRouteSpec } from "@every.org/common/src/routes/fundraiser";
import { ListParams } from "@every.org/common/src/routes/index";
import {
  getHomeFeedRouteSpec,
  getAvailableGivingCreditRouteSpec,
  getGivingCreditsRouteSpec,
} from "@every.org/common/src/routes/me";
import {
  getNonprofitFeedRouteSpec,
  getFundFeedRouteSpec,
} from "@every.org/common/src/routes/nonprofit";
import { getNteeCategoryFeedRouteSpec } from "@every.org/common/src/routes/nteeCategory";
import {
  getPublicHomeFeedRouteSpec,
  getLandingDataRouteSpec,
} from "@every.org/common/src/routes/publicCached";
import {
  getTagDonationsFeedRouteSpec,
  getTagNonprofitsFeedRouteSpec,
} from "@every.org/common/src/routes/tag";
import {
  getUserLikesRouteSpec,
  getUserFeedRouteSpec,
  getUserFundsRouteSpec,
} from "@every.org/common/src/routes/users";

import { FeedResponse } from "src/components/feed/types";
import { dispatchPersonalDonationsAction } from "src/context/DonationsContext/";
import { DonationActionType } from "src/context/DonationsContext/types";
import { dispatchMyDonationsAction } from "src/context/MyDonationsContext";
import { MyDonationsActionType } from "src/context/MyDonationsContext/types";
import { addNonprofits } from "src/context/NonprofitsContext";
import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { addTags } from "src/context/TagContext/actions";
import { resetTurnstile } from "src/context/TurnstileContext/useTurnstile";
import { addUsers } from "src/context/UsersContext/actions";
import {
  trackDonate,
  entryRouteName,
  entryEntityName,
  getDeviceType,
} from "src/utility/analytics";
import { queryApi } from "src/utility/apiClient";
import { getUTMValuesFromCookie } from "src/utility/cookies";
import { promiseWithTimeout } from "src/utility/helpers";
import { logger } from "src/utility/logger";
import {
  MixpanelProfileProperties,
  registerMixpanelProperty,
} from "src/utility/mixpanel";
import { getWindow } from "src/utility/window";

/**
 * Fetches a nonprofit's donations feed.
 * If a fundraiserId is supplied, only donations from that fundraiser will be fetched.
 *
 * This last thing might be a bit controversial given the name of the function,
 * but another function would be pretty much the same as this one.
 * We can separate them later.
 *
 */
type GetNonprofitFeedResult = t.TypeOf<
  typeof getNonprofitFeedRouteSpec.responseBodyCodec
>;
export async function fetchNonprofitFeed({
  nonprofitId,
  fundraiserId,
  skip,
  take,
}: ListParams & {
  nonprofitId: ContextNonprofit["id"];
  fundraiserId?: FundraiserResponse["id"];
}): Promise<GetNonprofitFeedResult> {
  const routeTokens = !fundraiserId
    ? { id: nonprofitId }
    : { id: fundraiserId };
  const routeSpec = !fundraiserId
    ? getNonprofitFeedRouteSpec
    : getFundraiserFeedRouteSpec;
  const result = await queryApi(routeSpec, {
    routeTokens,
    body: {},
    queryParams: { skip, take },
  });
  result.users && addUsers(result.users);
  return result;
}

export async function fetchFundFeed({
  fundId,
  skip,
  take,
}: ListParams & {
  fundId: ContextNonprofit["id"];
}) {
  const result = await queryApi(getFundFeedRouteSpec, {
    routeTokens: { id: fundId },
    body: {},
    queryParams: { skip, take },
  });
  result.users && addUsers(result.users);
  return result;
}

export async function fetchCommunityFeed({
  communityId,
  skip,
  take,
}: ListParams & {
  communityId: ContextNonprofit["id"];
}) {
  const result = await queryApi(getCommunityFeedRouteSpec, {
    routeTokens: { id: communityId },
    body: {},
    queryParams: { skip, take },
  });
  result.nonprofits && addNonprofits(result.nonprofits);
  result.users && addUsers(result.users);
  return result;
}

export async function fetchFeed({
  feedRouteSpec,
  userId,
  take,
  skip,
}: ListParams & {
  userId: UserResponse["id"];
  feedRouteSpec: typeof getUserLikesRouteSpec | typeof getUserFeedRouteSpec;
}): Promise<FeedResponse> {
  const { users, nonprofits, items, hasMore, nonprofitTags } = await queryApi(
    feedRouteSpec,
    {
      routeTokens: { id: userId },
      body: {},
      queryParams: { take, skip },
    }
  );
  users && addUsers(users);
  nonprofits && addNonprofits(nonprofits);
  nonprofitTags && addTags(nonprofitTags);
  return { items, hasMore };
}

export async function fetchUserFundsFeed({
  userId,
  skip,
  take,
}: ListParams & {
  userId: UserResponse["id"];
}) {
  const { users, nonprofits, items, hasMore } = await queryApi(
    getUserFundsRouteSpec,
    {
      routeTokens: { id: userId },
      body: {},
      queryParams: { take, skip },
    }
  );
  users && addUsers(users);
  nonprofits && addNonprofits(nonprofits);
  return { items, hasMore };
}

export function fetchGiftsFeed({
  userId,
  take,
  skip,
}: ListParams & {
  userId: UserResponse["id"];
}) {
  return fetchFeed({
    userId,
    take,
    skip,
    feedRouteSpec: getUserFeedRouteSpec,
  });
}

export function fetchLikesFeed({
  userId,
  take,
  skip,
}: ListParams & {
  userId: UserResponse["id"];
}) {
  return fetchFeed({
    userId,
    take,
    skip,
    feedRouteSpec: getUserLikesRouteSpec,
  });
}

export type PageNum = t.Branded<
  t.Branded<number, t.IntBrand>,
  BoundedIntIotsBrand
>;

export async function fetchTagRecommendations(tag: string, pageNum: number) {
  const responseData = await queryApi(getTagNonprofitsFeedRouteSpec, {
    routeTokens: { tagName: tag },
    body: {},
    queryParams: { pageNum: pageNum as PageNum },
  });
  const allTags = new Map(
    responseData.nonprofitTags.map((tag) => [tag.id, tag])
  );
  const nonprofitsToAdd = responseData.nonprofits.map<ContextNonprofit>(
    (nonprofit) => ({
      ...nonprofit,
      nonprofitTags: nonprofit.tags
        ?.map((tag) => allTags.get(tag))
        .filter(isPresent),
    })
  );
  addNonprofits(nonprofitsToAdd);
  return responseData;
}

export async function fetchTagGifts(tag: string, pageNum: number) {
  const responseData = await queryApi(getTagDonationsFeedRouteSpec, {
    routeTokens: { tagName: tag },
    body: {},
    queryParams: { pageNum: pageNum as PageNum },
  });
  responseData.nonprofits && addNonprofits(responseData.nonprofits);
  responseData.users && addUsers(responseData.users);
  return responseData;
}

export async function fetchNteeCategoryRecommendations(
  nteeCode: string,
  pageNum: number
) {
  const responseData = await queryApi(getNteeCategoryFeedRouteSpec, {
    routeTokens: {},
    body: {},
    queryParams: { nteeCode, pageNum: pageNum as PageNum },
  });
  responseData.nonprofits && addNonprofits(responseData.nonprofits);
  return responseData;
}

// If client ever needs to used unpersonalized public feed,
// always use public feed until refresh.
// Better would be to store some timestamp in localstorate and only use public
// if we recently failed to use personalized.
let usePublicHomeFeed = false;

export async function fetchPublicHomeFeed({ take, skip }: ListParams) {
  const responseData = await queryApi(getPublicHomeFeedRouteSpec, {
    routeTokens: {},
    body: {},
    queryParams: {},
  });
  return {
    users: responseData.users,
    nonprofits: responseData.nonprofits,
    // Filtering on front end means that client can instantly get all data
    // and then cache locally, later loads can use disk cache as take/limit
    // change. Makes CDN work very well, but means we can only send back so much.
    items: responseData.items.slice(skip, take + skip),
    hasMore: responseData.items.length > skip + take,
  };
}

const GET_HOME_FEED_TIMEOUT_MS = 3000;

async function fetchHomeFeed_(listParams: ListParams, isLoggedOut?: boolean) {
  if (usePublicHomeFeed || isLoggedOut) {
    return fetchPublicHomeFeed(listParams);
  }
  try {
    const responseData = await promiseWithTimeout(
      GET_HOME_FEED_TIMEOUT_MS,
      () =>
        queryApi(getHomeFeedRouteSpec, {
          routeTokens: {},
          body: {},
          queryParams: listParams,
        }),
      "GET_HOME_FEED timeout"
    );
    registerMixpanelProperty(
      MixpanelProfileProperties.LAST_PERSONALIZED_HF_LOAD,
      new Date().toISOString()
    );
    return responseData;
  } catch {
    usePublicHomeFeed = true;
    return await fetchPublicHomeFeed(listParams);
  }
}

export async function fetchHomeFeed(
  listParams: ListParams,
  isLoggedOut?: boolean
) {
  const responseData = await fetchHomeFeed_(listParams, isLoggedOut);
  responseData.users && addUsers(responseData.users);
  responseData.nonprofits && addNonprofits(responseData.nonprofits);
  return responseData;
}

export async function fetchLandingData() {
  const responseData = await queryApi(getLandingDataRouteSpec, {
    routeTokens: {},
    body: {},
    queryParams: {},
  });
  responseData.users && addUsers(responseData.users);
  responseData.nonprofits && addNonprofits(responseData.nonprofits);
  responseData.nonprofitTags && addTags(responseData.nonprofitTags);
  return { ...responseData, hasMore: false };
}

export type FollowRecommendations = t.TypeOf<
  typeof getFollowRecommendationsRouteSpec.responseBodyCodec
>;

export async function fetchFollowRecommendations(
  listParams: ListParams
): Promise<FollowRecommendations> {
  const responseData = await queryApi(getFollowRecommendationsRouteSpec, {
    routeTokens: {},
    body: {},
    queryParams: listParams,
  });
  responseData.users && addUsers(responseData.users);
  return responseData;
}

export async function createDonation({
  paymentTab,
  ...body
}: t.TypeOf<typeof createDonationRouteSpec.bodyCodec> & {
  paymentTab: string;
}) {
  const amount = body.value?.amount || new Big(0);
  const currency = body.value?.currency || Currency.USD;
  const amountAsMinDenom = currencyValueToMinimumDenominationAmount({
    value: {
      currency,
      amount,
    },
  });
  const creditAmount = body.creditAmount || (0 as SafeInt);
  const tipAmount = body.tipAmount;
  // This "paidAmount" calculation not so accurate because it will always be
  // 0 for crypto donations and totally ignores currency.
  const paidAmount = coerceToSafeIntOrThrow({
    num: amountAsMinDenom - creditAmount + (tipAmount || 0),
  });
  try {
    trackDonate({
      uniqueId: body.nonce,
      paymentMethod: body.paymentMethod,
      paymentSource: body.externalPaymentSourceId,
      paymentTab,
      amount: parseFloat(amount.toString()),
      creditAmount: parseFloat(
        minimumDenominationAmountToCurrencyValue({
          amountInMinDenom: creditAmount,
          currency,
        }).amount.toString()
      ),
      paidAmount: parseFloat(
        minimumDenominationAmountToCurrencyValue({
          amountInMinDenom: paidAmount,
          currency,
        }).amount.toString()
      ),
      tipAmount,
      frequency: body.frequency,
      toNonprofitId: body.toNonprofitId,
      currency,
      pledgedCryptoValue: body.metadata?.pledgedCryptoValue,
      usdValueAtPledge: body.metadata?.usdValueAtPledge
        ? new Big(body.metadata?.usdValueAtPledge).toString()
        : undefined,
      hasPrivateNote: !!body.privateNote,
      isPublic: body.isPublic,
      shareInfo: body.shareInfo,
    });
  } catch (error) {
    logger.warn({ message: "Error calling track function", error });
  }

  const result = await queryApi(createDonationRouteSpec, {
    body: {
      ...body,
      metadata: {
        ...(body.metadata || {}),
        ...getUTMValuesFromCookie(),
        entryRouteName: entryRouteName || undefined,
        entryEntityName,
        paymentTab,
        userAgent: getWindow()?.navigator?.userAgent,
        device: getDeviceType(),
      },
    },
    queryParams: {},
    routeTokens: {},
  });
  dispatchPersonalDonationsAction({
    type: DonationActionType.ADD_NEW_DONATION,
    data: result.donationCharge,
  });
  dispatchMyDonationsAction({
    type: MyDonationsActionType.ADD_DONATION,
    data: result.donation,
  });
  resetTurnstile("turnstile");
  return result;
}

export async function updateComment({
  donationCharge,
  ...body
}: t.TypeOf<typeof updateCommentRouteSpec.bodyCodec> & {
  donationCharge?: PersonalDonationChargeResponse;
}) {
  const { donation } = await queryApi(updateCommentRouteSpec, {
    body,
    queryParams: {},
    routeTokens: {},
  });
  if (donation) {
    if (donationCharge) {
      dispatchPersonalDonationsAction({
        type: DonationActionType.UPDATE_DONATION,
        data: {
          ...donationCharge,
          donation,
        },
      });
    }
    dispatchMyDonationsAction({
      type: MyDonationsActionType.UPDATE_DONATION,
      data: donation,
    });
  }
}

export async function fetchAvailableGivingCredit({
  restrictedByNonprofitId,
}: {
  restrictedByNonprofitId?: string;
}) {
  // TODO #8478: add currency to params
  const { currency, amount } = await queryApi(
    getAvailableGivingCreditRouteSpec,
    {
      body: {},
      queryParams: {
        restrictedByNonprofitId: decodeOrUndefined(
          uuidCodec,
          restrictedByNonprofitId
        ),
      },
      routeTokens: {},
    }
  );
  return minimumDenominationAmountToCurrencyValue({
    amountInMinDenom: amount,
    currency,
  });
}

// TODO should probably move this and other functions that don't actually
// interact with the context they're part of into another module
export async function fetchGivingCredits() {
  const { currency, credits, availableCreditAmount } = await queryApi(
    getGivingCreditsRouteSpec,
    {
      body: {},
      queryParams: {},
      routeTokens: {},
    }
  );
  return {
    credits,
    availableCreditAmount: minimumDenominationAmountToCurrencyValue({
      currency,
      amountInMinDenom: availableCreditAmount,
    }),
  };
}

export async function fetchDonationBoosts(donationId: DonationResponse["id"]) {
  const response = await queryApi(getDonationBoostsRouteSpec, {
    routeTokens: { id: donationId },
    queryParams: {},
    body: {},
  });
  return response;
}
export type DonationBoostsResponse = TypeOfPromise<
  ReturnType<typeof fetchDonationBoosts>
>;
