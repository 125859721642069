import { AvatarSize, avatarCssBySize } from "@components/Avatar";
import { ResizingSpan } from "@components/ResizingSpan";
import { CSSInterpolation } from "@emotion/css";
import { css } from "@emotion/react";
import React from "react";

import { truncatedTextCss } from "src/styles/truncatedTextCss";
import { colorCssVars } from "src/theme/color";
import {
  MediaSize,
  cssForMediaSize,
  useMatchesScreenSize,
} from "src/theme/mediaQueries";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import {
  TextSize,
  secondaryMetadataTextCss,
  textSizeCss,
  FontWeight,
} from "src/theme/text";

export interface HeaderCardTitleProps {
  title: string | React.ReactElement;
  avatar: (avatarSize: CSSInterpolation) => React.ReactNode;

  /**
   * Optional pre-title, displayed above the title.
   */
  preTitle?: string;

  /**
   * Optional subtitle, displayed below the title.
   */
  subtitle?: string | React.ReactElement;

  /**
   * Allows for overriding the avatar size on large screen sizes.
   */
  overrideLargeAvatarSize?: AvatarSize;

  /**
   * Allows for overriding the avatar size on small screen sizes.
   */
  overrideSmallAvatarSize?: AvatarSize;

  /**
   * Allows for title compression when title is not passed as a string.
   */
  titleCompressor?: number;
}

export const HeaderCardTitle: React.FCC<HeaderCardTitleProps> = ({
  avatar,
  title,
  preTitle,
  subtitle,
  overrideLargeAvatarSize,
  titleCompressor,
}) => {
  const isMediumScreen =
    // eslint-disable-next-line no-restricted-syntax
    useMatchesScreenSize({ min: MediaSize.MEDIUM }) ?? false;

  return (
    <div
      css={cssForMediaSize({
        min: MediaSize.LARGE,
        css: css`
          ${horizontalStackCss.m};
          justify-content: space-between;
          margin-left: 0;
        `,
      })}
    >
      <section
        css={[
          horizontalStackCss.m,
          cssForMediaSize({
            min: MediaSize.MEDIUM,
            css: horizontalStackCss.l,
          }),
          css`
            align-items: center;
            flex-grow: 1;
            flex-wrap: nowrap;
          `,
        ]}
      >
        {avatar(
          cssForMediaSize({
            min: MediaSize.MEDIUM,
            css: avatarCssBySize[overrideLargeAvatarSize || AvatarSize.X_LARGE],
          })
        )}
        <div
          css={[
            verticalStackCss.xxs,
            cssForMediaSize({
              min: MediaSize.MEDIUM,
              css: verticalStackCss.xs,
            }),
            css`
              flex-grow: 1;
            `,
          ]}
        >
          {preTitle && (
            <b
              css={[
                truncatedTextCss({ numLines: 1 }),
                textSizeCss.s,
                css`
                  font-weight: ${FontWeight.MEDIUM};
                  color: var(${colorCssVars.accent.small});
                `,
              ]}
            >
              {preTitle}
            </b>
          )}
          {/* Set the text size smaller so that the resizing span can dictate height */}
          <h1 css={textSizeCss.s}>
            <ResizingSpan
              css={{ width: "100%" }}
              maxTextSize={isMediumScreen ? TextSize.xl : TextSize.l}
              minTextSize={isMediumScreen ? TextSize.m : TextSize.s}
              compressor={
                titleCompressor ||
                (typeof title === "string" && title.length > 24)
                  ? 2
                  : 1
              } // Arbitrarily chose to shrink faster based on what looks good
            >
              {title}
            </ResizingSpan>
          </h1>

          {subtitle &&
            (typeof subtitle === "string" ? (
              <b
                css={[
                  truncatedTextCss({ numLines: 1 }),
                  secondaryMetadataTextCss,
                ]}
              >
                {subtitle}
              </b>
            ) : (
              subtitle
            ))}
        </div>
      </section>
    </div>
  );
};
