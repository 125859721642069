import * as t from "io-ts";
import { BooleanFromString } from "io-ts-types/BooleanFromString";
import { UUID as uuidCodec } from "io-ts-types/UUID";

import { currencyCodec } from "../codecs/currency";
import { dateFromStringCodec } from "../codecs/date";
import {
  feedItemResponseCodec,
  fundraiserCodec,
  nonprofitResponseCodec,
  userResponseCodec,
  fundraiserRaisedResponseCodec,
  tagResponseCodec,
  coverAssetCodec,
} from "../codecs/entities";
import { slugCodec } from "../codecs/nonprofit";
import { safeNonNegativeIntCodec } from "../codecs/number";
import { HttpMethod } from "../helpers/http";

import { listParamsCodec, listResponseCodec, makeRouteSpec } from ".";

const fundraiserFullResponseCodec = t.type({
  fundraiser: fundraiserCodec,
  nonprofits: t.array(nonprofitResponseCodec),
  users: t.array(userResponseCodec),
  tags: t.array(tagResponseCodec),
});

export type FundraiserResponse = t.TypeOf<typeof fundraiserFullResponseCodec>;

const metadataPropsCodec = t.partial({
  brandColor: t.string,
  coverAssetOverride: t.union([coverAssetCodec, t.null]),
  coverYoutubeVideoUrl: t.union([t.string, t.null]),
  donationThankYouMessage: t.string,
  showInFeed: t.boolean,
  creatorApiKey: t.string,
});
const fundraisersFullResponseCodec = t.type({
  fundraisers: t.array(fundraiserCodec),
  nonprofits: t.array(nonprofitResponseCodec),
  users: t.array(userResponseCodec),
  tags: t.array(tagResponseCodec),
});

export type FundraisersFullResponse = t.TypeOf<
  typeof fundraisersFullResponseCodec
>;

const identifiersTokenCodec = t.type({
  fundraiserIdentifier: t.union([uuidCodec, slugCodec]),
  nonprofitIdentifier: t.union([uuidCodec, slugCodec]),
});

export const deleteFundraiserRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitIdentifier/fundraiser/:fundraiserIdentifier",
  method: HttpMethod.DELETE,
  authenticated: true,
  tokensCodec: identifiersTokenCodec,
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: t.type({}),
});

export const getFundraiserRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitIdentifier/fundraiser/:fundraiserIdentifier",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: identifiersTokenCodec,
  paramsCodec: t.partial({ skipCache: BooleanFromString }),
  bodyCodec: t.type({}),
  responseBodyCodec: fundraiserFullResponseCodec,
});

export const getFundraiserRaisedRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitIdentifier/fundraiser/:fundraiserIdentifier/raised",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: identifiersTokenCodec,
  paramsCodec: t.partial({ skipCache: BooleanFromString }),
  bodyCodec: t.type({}),
  responseBodyCodec: fundraiserRaisedResponseCodec,
});

export const postFundraiserBodyCodec = t.type({
  nonprofitId: uuidCodec,
  title: t.string,
  description: t.union([t.string, t.null]),
  startDate: t.union([dateFromStringCodec, t.null]),
  endDate: t.union([dateFromStringCodec, t.null]),
  goal: t.union([safeNonNegativeIntCodec, t.null]),
  raisedOffline: t.union([safeNonNegativeIntCodec, t.null]),
  currency: currencyCodec,
});
export const postFundraiserRouteSpecTemplate = {
  path: "/fundraiser",
  method: HttpMethod.POST,
  authenticated: true,
  tokensCodec: t.type({}),
  paramsCodec: t.type({}),
  responseBodyCodec: fundraiserFullResponseCodec,
};
export const postFundraiserRouteSpec = makeRouteSpec({
  ...postFundraiserRouteSpecTemplate,
  bodyCodec: t.intersection([
    postFundraiserBodyCodec,
    t.type({
      creatorNonprofitId: t.union([uuidCodec, t.null]),
      parentFundraiserId: t.union([uuidCodec, t.null]),
      coverImageCloudinaryId: t.union([t.string, t.null]),
      active: t.boolean,
      hidden: t.boolean,
      pinned: t.boolean,
      metadata: t.union([metadataPropsCodec, t.null]),
    }),
  ]),
});

export const updateFundraiserRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitIdentifier/fundraiser/:fundraiserIdentifier",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: identifiersTokenCodec,
  paramsCodec: t.type({}),
  bodyCodec: t.partial({
    title: t.string,
    slug: t.string,
    description: t.union([t.string, t.null]),
    coverImageCloudinaryId: t.union([t.string, t.null]),
    active: t.boolean,
    hidden: t.boolean,
    startDate: t.union([dateFromStringCodec, t.null]),
    endDate: t.union([dateFromStringCodec, t.null]),
    goal: t.union([safeNonNegativeIntCodec, t.null]),
    raisedOffline: t.union([safeNonNegativeIntCodec, t.null]),
    currency: currencyCodec,
    pinned: t.boolean,
    metadata: t.union([metadataPropsCodec, t.null]),
    creatorNonprofitId: t.union([uuidCodec, t.null]),
  }),
  responseBodyCodec: fundraiserFullResponseCodec,
});

export const hideFundraiserRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitIdentifier/fundraiser/:fundraiserIdentifier/hide",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: identifiersTokenCodec,
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: fundraiserCodec,
});

export const unhideFundraiserRouteSpec = makeRouteSpec({
  path: "/nonprofits/:nonprofitIdentifier/fundraiser/:fundraiserIdentifier/unhide",
  method: HttpMethod.PATCH,
  authenticated: true,
  tokensCodec: identifiersTokenCodec,
  paramsCodec: t.type({}),
  bodyCodec: t.type({}),
  responseBodyCodec: fundraiserCodec,
});

const entityFundraisersParamsCodec = t.intersection([
  listParamsCodec,
  t.partial({ publicFeed: BooleanFromString }),
]);

export const getNonprofitFundraisersRouteSpec = makeRouteSpec({
  path: "/nonprofit/:id/fundraisers/all",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: listParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: fundraisersFullResponseCodec,
});

export const getNonprofitFundraisersCachedRouteSpec = makeRouteSpec({
  path: "/nonprofit/:id/fundraisers",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: entityFundraisersParamsCodec, // TODO: replace with listParamsCodec after deploying #14941
  bodyCodec: t.type({}),
  responseBodyCodec: fundraisersFullResponseCodec,
});

export const getUserFundraisersRouteSpec = makeRouteSpec({
  path: "/user/:id/fundraisers",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: entityFundraisersParamsCodec,
  bodyCodec: t.type({}),
  responseBodyCodec: fundraisersFullResponseCodec,
});

const getFundraiserFeedParamsCodec = t.intersection([
  listParamsCodec,
  t.partial({ fundraiserId: uuidCodec }),
]);
const getFundraiserFeedBodyCodec = t.type({});
const getFundraiserFeedResponseBodyCodec = t.intersection([
  t.type({
    items: t.array(feedItemResponseCodec),
    users: t.array(userResponseCodec),
  }),
  listResponseCodec,
]);
export const getFundraiserFeedRouteSpec = makeRouteSpec({
  path: "/fundraiser/:id/feed",
  method: HttpMethod.GET,
  authenticated: false,
  tokensCodec: t.type({ id: uuidCodec }),
  paramsCodec: getFundraiserFeedParamsCodec,
  bodyCodec: getFundraiserFeedBodyCodec,
  responseBodyCodec: getFundraiserFeedResponseBodyCodec,
  publicRoute: {
    publicCacheLengthMinutes: 5,
  },
});
