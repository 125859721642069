import { DonateFormSearchValues } from "@components/donate/DonateV3/useDonateFormSearchParams";
import { useGate } from "statsig-react";

import {
  DISALLOWED_FUNDRAISER_NONPROFIT_IDS,
  GIFT_CARDS_SLUG,
} from "@every.org/common/src/entity/constants";
import {
  DonationFlowPaymentOption,
  NominalDonationFlowPaymentOption,
} from "@every.org/common/src/entity/types";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";

const AllDonationFlowPaymentOption = new Set(
  Object.values({
    ...DonationFlowPaymentOption,
    ...NominalDonationFlowPaymentOption,
  })
);

function supportMethodsFromString(string: string) {
  return string
    .split(",")
    .filter(
      (
        paymentMethod
      ): paymentMethod is
        | DonationFlowPaymentOption
        | NominalDonationFlowPaymentOption =>
        AllDonationFlowPaymentOption.has(
          paymentMethod as
            | DonationFlowPaymentOption
            | NominalDonationFlowPaymentOption
        )
    );
}

interface GridCardsDisplayControllerArgs {
  nonprofit: ContextNonprofit;
  initialValuesFromSearchParams: DonateFormSearchValues;
}

export function useShowFundraiserLink(nonprofit: ContextNonprofit): boolean {
  if (
    useGate("suspect_traffic").value &&
    nonprofit.metadata?.hideFundraiseButtonSuspectTraffic
  ) {
    return false;
  }
  if (DISALLOWED_FUNDRAISER_NONPROFIT_IDS.includes(nonprofit.id)) {
    return false;
  }
  if (nonprofit.metadata?.hideFundraiseButton) {
    return false;
  }
  return true;
}

export function useGridCardsDisplayController({
  nonprofit,
  initialValuesFromSearchParams,
}: GridCardsDisplayControllerArgs) {
  const { method: paymentFlowOptionsFromUrl } = initialValuesFromSearchParams;
  const methods = paymentFlowOptionsFromUrl
    ? supportMethodsFromString(paymentFlowOptionsFromUrl)
    : undefined;

  const showGiftCardLink =
    nonprofit.primarySlug === GIFT_CARDS_SLUG
      ? false
      : methods
      ? methods.includes(DonationFlowPaymentOption.GIFT_CARD)
      : true;

  let showFundraiserLink = useShowFundraiserLink(nonprofit);

  if (methods?.includes(NominalDonationFlowPaymentOption.FUNDRAISER)) {
    showFundraiserLink = true;
  }

  return {
    showGiftCardLink,
    showFundraiserLink,
  };
}
