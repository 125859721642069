import { Mixpanel } from "mixpanel-browser";

import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import { getClientBotType } from "src/utility/helpers";
import { logger } from "src/utility/logger";
import { getWindow } from "src/utility/window";

export enum MixpanelProfileProperties {
  FINISHED_BUILD_PROFILE_AT = "FINISHED_BUILD_PROFILE_AT",
  LAST_LOGGED_IN_LOAD_AT = "LAST_LOGGED_IN_LOAD_AT",
  LAST_PERSONALIZED_HF_LOAD = "LAST_PERSONALIZED_HF_LOAD",
  AB_TEST_TRACKS = "AB_TEST_TRACKS",
}

export const mixpanelPromise = new Promise<Mixpanel | undefined>((resolve) => {
  if (
    !getWindow() ||
    getClientBotType() ||
    !process.env.NEXT_PUBLIC_MIXPANEL_PUBLIC_API_KEY
  ) {
    // If no window present because doing SSR
    // or we think the user is a bot/crawler
    // or there is no Mixpanel API key (true for dev and staging)
    // the do not get the mixpanel library or track any events.
    return resolve(undefined);
  }
  import("mixpanel-browser")
    .then((mixpanelLib) => {
      const mixpanelApiKey = assertEnvPresent(
        process.env.NEXT_PUBLIC_MIXPANEL_PUBLIC_API_KEY,
        "MIXPANEL_PUBLIC_API_KEY"
      );

      mixpanelLib.init(mixpanelApiKey, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        api_host: "https://tracking.every.org",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        batch_requests: true,
        loaded: (mp) => {
          resolve(mp);
        },
      });
    })
    .catch((error) => {
      logger.warn({ error, message: "Failed to initialize mixpanel" });
      resolve(undefined);
    });
});

export async function registerMixpanelProperty(
  propertyName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propertyValue: any
) {
  (await mixpanelPromise)?.register({ [propertyName]: propertyValue });
}
