import { TypeOf } from "io-ts";
import { UUID as uuidCodec } from "io-ts-types/UUID";

/**
 * Unicode code-point aware substring function
 * @param text String to cut
 * @param start Index of first character to include
 * @param length Length to include in substring (may be shorter if string ends)
 */
export function unicodeSubstr(params: {
  text: string;
  start: number;
  length: number;
}) {
  // this works since Array.from does split unicode characters properly, unlike
  // string.substr
  return Array.from(params.text)
    .slice(params.start, params.start + Math.max(params.length, 0))
    .join("");
}

/**
 * Joins English words together in a gramatically correct, brief way
 *
 * Uses Oxford comma as per our copy guidelines
 * @example ["a", "b", "c"] => "a, b, and c"
 */
export function joinEnglishWordSeries(words: string[]) {
  if (words.length < 3) {
    return words.join(" and ");
  }
  return `${words.slice(0, words.length - 1).join(", ")}, and ${
    words[words.length - 1]
  }`;
}

/**
 * Takes a word and applies title casing (first letters capitalized, others lowercased)
 * i.e.
 * hello -> Hello
 * jim-bob -> Jim-Bob
 * don't -> Don't
 */
export function toTitleCase(word: string) {
  return word.replace(/\b[\w^']+/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function isUuid(input: unknown): input is TypeOf<typeof uuidCodec> {
  return uuidCodec.decode(input)._tag === "Right";
}

// Returns true if search param present, unless it is 0, f, fa, F FA etc
export function searchParamToBool(searchParamValue: string | null) {
  return (
    searchParamValue !== null &&
    searchParamValue !== "0" &&
    (searchParamValue.length === 0 ||
      !"false".startsWith(searchParamValue.toLowerCase()))
  );
}

const ELLIPSES = "...";
const MAX_SMART_CUT = 10;

/**
 * If a message is too long, cuts it so that it will be at most max length,
 * and adds ellipses to the end. Will do a smart cut so that it ends on a
 * whole word if possible to do so without cutting too much extra.
 */
export function smartTruncate(message: string, maxLength: number) {
  if (message.length <= maxLength) {
    return message;
  }
  const maxCut = maxLength - ELLIPSES.length;
  const spaceIndex = message.lastIndexOf(" ", maxCut);
  const useSpaceIndex = spaceIndex > 0 && maxCut - spaceIndex < MAX_SMART_CUT;
  return (
    unicodeSubstr({
      text: message,
      start: 0,
      length: useSpaceIndex ? spaceIndex : maxCut,
    }) + ELLIPSES
  );
}
