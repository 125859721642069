import { Checkbox, CheckboxSize } from "@components/Checkbox";
import { TextArea } from "@components/TextInput";
import { DonateFormType } from "@components/donate/DonateV3/types";
import styled from "@emotion/styled";
import { UseFormReturn, Controller } from "react-hook-form";

import { ContextNonprofit } from "src/context/NonprofitsContext/types";
import { colorCssVars } from "src/theme/color";
import { horizontalStackCss, verticalStackCss } from "src/theme/spacing";
import { FontWeight, TextSize, textSizeCss } from "src/theme/text";

const StyledCheckbox = styled(Checkbox)`
  ${horizontalStackCss.xs};
  ${textSizeCss.s};
  align-items: center;
`;

const StyledLabel = styled.label`
  color: var(${colorCssVars.text.body});
  font-weight: ${FontWeight.BOLD};
  ${textSizeCss[TextSize.s]};
`;

interface TestimonyInputProps {
  nonprofit: ContextNonprofit;
  form: UseFormReturn<DonateFormType>;
}

export const TestimonyInput = ({ nonprofit, form }: TestimonyInputProps) => {
  const {
    control,
    formState: { errors },
  } = form;
  if (nonprofit.metadata?.disablePublicTestimony) {
    return null;
  }

  const includeCommentText = form.watch("includeCommentText");
  return (
    <div css={verticalStackCss.l}>
      <Controller
        control={control}
        key="includeCommentText"
        name="includeCommentText"
        render={({ field: { onChange, value } }) => (
          <StyledCheckbox
            data-tname="includeCommentText__Chekbox"
            checked={value}
            size={CheckboxSize.LARGE}
            onClick={() => {
              onChange(!value);
            }}
            css={{
              color: `var(${
                value ? colorCssVars.text.body : colorCssVars.text.secondary
              })`,
            }}
          >
            Add public testimony
          </StyledCheckbox>
        )}
      />
      {includeCommentText && (
        <Controller
          control={control}
          key="testimonyText"
          name="commentText"
          render={({ field: { onChange, value } }) => (
            <div css={verticalStackCss.xs}>
              <StyledLabel htmlFor="testimonyText">
                Help {nonprofit.name} by sharing why you support them with the
                world
              </StyledLabel>
              <TextArea
                rows={3}
                label="Note"
                data-tname="testimonyText"
                id="testimonyText"
                value={value}
                onChange={onChange}
                collapseDescriptionSpace
                validationStatus={
                  errors.commentText
                    ? {
                        success: false,
                        message: errors.commentText.message,
                      }
                    : undefined
                }
              />
            </div>
          )}
        />
      )}
    </div>
  );
};
